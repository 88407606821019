import { Badge, Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IconButton, useMediaQuery, Drawer, useTheme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { primaryColor, primaryColorHover } from "../constant";
import { get_data } from "../api";
import { IoIosLogIn } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import LoginDrawer from "./LoginDrawer";
import Logo from "../assests/oredogpslogo.jpeg"
import { FaHome, FaCogs, FaUserShield } from "react-icons/fa";
import { Android, Apple, IosShare, IosShareRounded, Web } from "@mui/icons-material";
export default function Header() {

    const menuRef = useRef(null);
    const { user_data, isLoggedIn, isClient } = useSelector(state => state.user);
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const matches1 = useMediaQuery(theme.breakpoints.down(1025));
    const matches2 = useMediaQuery(theme.breakpoints.down(500));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showBrandDropdown, setShowBrandDropdown] = useState(false);
    const [brandList, setBrandList] = useState([]);
    const [android, setAndroid] = useState('');
    const [ios, setIos] = useState('');
    const [web, setWeb] = useState('');

    const fetchLinks = async () => {
        try {
            const global = await get_data('global/get-global-prices');
            if (global?.status == true) {
                setAndroid(global?.data?.android);
                setIos(global?.data?.ios);
                setWeb(global?.data?.website);
            }

        } catch (e) {
            console.log('error while fetching links', e);
        }
    }

    const fetchBrands = async () => {
        const result = await get_data(`product/get-all-brands-for-options`); // Replace with your API endpoint
        if (result?.status == true) {
            setBrandList(result?.data || []);
        }
    };

    useEffect(() => {
        fetchBrands();
        fetchLinks();
    }, []);

    const openLogin = Boolean(anchorEl);
    const handleClickLogin = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };


    const styles = {
        container: {
            width: '100%',
            boxSizing: 'border-box',
            position: 'relative',
            borderBottom: '1px solid gainsboro',
            zIndex: 99,
            background: 'white'
        },
        headerBar: {
            background: 'black',
            color: 'white',
            padding: '10px',
            fontSize: matches_md ? 12.5 : 14,
            textAlign: 'center'
        },
        navContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: matches1 ? '0 20px' : '0 80px',
            height: 70,
            position: "sticky",
            zIndex: 1000,
        },
        logo: {
            height: 'auto'
        },
        menuIcon: {
            display: matches_md ? 'block' : 'none',
            fontSize: '36px',
            color: 'black',
            cursor: 'pointer',
            marginLeft: 'auto',
        },
        navItems: {
            display: matches_md ? 'none' : 'flex',
            gap: 50,
            color: 'black',
            fontSize: 15,
            cursor: 'pointer',
        },
        buttonContainer: {
            display: matches_md ? 'none' : 'flex',
            gap: '10px'
        },
        signInButton: {
            borderColor: primaryColor,
            color: primaryColor,
            textTransform: 'capitalize',
            borderRadius: 0,
            fontWeight: 400,
            boxShadow: 'none',
            '&:hover': {
                borderColor: primaryColor,
                boxShadow: 'none',
            },
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
                borderColor: primaryColor,
            }
        },
        signOutButton: {
            borderColor: primaryColor,
            color: primaryColor,
            border: `2px solid ${primaryColor}`,
            textTransform: 'capitalize',
            borderRadius: '10px',
            padding: '10px',
            textAlign: 'center',
            fontWeight: '500',
            cursor: 'pointer'
        },
        getStartedButton: {
            background: primaryColor,
            color: 'white',
            borderRadius: 0,
            fontWeight: 400,
            textTransform: 'capitalize',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: primaryColorHover,
                boxShadow: 'none'
            },
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
                backgroundColor: primaryColor,
            }
        },
        drawerContent: {
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            gap: '10px',
            backgroundColor: 'white',
            color: 'black',
        },
        drawerNavItem: {
            color: 'black',
            fontSize: '18px',
            textDecoration: 'none',
            cursor: 'pointer',
            padding: '10px 0',
            fontWeight: '400',
        },
        drawerButtonContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginTop: '20px',
        },
        MyAccountButton: {
            padding: '10px 12px',
            borderRadius: '8px',
            backgroundColor: primaryColor,
            color: 'white',
            fontSize: 15,
            textTransform: 'capitalize',
            cursor: 'pointer',
            border: 'none',
        },
        platformMenuItem: {
            color: "black",
            cursor: "pointer",
            position: "relative",
        },
    };

    const handleClick = (link) => {
        navigate(link)
        window.scrollTo(0, 0)
    }

    const handleNavigateToMyAccount = () => {
        if (user_data?.isClient || user_data?.isSubClient) {
            navigate("/company/dashboard")
        } else if (user_data?.isUser) {
            navigate("/userdashboard");
        } else if (user_data?.isVendor) {
            navigate("/vendor/dashboard");
        }
    }


    return (<div style={styles.container}>
        <div style={styles.navContainer}>
            <img src={Logo}
                style={{ width: 60, height: 60, cursor: 'pointer', borderRadius: '50%' }}
            />
            <div style={styles.navItems}>
                <span style={{ color: 'black' }} onClick={() => handleClick('/')} className="nav-item" >Home</span>
                {isLoggedIn && user_data?.isClient ? <span style={{ color: 'black' }} onClick={() => handleClick('/request')} className="nav-item"  >Request</span> : <></>}
                {isLoggedIn && user_data?.isClient ? <span style={{ color: 'black' }} onClick={() => handleClick('/uninstall')} className="nav-item"  >Uninstall</span> : <></>}
                {isLoggedIn && user_data?.isClient ? <span style={{ color: 'black' }} onClick={() => handleClick('/claim')} className="nav-item"  >Claim</span> : <></>}
                {isLoggedIn && user_data?.isClient ? <span style={{ color: 'black' }} onClick={() => handleClick('/return')} className="nav-item"  >Return</span> : <></>}
                {/* {isLoggedIn && user_data?.isClient ? <span style={{ color: 'black' }} onClick={() => handleClick('/record')} className="nav-item"  >Record</span> : <></>} */}
                <span style={{ color: 'black' }} onClick={() => handleClick('/store')} className="nav-item"  >Store</span>
                {isLoggedIn && user_data?.isUser ? <span style={{ color: 'black' }} className="nav-item"  >Device Warranty</span> : <></>}
                <div
                    className="platform-menu"
                    onMouseEnter={() => setShowBrandDropdown(true)}
                    onMouseLeave={() => setShowBrandDropdown(false)}
                >
                    <span style={{ color: 'black' }} className="nav-item">
                        Brand
                    </span>
                    {showBrandDropdown && (
                        <div className="dropdown-menu" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {brandList.map((item, index) => (
                                <div key={index} className="dropdown-item" onClick={() => handleClick(`/brand/${item}`)}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div
                    className="platform-menu"
                    onMouseEnter={() => setShowDropdown(true)}
                    onMouseLeave={() => setShowDropdown(false)}
                >
                    <span style={styles.platformMenuItem}>Platform</span>

                    {showDropdown && (
                        <div className="dropdown-menu">
                            <div className="dropdown-item">
                                <a href={android} target="_blank" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', gap: 10, color: 'black' }}>
                                    <Android className="dropdown-icon" /> Android
                                </a>
                            </div>
                            <div className="dropdown-item">
                                <a href={ios} target="_blank" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', gap: 10, color: 'black' }}>
                                    <Apple className="dropdown-icon" /> IOS
                                </a>
                            </div>
                            <div className="dropdown-item">
                                <a href={web} target="_blank" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', gap: 10, color: 'black' }}>
                                    <Web className="dropdown-icon" /> Web

                                </a>
                            </div>
                        </div>
                    )}
                </div>

            <span style={{ color: 'black' }} onClick={() => handleClick('/contact')} className="nav-item" >Contact Us</span>

            </div>


            {isLoggedIn && user_data?.isClient || user_data?.isSubClient || user_data?.isUser ?
                <div style={styles.buttonContainer} >
                    <Button variant="outlined" sx={styles.signInButton} onClick={handleNavigateToMyAccount}>My Account</Button>
                </div>
                :
                <div style={styles.buttonContainer} >
                    {/* <Button variant="outlined" sx={styles.signInButton} onClick={() => navigate('/company/login')}>Company Login</Button> */}
                    {/* <Button variant="contained" sx={styles.getStartedButton} onClick={() => setOpen(true)}>User Login</Button> */}
                    <Button variant="contained"
                        aria-controls={openLogin ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openLogin ? 'true' : undefined}
                        onClick={handleClickLogin}
                        sx={styles.getStartedButton}
                        startIcon={<IoIosLogIn />}>  Login </Button>

                    <Menu
                        anchorEl={anchorEl}
                        open={openLogin}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleClose}><div onClick={() => navigate('/company/login')}> Company Login</div> </MenuItem>
                        <MenuItem onClick={handleClose}><div onClick={() => navigate('/vendor/login')}>Vendor Login</div> </MenuItem>
                        <MenuItem onClick={handleClose}><div onClick={() => setOpen(true)}>User Login</div> </MenuItem>
                    </Menu>
                </div>

            }

            <IconButton style={styles.menuIcon} onClick={handleDrawerToggle}>
                <MenuIcon />
            </IconButton>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                PaperProps={{
                    style: {
                        backgroundColor: 'white',
                        color: 'black',
                        width: 300,
                    },
                }}
            >
                <div style={styles.drawerContent}>
                    <span style={{ ...styles.drawerNavItem, color: 'black' }} onClick={() => handleClick('/')} className="nav-item" >Home</span>
                    {isLoggedIn && user_data?.isClient ? <span style={{ ...styles.drawerNavItem, color: 'black' }} onClick={() => handleClick('/request')} className="nav-item"  >Request</span> : <></>}
                    {isLoggedIn && user_data?.isClient ? <span style={{ ...styles.drawerNavItem, color: 'black' }} onClick={() => handleClick('/uninstall')} className="nav-item"  >Uninstall</span> : <></>}
                    {isLoggedIn && user_data?.isClient ? <span style={{ ...styles.drawerNavItem, color: 'black' }} onClick={() => handleClick('/claim')} className="nav-item"  >Claim</span> : <></>}
                    {isLoggedIn && user_data?.isClient ? <span style={{ ...styles.drawerNavItem, color: 'black' }} onClick={() => handleClick('/return')} className="nav-item"  >Return</span> : <></>}
                    {/* {isLoggedIn && user_data?.isClient ? <span style={{ ...styles.drawerNavItem, color: 'black' }} onClick={() => handleClick('/record')} className="nav-item"  >Record</span> : <></>} */}
                    {/* <span style={{ ...styles.drawerNavItem, color: 'black' }} onClick={() => handleClick('/strore')} className="nav-item"  >Store</span> */}

                    {isLoggedIn && user_data?.isClient || user_data?.isSubClient ?
                        <div style={styles.buttonContainer} >
                            <Button variant="outlined" sx={styles.signInButton} onClick={() => navigate('/company/dashboard')}>My Account</Button>
                        </div>
                        :
                        <div style={styles.buttonContainer} >
                            <Button variant="outlined" sx={styles.signInButton} onClick={() => navigate('/company/login')}>Company Login</Button>
                            <Button variant="contained" sx={styles.getStartedButton} onClick={() => setOpen(true)}>User Login</Button>
                        </div>
                    }
                </div>
            </Drawer>
            <LoginDrawer open={open} setOpen={setOpen} />
        </div>
    </div>
    )
};
