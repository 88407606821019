import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Button, Typography, Menu, MenuItem, IconButton, Pagination, Box, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { get_data, post_data } from '../../api';
import AdminInventorysModal from '../models/AdminInventoryModal';
import AssignGpsToCompany from '../models/AssignGpsToCompany';
import { primaryColor, primaryColorHover } from '../../constant';
import VendorDetailModal from '../VendorDetailModal';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        backgroundColor: '#ffebee',
        color: '#e53935',
        '&.active': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.non-active': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.stock': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.no data': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
    moreIcon: {
        color: '#a3a3a3',
    },
    actionBar: {
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const AdminInventoryTable = ({
    data, getInvenrotyData,
    setLoading, totalData, loading, currentPage, setCurrentPage, itemsPerPage, totalPages
}) => {
    const classes = useStyles();
    const [rowsData, setRowsData] = useState(data || []);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState([]);
    const [openAssign, setOpenAssign] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [deleteMode, setDeleteMode] = useState(false);
    const [vendorOpen, setVendorOpen] = useState(false);

    const tableHeader = deleteMode ? [
        "S No.", "select", "Company Name", "Vehicle Number", "IMEI Number", "SIM Number", "Sim Network", "Device Unique Id", "Vendor", "Gps Status"
    ] : ["S No.", "Company Name", "Vehicle Number", "IMEI Number", "SIM Number", "Sim Network", "Device Unique Id", "Vendor", "Gps Status"];

    useEffect(() => { setRowsData(data); }, [data]);

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handleDelete = async () => {
        if (selectedRows?.length === 0) {
            toast.error("Please select at least one row to delete.");
            return;
        }

        Swal.fire({
            title: "Are you sure?",
            text: `You will delete ${selectedRows?.length} record(s).`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const deletePromises = selectedRows?.map((rowId) =>
                        post_data(`admin-inventory/delete-admin-inventory/${rowId}`, {})
                    )
                    const results = await Promise.all(deletePromises);


                    const successfulDeletes = results?.filter((res) => res?.status);
                    if (successfulDeletes?.length === selectedRows?.length) {
                        toast.success("Selected inventories deleted successfully.");
                        getInvenrotyData();
                    } else {
                        toast.error("Some records could not be deleted.");
                    }
                    setDeleteMode(false);
                    setSelectedRows([]);
                } catch (error) {
                    toast.error("Something went wrong.");
                }
            }
        });
    };

    const handleCheckboxChange = (event, rowId) => {
        if (event.target.checked) {
            setSelectedRows((prev) => [...prev, rowId]);
        } else {
            setSelectedRows((prev) => prev.filter((id) => id !== rowId));
        }
    };

    const handleSelectAll = () => {
        // if (selectedRows?.length === rowsData?.length) {
        //     setSelectedRows([]);
        // } else {
        //     setSelectedRows(rowsData?.map((row) => row?._id));
        // }

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const results = await get_data(`admin-inventory/delete-all-admin-inventory`)
                    if (results?.status === true) {
                        toast.success("Selected inventories deleted successfully.");
                        getInvenrotyData();
                    } else {
                        toast.error("Some records could not be deleted.");
                    }
                    setDeleteMode(false); // Exit delete mode after deletion
                    setSelectedRows([]); // Clear selected rows
                } catch (error) {
                    toast.error("Something went wrong.");
                }
            }
        });

    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleShowVendor = (row) => {
        setVendorOpen(true);
        setCurrentRow(row)
    }

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    return (
        <>
            <VendorDetailModal open={vendorOpen} setOpen={setVendorOpen} data={currentRow} />
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                {deleteMode && (<Table style={{ borderBottom: '1px solid gainsboro', display: 'flex' }}>
                    <Box className={classes.actionBar}>
                        <Button sx={{
                            borderColor: '#d33',
                            color: '#d33',
                            textTransform: 'capitalize',
                            borderRadius: 0,
                            fontWeight: 400,
                            boxShadow: 'none',
                            '&:hover': {
                                borderColor: '#d33',
                                boxShadow: 'none',
                            },
                            '&:focus': {
                                outline: 'none',
                                boxShadow: 'none',
                                borderColor: '#d33',
                            }
                        }} variant='outlined' onClick={handleSelectAll}>
                            {"Delete All"}
                        </Button>
                    </Box>

                    {deleteMode && selectedRows?.length > 0 && (
                        <Box className={classes.actionBar}>
                            <Button sx={{
                                background: primaryColor,
                                color: 'white',
                                borderRadius: 0,
                                fontWeight: 400,
                                textTransform: 'capitalize',
                                boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: primaryColorHover,
                                    boxShadow: 'none',
                                },
                                '&:focus': {
                                    outline: 'none',
                                    boxShadow: 'none',
                                    backgroundColor: primaryColor,
                                }
                            }} variant="contained" onClick={handleDelete}>Confirm Delete</Button>
                        </Box>
                    )}
                </Table>)}
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {tableHeader.map((item) => (
                                <TableCell key={item} style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: '5%' }}>
                                    {startEntry + index}
                                </TableCell>
                                {deleteMode && (
                                    <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center' }}>
                                        <Checkbox
                                            checked={selectedRows.includes(row?._id)}
                                            onChange={(event) => handleCheckboxChange(event, row?._id)}
                                            disabled={!deleteMode} // Only enable checkboxes in delete mode
                                        />
                                    </TableCell>
                                )}
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    {row?.clientId?.companyName}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    {row?.vehicleNumber}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    {row?.imeiNumber || '-'}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    {row?.simNumber || '-'}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.network || '-'}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    {row?.deviceUniqueId}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', cursor: 'pointer' }} onClick={() => handleShowVendor(row?.vendorId)}>
                                    <span style={{ color: primaryColor, fontSize: 11, fontWeight: 500, textDecoration: 'underline' }}>View Detail</span>
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    <Button
                                        style={{ fontSize: 11, fontWeight: 500, borderRadius: 30 }}
                                        className={`${classes.stageButton} ${row?.gpsStatus?.toLowerCase()?.replace(' ', '')}`}
                                    >
                                        {row?.gpsStatus || '-'}
                                    </Button>
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    <IconButton
                                        className={classes.moreIcon}
                                        onClick={(event) => handleMenuOpen(event, index)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>

                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl) && currentRow === index}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => {
                                            setEditData(row);
                                            setOpen(true);
                                            handleMenuClose();
                                        }}>
                                            Update
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            setDeleteMode(true); // Enable delete mode
                                            handleMenuClose();
                                        }}>
                                            Delete
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            setEditData(row);
                                            setOpenAssign(true);
                                            handleMenuClose();
                                        }}>
                                            Assign Gps
                                        </MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <AssignGpsToCompany
                    open={openAssign}
                    setOpen={setOpenAssign}
                    data={editData}
                    getInvenrotyData={getInvenrotyData}
                />
                <AdminInventorysModal
                    open={open}
                    setOpen={setOpen}
                    data={editData}
                    isUpdate={true}
                    getInvenrotyData={getInvenrotyData}
                />

                {rowsData.length > 0 && (
                    <Box className={classes.actionBar}>
                        <Typography style={{ fontWeight: 500, fontSize: 15 }}>
                            {`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}
                        </Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                )}
            </TableContainer>
        </>
    );
};

export default AdminInventoryTable;