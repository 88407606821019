import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, Autocomplete } from '@mui/material';
import { formatDate, primaryColor } from '../constant';
import { useSelector } from 'react-redux';
import { post_data, get_data } from '../api';
import toast from 'react-hot-toast';

const GpsReqAssignModal = ({ open, setOpen, data, getAllVehicles }) => {
    const { user_data } = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [deviceUniqueIdList, setDeviceUniqueIdList] = useState([]);
    const [imeiNo, setImeiNo] = useState('');
    const [simNo, setSimNo] = useState('');

    const fetchAllDeviceUniqueIds = async () => {
        let result = await get_data(`inventory/get-all-stock-gps`)
        if (result?.status === true) {
            setDeviceUniqueIdList(result?.data)
        }
    }

    useEffect(() => {
        fetchAllDeviceUniqueIds()
    }, []);

    const handleClose = () => {
        setOpen(false);
        getAllVehicles();
    };

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            border: 'none',
            bgcolor: 'white',
            boxShadow: 12,
            textAlign: 'center',
            maxHeight: '80vh',
            overflow: 'auto',
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    const [gpsUniqueIds, setGpsUniqueIds] = useState(data?.detail?.map((item) => item?.gpsUniqueId) || []);

    const handleGpsUniqueIdChange = (index, value) => {
        const updatedGpsUniqueIds = [...gpsUniqueIds];
        updatedGpsUniqueIds[index] = value;
        setGpsUniqueIds(updatedGpsUniqueIds);
    };

    const handleAssign = async (item, index) => {
        const gpsUniqueId = gpsUniqueIds[index];
        setLoading(true);

        const payload = {
            clientId: user_data?._id,
            customerDetails: {
                companyName: user_data?.companyName,
                vendorName: '',
                contactNo: user_data?.contactNo,
                resContactNo: user_data?.resContactNo,
                address: {
                    street: user_data?.address?.street,
                    landmark: user_data?.address?.landmark,
                    city: user_data?.address?.city,
                    state: user_data?.address?.state,
                    zipCode: user_data?.address?.zipCode
                },
            },
            vehicleId: data?._id,
            isVendorRequest: true,
            gps: [{ ...item, deviceDetails: { uniqueId: gpsUniqueId, imeiNo, simNo } }]
        }

        const result = await post_data('request/create-request', payload);

        if (result?.status === true) {
            const res = await post_data('vehicles/update-gps-uniqueId', {
                gpsUniqueId,
                id: data?._id,
                vehicleNumber: item?.vehicleDetails?.regnNumber
            });

            if (res?.status === true) {
                toast.success('Request created successfully');
                handleClose();
            }else{
                toast.error(result?.response?.data?.message || 'Something Went Wrong')
            }
            setLoading(false);
        } else {
            toast.error(result?.response?.data?.message || 'Something went wrong');
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                    Assign GPS
                </Typography>
                <Box sx={{ padding: '2% 6% 6% 6%' }}>
                    {data?.detail?.map((item, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', justifyContent: 'space-between', gap: '10px' }}>
                            <div>{item?.vehicleDetails?.regnNumber}</div>
                            {item?.gpsUniqueId ?
                                <div>{item?.gpsUniqueId}</div>
                                :
                                <Autocomplete
                                    id="device-unique-id-select"
                                    fullWidth
                                    options={deviceUniqueIdList}
                                    value={deviceUniqueIdList.find(s => s?.gpsDetail?.deviceUniqueId == gpsUniqueIds[index] || '') || null}
                                    onChange={(event, newValue) => {
                                        setGpsUniqueIds(prev => {
                                            const updatedGpsUniqueIds = [...prev];
                                            updatedGpsUniqueIds[index] = newValue?.gpsDetail?.deviceUniqueId;
                                            return updatedGpsUniqueIds
                                        })
                                        setImeiNo(newValue?.gpsDetail?.imei)
                                        setSimNo(newValue?.gpsDetail?.simNumber)
                                    }}
                                    getOptionLabel={(option) => option?.gpsDetail?.deviceUniqueId || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Device Unique Id"
                                            fullWidth
                                        />
                                    )}
                                />
                            }
                            {item?.gpsUniqueId ? null :
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: primaryColor,
                                        color: '#ffff',
                                    }}

                                    onClick={() => handleAssign(item, index)}
                                >
                                    {loading ? 'Loading...' : 'Assign'}
                                </Button>}
                        </div>
                    ))}
                </Box>
            </Box>
        </Modal>
    );
};

export default GpsReqAssignModal;
