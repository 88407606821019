import * as React from 'react';
import { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Box, Grid, Typography, IconButton, MenuItem, FormControl, FormLabel, RadioGroup, Radio, Autocomplete, Chip, FormGroup } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import imageCompression from "browser-image-compression";
import ImageIcon from '@mui/icons-material/Image';
import { primaryColor } from '../constant';
import CloseIcon from '@mui/icons-material/Close';
import { get_data, post_data, serverURL } from '../api';
import { useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import AddFeature from './models/AddFeature';

export default function CreateProduct() {

    const location = useLocation()

    const Update = location?.state?.title || '';
    const [rowsData, setRowsData] = useState(location?.state?.row || '');
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [images, setImages] = useState([]);
    const [newImages, setNewImages] = useState(location?.state?.row?.images || [])
    const [newPdf, setNewPdf] = useState({})
    const [compressedImages, setCompressedImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accessoriesList, setAccessoriesList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [Accessories, setAccessories] = useState([])
    const [networkType, setNetworkType] = useState('2G');
    const [isActive, setIsActive] = useState(true);
    const [isImageUpdate, setIsImageUpdate] = useState(false);
    const [uploadNewImage, setUploadNewImage] = useState([]);
    const [features, setFeatures] = useState([]);
    const [formData, setFormData] = useState({ ...location?.state?.row, uploadNewImage: [], features: location?.state?.row?.features?.length > 0 ? location?.state?.row?.features : [] } || {
        name: '',
        price: '',
        type: '',
        amazon: '',
        flipkart: '',
        meesho: '',
        snapdeal: '',
        trackingDeviceType: '',
        trackingMethod: '',
        gpsTrackerType: '',
        serviceCompletion: '',
        maintenanceType: '',
        // imeiNo: '',
        frequency: '',
        warranty: '',
        color: '',
        mobileSupported: false,
        webConnectivity: false,
        geoFencing: false,
        touchScreen: false,
        mobileAccess: false,
        emailAlert: false,
        waterproof: false,
        description: '',
        images: [],
        uploadNewImage: [],
        accessories: [],
        vehicleSupported: [],
        brand: '',
        relay: [],
        features: [],
        // pdf:{},
        // uploadNewPdf:{},
    } || location?.state?.row);
    const [simCard, setSimCard] = useState(location?.state?.row?.simCard || []);
    const [featuresList, setFeaturesList] = useState( location?.state?.row?.features || {});


    const handleChipClick = (value) => {
        if (value === 'no relay') {
            setFormData((prevState) => ({ ...prevState, relay: ['no relay'] }))
            return;
        }
        setFormData((prevState) => {
            const isSelected = prevState.relay?.includes(value);
            let updatedSelection = isSelected
                ? prevState.relay.filter((item) => item !== value)
                : [...(prevState.relay || []), value];

            updatedSelection = updatedSelection.filter((item) => item !== 'no relay');

            return { ...prevState, relay: updatedSelection };
        });
    };


    const vehicleSupport = ["car", "bike", "bus", "truck", "tractor", "auto", "jcb", "crane"]

    const dropzoneStyle = {
        border: '2px dashed ' + primaryColor,
        borderRadius: '8px',
        padding: '40px',
        textAlign: 'center',
        width: '100%',
        margin: 'auto',
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
        transition: '0.3s',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
    }

    const imagesBox = {
        position: 'relative',
        width: 130,
        height: 130,
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    }

    const fetchFeatures = async () => {
        let result = await get_data(`feature/get-all-features-for-options`);

        if (result?.status == true) {
            const feature = result?.data?.map((item) => item?.name);
            setFeatures(feature);
        }
    }

    React.useEffect(function () {
        if (location?.state?.row?.accessories) {
            let temp = location?.state?.row?.accessories?.map((item) => {
                return item?._id
            })
            setAccessories(temp)
        }

        fetchFeatures()
    }, [])

    const getAllAccessoriesList = async () => {
        let result = await get_data(`accessory/get-all-accessories?pageNumber=${currentPage}`)
        if (result?.status == true) {
            setAccessoriesList(result?.data?.accessories)
        }
    }

    React.useEffect(function () {
        if (currentPage) {
            getAllAccessoriesList()
        }
    }, currentPage)


    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData?.name?.trim()) {
            newErrors.name = 'Product name is required';
        }

        if (!formData?.price?.trim()) {
            newErrors.price = 'Price is required';
        } else if (!/^\d+$/.test(formData.price)) {
            newErrors.price = 'Price must be a number';
        }
        if (!formData?.type?.trim()) {
            newErrors.type = 'Product type is required';
        }

        if (!formData?.color?.trim()) {
            newErrors.color = 'Color is required';
        }

        if (!formData.accessories) {
            newErrors.accessories = 'Accessories is required';
        }

        if (!formData.vehicleSupported) {
            newErrors.vehicleSupported = 'vehicleSupported is required';
        }
        if (!formData.description) {
            newErrors.description = 'description is required';
        }
        if (!formData.trackingDeviceType) {
            newErrors.trackingDeviceType = 'trackingDeviceType is required';
        }
        if (!formData.trackingMethod) {
            newErrors.trackingMethod = 'trackingMethod is required';
        }
        if (!formData.gpsTrackerType) {
            newErrors.gpsTrackerType = 'gpsTrackerType is required';
        }

        if (!formData.serviceCompletion) {
            newErrors.serviceCompletion = 'serviceCompletion is required';
        }

        if (!formData.maintenanceType) {
            newErrors.maintenanceType = 'maintenanceType is required';
        }

        if (!formData?.brand) {
            newErrors.brand = 'brand is required';
        }

        // if (!formData.imeiNo) {
        //     newErrors.imeiNo = 'imeiNo is required';
        // }
        if (!formData.model) {
            newErrors.model = 'model is required';
        }
        if (!formData.frequency) {
            newErrors.frequency = 'frequency is required';
        }
        if (!formData.warranty) {
            newErrors.warranty = 'warranty is required';
        }
        if (formData?.images?.length === 0 && !Update) {
            newErrors.images = 'images is required';
        }

        console.log('newErrors', newErrors);

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === "price") {
            if (value.length <= 10) {
                setFormData({ ...formData, [name]: value.replace(/\D/g, '').slice(0, 10) });
                setErrors({ ...errors, [name]: false })
            }
            return
        }
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: false })
    };

    const handleImageChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: false })
    };

    const handleCheckboxChange = (event, value) => {
        // const { name, checked } = event.target;
        setFormData({ ...formData, features: [...formData?.features, value] });
    };

    const handleSimChange = (event,) => {
        const name = event.target.value;

        if (simCard.includes(name)) {
            setSimCard(simCard.filter((item) => item !== name));
            return;
        }

        setSimCard([...simCard, name]);
    };

    console.log('formData', formData)
    const handleDescriptionChange = (value) => {
        setFormData({ ...formData, description: value });
    };

    const handleSubmit = async (event) => {

        event.preventDefault();
        if (validateForm()) {
            setLoading(true)
            let { accessories, ...newformData } = formData
            const vehicle = newformData?.vehicleSupported?.filter((item) => item !== 'Select All')
            var body = { ...newformData, networkType, isActive, accessories: JSON.stringify(Accessories), vehicleSupported: JSON.stringify(vehicle), simCard: JSON.stringify(simCard), features: JSON.stringify(featuresList) };
            const payload = new FormData();
            Object.keys(body).forEach((key) => {
                if (key === 'images') {
                    body.images.forEach((image) => {
                        payload.append('images', image);
                    });
                } else {
                    payload.append(key, body[key]);
                }
            });
            try {
                const response = await post_data('product/create-product', payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.status === true) {
                    setLoading(false)
                    toast.success("Product created successfully!");
                } else {
                    setLoading(false)
                    toast.error(response?.response?.data?.message || "Something went wrong!");
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            setLoading(true)
            if (formData?.uploadNewImage?.length == 0 && isImageUpdate == false) {
                let { accessories, ...newformData } = formData
                var body = { ...newformData, accessories: Accessories, simCard, features: featuresList }
                try {
                    const response = await post_data(`product/update-product/${body?._id}`, body, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    console.log('response', response)
                    if (response.status === true) {
                        setLoading(false)
                        toast.success("Product updated successfully!");
                    } else {
                        setLoading(false)
                        toast.error(response?.response?.data?.message || "Something went wrong!");
                    }
                } catch (error) {
                    setLoading(false)
                }
            } else {
                let { accessories, ...newformData } = formData
                const vehicle = newformData?.vehicleSupported?.filter((item) => item !== 'Select All')
                var body = { ...newformData, networkType, isActive, accessories: JSON.stringify(Accessories), vehicleSupported: JSON.stringify(vehicle), simCard: JSON.stringify(simCard), features: JSON.stringify(featuresList) }
                const payload = new FormData();

                Object.keys(body).forEach((key) => {
                    if (key === 'images') {
                        const updateImageURL = [];

                        newImages?.forEach((image) => {
                            if (image instanceof File) {
                                payload.append('images', image);
                            }
                            else if (typeof image === 'string') {
                                updateImageURL.push(image);
                            }
                        })

                        payload.append('updateImageURL', JSON.stringify(updateImageURL));

                    }
                    else if (key === 'uploadNewImage') {
                        // body?.uploadNewImage.forEach((image) => {
                        //     payload.append('images', image);
                        // });
                    }
                    else {
                        payload.append(key, body[key]);
                    }
                });
                try {
                    const response = await post_data(`product/update-product-with-images/${body?._id}`, payload, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    if (response.status === true) {
                        setLoading(false)
                        toast.success("Product update successfully!");
                    } else {
                        setLoading(false)
                        toast.error("Something went wrong!");
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        }
    }

    const handleCompressImage = async () => {
        const compressedImageArray = [];
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            const options = {
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };
            try {
                const compressedImage = await imageCompression(image, options);
                const compressedFile = new File(
                    [compressedImage],
                    image.name,
                    { type: image.type, lastModified: Date.now() }
                );
                compressedImageArray.push(compressedFile);
            } catch (error) {
            }
        }
        if (Update) {
            setCompressedImages([...newImages, ...compressedImageArray]);
        } else {
            setCompressedImages([...compressedImageArray]);
        }
    };

    React.useEffect(() => {
        handleCompressImage()
    }, [newImages, images])

    const modules = React.useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', "strike"],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['image', "link", "video"],
                ['clean'],
                ['code-block'],
                [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
            ],
            imageResize: {
                displaySize: true
            }
        },
    }), []);

    const vehicleOptions = ["car", "bike", "bus", "truck", "tractor", "auto", "jcb", "crane"]
    const handleChange = (event, newValue) => {
        if (newValue?.length === vehicleOptions?.length) {
            setFormData((prev) => ({ ...prev, vehicleSupported: [] }));
        }
        else if (newValue.includes("Select All")) {
            setFormData((prev) => ({ ...prev, vehicleSupported: [...vehicleOptions, "Select All"] }));
        } else {
            setFormData((prev) => ({ ...prev, vehicleSupported: newValue }));
        }
    };


    return (
        <>
            <AddFeature open={open} setOpen={setOpen} featuresList={featuresList} setFeaturesList={setFeaturesList} />
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Grid container spacing={2}>
                <Grid item md={7}>
                    <Box
                        component="form"
                        onSubmit={Update ? handleUpdate : handleSubmit}
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        margin="auto"
                        padding="35px"
                        bgcolor="white"
                        borderRadius={2}
                    >
                        <TextField
                            label="Product Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            fullWidth
                            variant="outlined"
                            style={{ borderRadius: 0 }}
                            error={!!errors.name}
                            helperText={errors.name}
                            onFocus={() => handleError('', 'name')}
                        />

                        <FormControl sx={{ width: '100%' }}>
                            <Autocomplete
                                disablePortal
                                options={accessoriesList}
                                getOptionLabel={(option) => option?.title}
                                isOptionEqualToValue={(option, value) => {

                                    if (!option || !value) return false;
                                    return option?._id === value?._id;
                                }}

                                sx={{ width: 'auto' }}
                                multiple
                                value={formData?.accessories || []}
                                onChange={async (e, value, index) => {
                                    setFormData({ ...formData, accessories: value })
                                    let temp = value?.map((item) => {
                                        return item?._id
                                    })

                                    setAccessories(temp)
                                }}
                                defaultChecked={formData?.accessories}
                                renderInput={(params) => {
                                    return <TextField
                                        error={!!errors.accessories}
                                        helperText={errors.accessories}
                                        onFocus={() => handleError('', 'accessories')}
                                        {...params} label="Select Accessories"
                                    />
                                }}
                            />
                            {!!errors.accessories ? <div>{errors.accessories}</div> : ''}
                        </FormControl>

                        <FormControl sx={{ width: '100%' }}>
                            <Autocomplete
                                options={["Select All", ...vehicleSupport]}
                                multiple
                                value={formData?.vehicleSupported || []}
                                onChange={handleChange}

                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox checked={selected} />
                                        {option}
                                    </li>
                                )}
                                renderTags={(selected, getTagProps) =>
                                    selected.map((option, index) => {
                                        if (option === "Select All") return null
                                        else
                                            return <Chip {...getTagProps({ index })} key={option} label={option} />
                                    })
                                }

                                renderInput={(params) => {
                                    return <TextField
                                        error={!!errors.vehicleSupported}
                                        helperText={errors.vehicleSupported}
                                        onFocus={() => handleError('', 'vehicleSupported')}
                                        {...params} label="Select Suppoerted Vehicles" />
                                }}
                            />
                        </FormControl>

                        <ReactQuill
                            value={formData?.description}
                            modules={modules}
                            style={{ height: 150, marginBottom: '10%' }}
                            onChange={handleDescriptionChange}
                            theme="snow"
                            placeholder="Enter product description"
                            error={!!errors.description}
                            helperText={errors.description}
                            onFocus={() => handleError('', 'description')}
                        />

                        <div style={{ display: 'flex', gap: 10 }}>
                            <Button
                                variant="contained"
                                style={{
                                    textTransform: 'capitalize',
                                    color: "#fff",
                                    background: primaryColor,
                                    borderRadius: 0
                                }}
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                Add Feature
                            </Button>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {
                                        Object.entries(featuresList)?.map(([item, value]) => {
                                            return (
                                                <Chip
                                                    key={item}
                                                    label={`${item}: ${value}`}
                                                />
                                            )
                                        })
                                    }
                                </Box>
                        </div>

                        <div style={{ display: 'flex', gap: 10 }}>
                            <TextField
                                label="Price"
                                name="price"
                                value={formData?.price}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                style={{ borderRadius: 0 }}
                                error={!!errors.price}
                                helperText={errors.price}
                                onFocus={() => handleError('', 'price')}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}  // Restrict input to numbers
                            />

                            <TextField
                                label="Type"
                                name="type"
                                value={formData.type}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                style={{ borderRadius: 0 }}
                                error={!!errors.type}
                                helperText={errors.type}
                                onFocus={() => handleError('', 'type')}
                            />
                        </div>

                        <div style={{ display: 'flex', gap: 10 }}>
                            <TextField
                                label="Tracking Device Type"
                                name="trackingDeviceType"
                                value={formData.trackingDeviceType}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                style={{ borderRadius: 0 }}
                                error={!!errors.trackingDeviceType}
                                helperText={errors.trackingDeviceType}
                                onFocus={() => handleError('', 'trackingDeviceType')}
                            />

                            <TextField
                                label="Tracking Method"
                                name="trackingMethod"
                                value={formData.trackingMethod}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                style={{ borderRadius: 0 }}
                                error={!!errors.trackingMethod}
                                helperText={errors.trackingMethod}
                                onFocus={() => handleError('', 'trackingMethod')}
                            />
                        </div>

                        <div style={{ display: 'flex', gap: 10 }}>
                            <TextField
                                label="GPS Tracker Type"
                                name="gpsTrackerType"
                                value={formData.gpsTrackerType}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                error={!!errors.gpsTrackerType}
                                helperText={errors.gpsTrackerType}
                                onFocus={() => handleError('', 'gpsTrackerType')}
                                style={{ borderRadius: 0 }}
                            />

                            <TextField
                                label="Service Completion"
                                name="serviceCompletion"
                                value={formData.serviceCompletion}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                error={!!errors.serviceCompletion}
                                helperText={errors.serviceCompletion}
                                onFocus={() => handleError('', 'serviceCompletion')}
                                style={{ borderRadius: 0 }}
                            />
                        </div>

                        <div style={{ display: 'flex', gap: 10 }}>
                            <TextField
                                label="Maintenance Type"
                                name="maintenanceType"
                                value={formData.maintenanceType}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                error={!!errors.maintenanceType}
                                helperText={errors.maintenanceType}
                                onFocus={() => handleError('', 'maintenanceType')}
                                style={{ borderRadius: 0 }}
                            />

                            {/* <TextField
                                label="IMEI NO"
                                name="imeiNo"
                                value={formData.imeiNo}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                error={!!errors.imeiNo}
                                helperText={errors.imeiNo}
                                onFocus={() => handleError('', 'imeiNo')}
                                style={{ borderRadius: 0 }}
                            /> */}
                        </div>

                        <div style={{ display: 'flex', gap: 10 }}>
                            <TextField
                                label="Gps Brand"
                                name="brand"
                                value={formData.brand}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                error={!!errors.brand}
                                helperText={errors.brand}
                                onFocus={() => handleError('', 'model')}
                                style={{ borderRadius: 0 }}
                            />

                            <TextField
                                label="Model"
                                name="model"
                                value={formData.model}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                error={!!errors.model}
                                helperText={errors.model}
                                onFocus={() => handleError('', 'model')}
                                style={{ borderRadius: 0 }}
                            />
                        </div>

                        <div style={{ display: 'flex', gap: 10 }}>
                            <TextField
                                label="Frequency"
                                name="frequency"
                                value={formData.frequency}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                error={!!errors.frequency}
                                helperText={errors.frequency}
                                onFocus={() => handleError('', 'frequency')}
                                style={{ borderRadius: 0 }}
                            />

                            <TextField
                                label="Warranty"
                                name="warranty"
                                value={formData.warranty}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                error={!!errors.warranty}
                                helperText={errors.warranty}
                                onFocus={() => handleError('', 'warranty')}
                                style={{ borderRadius: 0 }}
                            />
                        </div>

                        <div style={{ display: 'flex', gap: 10 }}>
                            <TextField
                                label="Color"
                                name="color"
                                value={formData.color}
                                onChange={handleInputChange}
                                variant="outlined"
                                style={{ borderRadius: 0, width: '49%' }}
                                error={!!errors.color}
                                helperText={errors.color}
                                onFocus={() => handleError('', 'color')}
                            />

                            <Box display="flex" gap={1} flexWrap="wrap">
                                {['no relay', '12v', '24v'].map((option) => (
                                    <Chip
                                        key={option}
                                        label={option.toUpperCase()}
                                        clickable
                                        variant={formData?.relay?.includes(option) ? 'filled' : 'outlined'}
                                        color={formData?.relay?.includes(option) ? 'primary' : 'default'}
                                        onClick={() => handleChipClick(option)}
                                    />
                                ))}

                            </Box>
                        </div>

                        <Box display="flex" flexWrap="wrap" gap={2}>
                            {
                                features.map((feature) => (
                                    <FormControlLabel
                                        key={feature}
                                        control={<Checkbox checked={formData.features?.includes(feature)} onChange={(e) => handleCheckboxChange(e, feature)} />}
                                        label={feature}
                                    />
                                ))
                                //     < FormControlLabel
                                //     control={<Checkbox name="mobileSupported" checked={formData.mobileSupported} onChange={handleCheckboxChange} sx={{ color: '#1e6e8a', '&.Mui-checked': { color: '#1e6e8a' } }} />}
                                // label="Mobile Supported"
                                // />
                            }
                        </Box>

                        <div style={{ display: 'flex', gap: 10 }}>
                            <Grid item xs={12} sm={8}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Select Network</FormLabel>
                                    <RadioGroup row name="network" value={networkType} onChange={(e) => setNetworkType(e.target.value)}>
                                        <FormControlLabel value="2G" control={<Radio />} label="2G" />
                                        <FormControlLabel value="4G" control={<Radio />} label="4G" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Select Sim</FormLabel>
                                    <FormGroup row >
                                        <FormControlLabel control={<Checkbox onChange={handleSimChange} value={'jio'} checked={simCard?.includes('jio')} sx={{ color: '#1e6e8a', '&.Mui-checked': { color: '#1e6e8a' } }} />} label="Jio" />
                                        <FormControlLabel control={<Checkbox onChange={handleSimChange} value={'airtel'} checked={simCard?.includes('airtel')} sx={{ color: '#1e6e8a', '&.Mui-checked': { color: '#1e6e8a' } }} />} label="Airtel" />
                                        <FormControlLabel control={<Checkbox onChange={handleSimChange} value={'bsnl'} checked={simCard?.includes('bsnl')} sx={{ color: '#1e6e8a', '&.Mui-checked': { color: '#1e6e8a' } }} />} label="BSNL" />
                                        <FormControlLabel control={<Checkbox onChange={handleSimChange} value={'vi'} checked={simCard?.includes('vi')} sx={{ color: '#1e6e8a', '&.Mui-checked': { color: '#1e6e8a' } }} />} label="Vi" />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </div>


                        <div style={{ display: 'flex', gap: 10 }}>
                            <TextField
                                label="Amazon "
                                name="amazon"
                                value={formData?.amazon}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                style={{ borderRadius: 0 }}
                                error={!!errors?.amazon}
                                helperText={errors?.amazon}
                            />

                            <TextField
                                label="Flipkart"
                                name="flipkart"
                                value={formData?.flipkart}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                style={{ borderRadius: 0 }}
                                error={!!errors?.flipkart}
                                helperText={errors?.flipkart}
                            />
                        </div>

                        <div style={{ display: 'flex', gap: 10 }}>
                            <TextField
                                label="Meesho"
                                name="meesho"
                                value={formData?.meesho}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                style={{ borderRadius: 0 }}
                                error={!!errors?.meesho}
                                helperText={errors?.meesho}

                            />

                            <TextField
                                label="Snapdeal"
                                name="snapdeal"
                                value={formData?.snapdeal}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                style={{ borderRadius: 0 }}
                                error={!!errors?.snapdeal}
                                helperText={errors?.snapdeal}
                            />
                        </div>

                        <Grid item xs={12} sm={4}>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="isActive"
                                    onChange={handleInputChange}
                                    defaultValue={formData.isActive}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Active" />
                                    <FormControlLabel value={false} control={<Radio />} label="Draft" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>


                        <Grid item sm={4} xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isActive}
                                        onChange={(e) => setIsActive(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Active"
                            />
                        </Grid>

                        <Button
                            type="submit"
                            variant="contained"
                            disabled={loading}
                            style={{
                                backgroundColor: primaryColor,
                                color: 'white',
                                width: '25%',
                                borderRadius: 0,
                                boxShadow: 'none',
                                padding: '10px 0',
                                fontSize: '16px',
                            }}
                        >
                            {
                                Update ?
                                    <>
                                        {
                                            loading ? 'Update...' : 'Update'
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            loading ? 'Submit...' : 'Submit'
                                        }
                                    </>
                            }
                        </Button>
                    </Box>

                </Grid>
                <Grid item md={5}>
                    <Box component="label" sx={dropzoneStyle}>
                        <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={(e) => {
                                const image = Array.from(e.target.files);
                                if (Update === 'update') {
                                    setFormData({ ...formData, uploadNewImage: [...(formData.uploadNewImage || []), ...image] });
                                    setNewImages([...newImages, ...image]);
                                    handleCompressImage(image)
                                    setUploadNewImage([...image])
                                    handleError('', 'images')
                                } else {
                                    setFormData({ ...formData, images: [...(formData.images || []), ...image] });
                                    setImages([...images, ...image]);
                                    handleError('', 'images')
                                }
                                e.target.value = '';
                            }}

                            style={{ display: 'none' }}
                        />
                        <ImageIcon sx={{ fontSize: 48, color: primaryColor }} />
                        <Typography variant="h6" sx={{ color: primaryColor, marginTop: '16px' }}>
                            Choose your Images
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#999999' }}>
                            Accepts all Formats
                        </Typography>
                    </Box>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: "10px", marginTop: '3%' }}>
                        {formData?.images?.length > 0 && Update === 'update' ?
                            newImages?.map((image, index) => {
                                let src;
                                if (typeof image === 'string' && !image?.includes?.('.pdf')) {
                                    src = `${serverURL}/uploads/products-images/${image}`;
                                } else if (image instanceof File && !image?.name?.includes?.('.pdf')) {
                                    src = URL.createObjectURL(image);
                                } else {
                                    src = '';
                                }

                                return src && <Box
                                    key={index}
                                    sx={{
                                        position: 'relative',
                                        width: '150px',
                                        height: '150px',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <img
                                        src={src}
                                        alt={`Uploaded preview ${index}`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            const updatedNewImages = newImages.filter((_, i) => i !== index);
                                            setNewImages(updatedNewImages);
                                            setIsImageUpdate(true);
                                        }}
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                            },
                                        }}
                                    >
                                        <CloseIcon sx={{ fontSize: '16px', color: '#f44336' }} />
                                    </IconButton>
                                </Box>
                            })
                            :
                            images?.map((image, index) => (
                                !image?.name?.includes?.('.pdf') &&
                                <Box
                                    key={index}
                                    sx={{
                                        position: 'relative',
                                        width: '150px',
                                        height: '150px',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <img
                                        src={URL.createObjectURL(image)}
                                        alt={`Uploaded preview ${index}`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            const updatedImages = images.filter((_, i) => i !== index);
                                            setFormData({ ...formData, documents: updatedImages });
                                            setImages(updatedImages);
                                        }}
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                            },
                                        }}
                                    >
                                        <CloseIcon sx={{ fontSize: '16px', color: '#f44336' }} />
                                    </IconButton>
                                </Box>
                            ))}
                    </div>
                    <Box component="label" sx={dropzoneStyle} style={{ marginTop: '10px' }}>
                        <input
                            type="file"
                            accept="application/pdf"
                            multiple
                            onChange={(e) => {
                                const pdf = Array.from(e.target.files);
                                if (Update) {
                                    setFormData({ ...formData, uploadNewImage: [...(formData.uploadNewImage || []), ...pdf] });
                                    setNewImages([...newImages, ...pdf]);
                                } else {
                                    setFormData({ ...formData, images: [...(formData.images || []), ...pdf] });
                                    setImages([...images, ...pdf]);
                                    e.target.value = '';
                                }
                            }}

                            style={{ display: 'none' }}
                        />
                        <ImageIcon sx={{ fontSize: 48, color: primaryColor }} />
                        <Typography variant="h6" sx={{ color: primaryColor, marginTop: '16px' }}>
                            Choose your File
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#999999' }}>
                            Accepts in PDF Formats
                        </Typography>
                    </Box>

                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '3%' }}>
                        {formData.images?.map((image, index) => (
                            (image?.name ? image?.name?.includes?.('.pdf') : image?.includes?.('.pdf')) ? <Box key={index} sx={imagesBox} position="relative">

                                {Update === 'update' ?
                                    image?.includes?.('.pdf') ?
                                        <embed src={`${serverURL}/uploads/products-images/${image}`} width="100%" height="100%" type="application/pdf" />
                                        :
                                        <embed src={URL.createObjectURL(image)} width="100%" height="100%" type="application/pdf" />
                                    :
                                    image?.name?.includes?.('.pdf')
                                        ?
                                        <embed src={URL.createObjectURL(image)} width="100%" height="100%" type="application/pdf" />
                                        : <></>}

                                <IconButton
                                    onClick={() => {
                                        const updatedImages = formData.images.filter((_, i) => i !== index);
                                        setFormData({ ...formData, images: updatedImages });
                                        setIsImageUpdate(true);
                                        setNewImages(updatedImages);
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        top: '4px',
                                        right: '4px',
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                        },
                                    }}
                                >
                                    <CloseIcon sx={{ fontSize: '16px', color: '#f44336' }} />
                                </IconButton>
                            </Box> : <></>
                        ))}

                        {formData?.uploadNewImage ? formData?.uploadNewImage?.map((image, index) => {
                            return image?.name?.includes?.('.pdf') ? <Box key={index} sx={imagesBox} position="relative">
                                {image?.name?.includes?.('.pdf') ? <embed src={URL.createObjectURL(image)} width="100%" height="100%" type="application/pdf" /> : <></>}

                                <IconButton
                                    onClick={() => {
                                        const updatedImages = formData.uploadNewImage.filter((_, i) => i !== index);
                                        setFormData({ ...formData, uploadNewImage: updatedImages }); // Update formData with the filtered images
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        top: '4px',
                                        right: '4px',
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                        },
                                    }}
                                >
                                    <CloseIcon sx={{ fontSize: '16px', color: '#f44336' }} />
                                </IconButton>
                            </Box> : <></>
                        }) : <></>}

                    </div>
                    {!!errors.images ? <span style={{ color: '#EA2024', fontSize: 10 }}>{errors.images}</span> : ''}
                </Grid>
            </Grid>
        </>
    );
}