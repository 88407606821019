import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Table, TableHead, TableBody, TextField, Avatar, ListItem, ListItemAvatar, IconButton, List, Divider, Button } from '@mui/material';
import { formatDate, formatPrice, primaryColor } from '../constant';
import toast from 'react-hot-toast';
import { post_data, serverURL } from '../api';
import { makeStyles } from '@mui/styles';
import InvoiceModal from '../web-app/User/InvoiceModal';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.processing': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.shipped': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.delivered': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.cancelled': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.true': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
    },
});

const OrderDetailModel = ({ open, setOpen, data, getAllOrders }) => {
    const classes = useStyles();
    const [simMobileNo, setSimMobileNo] = useState(data?.productId);
    const [invoiceModal, setInvoiceModal] = useState(false);
    const [currentInvoice, setCurrentInvoive] = useState({})
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        setSimMobileNo(data?.productId);
    }, [data?.productId]);

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80vw',
            height: '80vh',
            border: 'none',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
            overflow: 'auto'
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,
        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right'
        },
        icon: {
            width: 22,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    const shippingAddress = data?.shippingAddress
        ? `${data.shippingAddress.street}, ${data.shippingAddress.city}, ${data.shippingAddress.zipCode}, ${data.shippingAddress.state}`
        : '-';

    const DataRow = ({ label, value }) => (
        <div style={styles.row}>
            <span style={styles.dataLabel}>{label}</span>
            {
                label === 'Receipt' ?
                    <>
                        <span style={{ marginLeft: 'auto', color: primaryColor, fontWeight: 600, cursor: 'pointer' }}
                            onClick={() => {
                                setInvoiceModal(true)
                                setCurrentInvoive(data)
                            }}
                        >Download
                        </span>
                    </>
                    :
                    label === 'Status' ?
                        <Button
                            style={{ fontSize: 11, fontWeight: 500, borderRadius: 30, marginLeft: 'auto' }}
                            className={`${classes.stageButton} ${value?.toLowerCase()?.replace(' ', '')}`}
                        >
                            {value}
                        </Button>
                        :
                        <span style={styles.dataValue}>{value}</span>

            }
        </div>
    );


    const handleUpdateSimMobileNo = async (e, id) => {
        if (e.key === 'Enter') {
            const result = await post_data(`product/update-product/${id}`, { simMobileNo: e.target.value });
            if (result?.status === true) {
                toast.success("Sim Mobile No. Updated Successfully");
                getAllOrders();
                handleClose();
            } else {
                toast.error("Something went wrong");
            }
        }
    };

    return (
        <>
        <InvoiceModal
            open={invoiceModal}
            setOpen={setInvoiceModal}
            currentInvoice={currentInvoice}
        />
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                    Order&apos;s Details
                </Typography>
                <Box style={{ display: 'flex' }}>
                    <Box sx={{ padding: '2%', width: '50%' }}>
                        <DataRow label="Receipt" value={data?.orderUniqueId} />
                        <DataRow label="User Name" value={data?.userId?.name || '-'} />
                        <DataRow label="Phone" value={data?.userId?.phone || '-'} />
                        <DataRow label="Product Name" value={data?.productId?.map((item) => <div>{item?.name}</div>) || '-'} />
                        <DataRow label="Amount Without Gst" value={formatPrice(data?.amountBeforGst) || '-'} />
                        <DataRow label="Paid Amount" value={formatPrice(data?.amount) || '-'} />
                        <DataRow label="Address" value={shippingAddress || '-'} />
                        <DataRow label="Status" value={data?.orderStatus || '-'} />
                        {/* <DataRow label="Status" value={data?.status || '-'} /> */}
                        <DataRow label="Date" value={formatDate(data?.createdAt) || '-'} />
                        <DataRow label="Transaction Number" value={data?.transactionNumber || '-'} />
                        <DataRow label="InVoice Number" value={data?.invoiceNumber || '-'} />
                    </Box>

                    <Box style={{ width: '50%', overflow: 'auto' }}>
                        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                            <List>
                                {data?.productId?.map((item, index) => (
                                    <div key={index} style={{ marginBottom: '30px', border: '1px solid #ddd', padding: '10px', boxSizing: 'border-box' }}>
                                        {/* Product Card */}
                                        <div style={{ borderRadius: '8px', display: 'flex', gap: '20px' }}>
                                            <ListItemAvatar style={{ flexShrink: 0 }}>
                                                <img src={`${serverURL}/uploads/products-images/${item?.images[0]}`} style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' }} />
                                            </ListItemAvatar>

                                            {/* Product Information */}
                                            <Box style={{ flexGrow: 1 }}>
                                                <Typography variant='h6'>
                                                    <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
                                                        <span>{formatPrice(item?.price)}</span>
                                                        <span style={{ fontSize: '14px', color: '#888' }}> x {data?.productInfo?.[item?._id]?.qty}</span>
                                                    </div>
                                                </Typography>


                                                <div style={{ display: 'flex', gap: '10px' }}>
                                                    {data?.productInfo?.[item?._id]?.relay != 'no relay' && <span
                                                        style={{
                                                            fontSize: '14px',
                                                            color: '#808080',
                                                            border: '1px solid #808080',
                                                            padding: '5px 10px',
                                                            textAlign: 'center',
                                                            borderRadius: '5px'
                                                        }}
                                                    >
                                                        {data?.productInfo?.[item?._id]?.relay}
                                                    </span>}
                                                    {data?.productInfo?.[item?._id]?.insurance != 'without insurance' && <span
                                                        style={{
                                                            fontSize: '14px',
                                                            color: '#808080',
                                                            border: '1px solid #808080',
                                                            padding: '5px 10px',
                                                            textAlign: 'center',
                                                            borderRadius: '5px'
                                                        }}
                                                    >
                                                        {data?.productInfo?.[item?._id]?.insurance}
                                                    </span>}

                                                    {data?.productInfo?.[item?._id]?.server != 'without server' && <span
                                                        style={{
                                                            fontSize: '14px',
                                                            color: '#808080',
                                                            border: '1px solid #808080',
                                                            padding: '5px 10px',
                                                            textAlign: 'center',
                                                            borderRadius: '5px'
                                                        }}
                                                    >
                                                        {data?.productInfo?.[item?._id]?.server}
                                                    </span>
                                                    }
                                                    {data?.productInfo?.[item?._id]?.simCard != 'no sim' && <span
                                                        style={{
                                                            fontSize: '14px',
                                                            color: '#808080',
                                                            border: '1px solid #808080',
                                                            padding: '5px 10px',
                                                            textAlign: 'center',
                                                            borderRadius: '5px'
                                                        }}
                                                    >
                                                        {data?.productInfo?.[item?._id]?.simCard}
                                                    </span>}
                                                </div>

                                                <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
                                                    {data?.productInfo?.[item?._id]?.imei && <span
                                                        style={{
                                                            fontSize: '14px',
                                                            color: '#808080',
                                                            border: '1px solid #808080',
                                                            padding: '5px 10px',
                                                            textAlign: 'center',
                                                            borderRadius: '5px'
                                                        }}
                                                    >
                                                        IMEI NO : {data?.productInfo?.[item?._id]?.imei}
                                                    </span>}

                                                    {data?.productInfo?.[item?._id]?.simNumber && <span
                                                        style={{
                                                            fontSize: '14px',
                                                            color: '#808080',
                                                            border: '1px solid #808080',
                                                            padding: '5px 10px',
                                                            textAlign: 'center',
                                                            borderRadius: '5px'
                                                        }}
                                                    >
                                                        SIM NO :  {data?.productInfo?.[item?._id]?.simNumber}
                                                    </span>}
                                                </div>
                                            </Box>
                                        </div>

                                        {data?.productInfo?.[item?._id]?.accessories?.length > 0 && (
                                            <div style={{ marginTop: '15px', padding: '10px 0' }}>
                                                <Typography variant="body1" style={{ fontSize: '16px', fontWeight: '500', marginBottom: '10px' }}>
                                                    Accessories:
                                                </Typography>
                                                <div style={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
                                                    {data?.productInfo?.[item?._id]?.accessories.map((accessory, i) => (
                                                        <div
                                                            key={i}
                                                            style={{
                                                                position: 'relative',
                                                                width: '130px',
                                                                cursor: 'pointer',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {/* Accessory Image */}
                                                            <img
                                                                src={`${serverURL}/uploads/accessories-images/${accessory?.images?.[0]}`}
                                                                variant="rounded"
                                                                style={{
                                                                    width: '100%',
                                                                    height: '120px',
                                                                    borderRadius: '8px',
                                                                    objectFit: 'cover',
                                                                }}
                                                            />

                                                            {/* Transparent Overlay for Accessory Name and Price */}
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '5px',
                                                                    left: '0',
                                                                    right: '0',
                                                                    padding: '5px 10px',
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Slightly darker transparent background for better contrast
                                                                    borderRadius: '4px',
                                                                    color: '#fff',
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                {/* Accessory Name */}
                                                                <div
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        maxWidth: '100%',
                                                                        fontSize: '14px'
                                                                    }}
                                                                >
                                                                    {accessory?.title}
                                                                </div>

                                                                {/* Accessory Price */}
                                                                <div
                                                                    style={{
                                                                        fontSize: '14px',
                                                                        textAlign: 'center',
                                                                    }}
                                                                >
                                                                    {formatPrice(accessory?.price)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </List>
                        </div>

                    </Box >
                </Box >
            </Box>
        </Modal >
        </>
    );
};

export default OrderDetailModel;
