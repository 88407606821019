import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Menu,
    MenuItem,
    IconButton,
    Avatar,
    Typography,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import { post_data, serverURL } from '../../api';
import { useNavigate } from 'react-router-dom';
import TableLoader from './TableLoader';
import EmptyPage from '../EmptyPage';
import { formatDate, primaryColor } from '../../constant';
import toast from 'react-hot-toast';
import VendorDetailModal from '../VendorDetailModal';
import DriverDetailModal from "../DriverDetailModal";
import AdminRecordModal from '../models/AdminRecordModal';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        backgroundColor: '#ffebee',
        color: '#e53935',
        '&.installed': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.claimed': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.returned': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.uninstalled': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        }
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const RecordsTable = ({ data, tableHeader, getAllRecords, setLoading, totalData, loading, currentPage, setCurrentPage, itemsPerPage, totalPages, refresh, setRefresh }) => {
    // console.log('dummyData', data)
    const classes = useStyles();
    const navigate = useNavigate();
    const [rowsData, setRowsData] = useState(data || []);
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [vendorOpen, setVendorOpen] = useState(false);
    const [driverOpen, setDriverOpen] = useState(false);

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    useEffect(() => { setRowsData(data) }, [data])
    // Close the options menu
    const handleMenuClose = (row) => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handleEdit = (row) => {
        setEditData(row)
        setOpen(true)
        setAnchorEl(null);
        setCurrentRow(null);
    };
    // Delete a product after confirmation
    const handleDelete = async (row) => {
        setAnchorEl(null);
        setCurrentRow(null);

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const result = await post_data(`record/delete-record/${row?._id}`, {});

                    if (result.status) {
                        toast.success("Record Deleted Successfully");
                        getAllRecords();
                    } else {
                        toast.error("Something went wrong");
                    }
                } catch (error) {
                    toast.error("An error occurred during deletion");
                }
            }
        });
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    return (
        <>
            <DriverDetailModal open={driverOpen} setOpen={setDriverOpen} data={currentRow} />
            {/* <VendorDetailModal open={vendorOpen} setOpen={setVendorOpen} data={currentRow} /> */}
            <AdminRecordModal open={open} setOpen={setOpen} editData={editData} isUpdate={true} getAllRecords={getAllRecords} />
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {tableHeader?.map((item, idx) => (
                                <TableCell
                                    key={idx}
                                    style={{
                                        borderRight: '1px solid gainsboro',
                                        padding: '16px 10px',
                                        // width: idx === 0 ? '5%' : '20%', // Adjust widths as needed
                                    }}
                                >
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {loading ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHeader.length} style={{ textAlign: 'center' }}>
                                    <TableLoader />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {rowsData?.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        style={{
                                            borderRight: '1px solid gainsboro',
                                            textAlign: 'center',
                                            padding: '0px',
                                            width: '5%',
                                        }}
                                    >
                                        {startEntry + index}
                                    </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>{row?.clientId?.companyName || '-'}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>{row?.vendor || '-'}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>{row?.vehicleNumber || '-'}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>{row?.imei || '-'}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>{row?.simNumber || '-'}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>{row?.deviceUniqueId || '-'}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%', cursor: 'pointer' }}
                                        onClick={() => {
                                            setDriverOpen(true)
                                            setCurrentRow(row?.driverDetails || '-')
                                        }}
                                    >
                                        <span style={{ color: primaryColor, fontSize: 11, fontWeight: 500, textDecoration: 'underline' }}>View Detail</span>
                                    </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>{row?.installDate ? formatDate(row?.installDate) : '-' || '-'}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>{row?.uninstallDate ? formatDate(row?.uninstallDate) : row?.claimDate ? formatDate(row?.claimDate) : row?.returnDate ? formatDate(row?.returnDate) : '-'}</TableCell> 
                                    {/* <TableCell style={{ borderRight: '1px solid gainsboro', cursor: 'pointer', width: '5%' }} onClick={() => {
                                        setVendorOpen(true)
                                        setCurrentRow(row?.requestId?.gps[0]?.vendor || '-')
                                    }} >
                                        <span style={{ color: primaryColor, fontSize: 11, fontWeight: 500, textDecoration: 'underline' }}>View Detail</span>
                                    </TableCell> */}
                                    <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                        <Button
                                            style={{ fontSize: 11, fontWeight: 500, borderRadius: 30 }}
                                            className={`${classes.stageButton} ${row?.gpsStatus?.toLowerCase()?.replace(' ', '')}`}
                                        >
                                            {row?.gpsStatus || '-'}
                                        </Button>
                                    </TableCell>

                                    {/* <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                        <IconButton
                                            className={classes.moreIcon}
                                            onClick={(event) => handleMenuOpen(event, index)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>

                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl) && currentRow === index}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>
                                            <MenuItem onClick={() => handleDelete(row)}>Delete</MenuItem>
                                        </Menu>
                                    </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>

                    )}
                </Table>

                {data?.length > 0 && (
                    <div
                        style={{
                            borderTop: '1px solid gainsboro',
                            padding: '2%',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: 'black',
                            }}
                        >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}
            </TableContainer >

        </>
    );
};

export default RecordsTable;