import { Button, Grid } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { createButtonBg } from "../../../constant";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import EmptyPage from "../../../components/EmptyPage";
import { Toaster } from "react-hot-toast";
import SearchComponent from "../../../components/SearchComponent";
import BillingTable from "../../../components/tables/BillingTable";

export default function Billing() {
    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')

    const getAllBilling = async () => {
        try {
            const data = await get_data(`billing/get-all-billings?pageNumber=${currentPage}`);
            if (data.status === true) {
                setLoading(false);
                setData(data?.data?.billing);
                setTotalPages(data?.data?.totalPage);
                setTotalData(data?.data?.totalBillings);
            }
            setLoading(false);

        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllBilling(currentPage);
        }
    }, [currentPage])

    const handleSearch = async () => {
        setLoading(true);
        if (value.length > 0) {
            const data = await get_data(`billing/search-billing/${value}?pageNumber=${currentPage}`)
            if (data.status === true) {
                setData(data?.data?.billing);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalBillings);
            }
        } else {
            getAllBilling(currentPage);
        }
        setLoading(false);
    }

    const itemsPerPage = 15;
    const count = data?.length;


    const tableHeader = [
        "S No.","Month", 'Company Name',  'Total Gps', 'Total Amount', 'Vehicles', 'Device UID',"Payment Date", "Reference Number", "Status"
    ]


    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                </div>

                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage tableHeader={tableHeader} />
                        </>
                        :
                        <>
                            <BillingTable
                                data={data}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getAllBilling={getAllBilling}
                            />
                        </>
                }
            </Grid>

        </>
    )
}