import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import OtpComponent from './OtpComponent';
import { primaryColor } from '../constant';
import { post_data } from '../api';
import toast from 'react-hot-toast';

export default function LoginDrawer({
    open,
    setOpen
}) {
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');
    const [otpForm, setOtpForm] = React.useState(false)
    const [numnerForm, setNumberForm] = React.useState(true)

    const handleDrawerToggle = () => {
        setOpen(!open);
        setError(false);
        setHelperText('')
    };

    const handlePhoneNumberChange = (event) => {
        const value = event.target.value;

        // Allow only numeric input
        if (/^\d*$/.test(value)) {
            setPhoneNumber(value);

            // Validate length
            if (value.length !== 10) {
                setError(true);
                setHelperText('Phone number must be 10 digits');
            } else {
                setError(false);
                setHelperText('');
            }
        }
    };

    const handleLogin = async () => {
        if (phoneNumber?.length === 10) {
            let result;

            result = await post_data(`user/send-otp-to-user-login`, { phone: phoneNumber })
            console.log('result', result)

            if (result?.status === true) {
                alert(result?.data)
                toast.success(result?.message);
                setOtpForm(true);
                setNumberForm(false);
            } else {
                toast.error(result?.response?.data.message || "Something went wrong")
            }

        } else {
            setError(true);
            setHelperText('Phone number must be 10 digits');
        }
    };

    return (
        <Drawer
            anchor={'right'}
            open={open}
            onClose={handleDrawerToggle}
            PaperProps={{
                style: {
                    backgroundColor: 'white',
                    color: 'black',
                    width: 300,
                    padding: '20px',
                },
            }}
        >

            {
                numnerForm && (
                    <>
                        <Box display="flex" flexDirection="column" justifyContent="start" alignItems="center" height="100%">
                            <h2>Login with Phone Number</h2>
                            <TextField
                                label="Phone Number"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={phoneNumber}
                                error={error}
                                helperText={helperText}
                                onChange={handlePhoneNumberChange}
                                inputProps={{
                                    style: { fontSize: '16px' }, // Adjust font size of input
                                    maxLength: 10 // Limit input length to 10 digits
                                }}
                            />

                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleLogin}
                                style={{
                                    backgroundColor: primaryColor,
                                    color: 'white',
                                    boxShadow: 'none',
                                    marginTop: '20px',
                                    borderRadius: 0, // Removes border-radius
                                    padding: '10px 0',
                                    fontSize: '16px',
                                }}
                            >
                                Login
                            </Button>
                        </Box>
                    </>
                )
            }

            {
                otpForm && (
                    <>
                        <OtpComponent
                            type="user_signup"
                            setOpen={setOpen}
                            open={open}
                            handleResend={handleLogin}
                            mobileNumber={phoneNumber}
                        />
                    </>
                )
            }
        </Drawer>
    );
}
