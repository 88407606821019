import * as React from 'react';
import { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Box, Grid, Typography, IconButton, Autocomplete, Accordion, AccordionSummary, AccordionDetails, OutlinedInput, InputAdornment, FormControl, InputLabel, FormHelperText } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import imageCompression from "browser-image-compression";
import ImageIcon from '@mui/icons-material/Image';
import { primaryColor } from '../constant';
import CloseIcon from '@mui/icons-material/Close';
import { get_data, post_data, serverURL } from '../api';
import Swal from 'sweetalert2';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { City, Country, State } from 'country-state-city';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AdminChangPasswordModals from './tables/AdminChangPasswordModals';
import { FaRegFileAlt } from 'react-icons/fa';

export default function CreateClients() {

    const location = useLocation()

    const Update = location?.state?.title || ''
    const [rowsData, setRowsData] = useState(location?.state?.row || '');
    const [newImages, setNewImages] = useState([])
    const [compressedImages, setCompressedImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countryCode] = useState('IN');
    const [sitesCityList, setSitesCityList] = useState([])
    const [sitesStateList, setSitesStateList] = useState([])
    const [sitesCountryList, setSitesCountryList] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfrimPassword, setShowConfrimPassword] = useState(false);
    const [images, setImages] = useState({ bytes: '', filename: '' })
    const [images2, setImages2] = useState({ bytes: '', filename: '' })
    const [images3, setImages3] = useState({ bytes: '', filename: '' })
    const [images4, setImages4] = useState({ bytes: '', filename: '' })
    const [images5, setImages5] = useState({ bytes: '', filename: '' })
    const [imageIndex, setImageIndex] = useState([]);
    const [isImageUpdate, setIsImageUpdate] = useState(false);
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState(location?.state?.row || {
        // name: '',
        // price: '',
        // type: '',
        // trackingDeviceType: '',
        // trackingMethod: '',
        // gpsTrackerType: '',
        // serviceCompletion: '',
        // maintenanceType: '',
        // imeiNo: '',
        // frequency: '',
        // warranty: '',
        // color: '',
        // mobileSupported: false,
        // webConnectivity: false,
        // geoFencing: false,
        // touchScreen: false,
        // mobileAccess: false,
        // emailAlert: false,
        // waterproof: false,
        // description: '',
        // images: [],
        // uploadNewImage: [],
        sites: [{
            address: {}
        }]
    } || location?.state?.row);

    let navigate = useNavigate()
    const dropzoneStyle = {
        border: '2px dashed ' + primaryColor,
        borderRadius: '8px',
        padding: '40px',
        textAlign: 'center',
        width: '100%',
        margin: 'auto',
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
        transition: '0.3s',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
    }

    const imagesBox = {
        position: 'relative',
        width: 130,
        height: 130,
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfrimPassword = () => setShowConfrimPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const getCilentDetails = async () => {
        let result = await get_data(`client/get-client/${localStorage.getItem('clientId')}`)

        console.log('result?.dataresult?.data', result?.data)
        if (result?.status == true) {
            setFormData((prev) => {
                return {
                    ...prev,
                    id: result?.data?._id,
                    companyName: result?.data?.companyName,
                    contactNo: result?.data?.contactNo,
                    email: result?.data?.email,
                    resContactNo: result?.data?.resContactNo,
                    gpsRate: result?.data?.gpsRate,
                    gps_installation_charge: result?.data?.gps_installation_charge,
                    gps_uninstallation_charge: result?.data?.gps_uninstallation_charge,
                    claim_charge: result?.data?.claim_charge,
                    panic_button_charge: result?.data?.panic_button_charge,
                    wiring_charge: result?.data?.wiring_charge,
                    service_charge: result?.data?.service_charge,
                    speedGoverner: result?.data?.speedGoverner,
                    address: {
                        street: result?.data?.address?.street,
                        city: result?.data?.address?.city,
                        state: result?.data?.address?.state,
                        country: result?.data?.address?.country,
                        zipCode: result?.data?.address?.zipCode,
                        landmark: result?.data?.address?.landmark,
                    },
                    doc1Name: result?.data?.doc1Name,
                    doc2Name: result?.data?.doc2Name,
                    doc3Name: result?.data?.doc3Name,
                    doc4Name: result?.data?.doc4Name,
                    doc5Name: result?.data?.doc5Name,
                    doc1: result?.data?.doc1,
                    doc2: result?.data?.doc2,
                    doc3: result?.data?.doc3,
                    doc4: result?.data?.doc4,
                    doc5: result?.data?.doc5,
                    sites: result?.data?.sites?.map((item, i) => {
                        const fetchedStates = State.getStatesOfCountry(item?.country);
                        setSitesStateList(fetchedStates)

                        const fetchedCities = City.getCitiesOfState(countryCode, item?.state);
                        setSitesCityList(fetchedCities);

                        return item
                    })
                    // sites: result?.data?.sites?.map((item, i) => {
                    //     const fetchedCities = City.getCitiesOfState(countryCode, item?.state);
                    //     setSitesCityList(fetchedCities);
                    //     return item
                    // })
                    // sites: result?.data?.sites?.map((item, i) => {
                    //     const fetchedCities = City.getCitiesOfState(countryCode, item?.state);
                    //     setSitesCityList(fetchedCities);
                    //     return item
                    // })
                }
            })

        }
    }

    console.log("xxxxxxxxxx", formData)
    React.useEffect(() => {
        if (localStorage.getItem('clientId')) {
            getCilentDetails()
            setIsUpdate(true)
        } else if (localStorage.getItem('createClient')) {
            setIsUpdate(false)
        }
    }, [])

    // const fetchStates = async () => {
    //     setSitesStateList(State.getStatesOfCountry(countryCode));
    // }

    React.useEffect(function () {
        setCountryList(Country.getAllCountries())
    }, [])

    React.useEffect(() => {
        // fetchStates()
        setSitesCountryList(Country.getAllCountries())
    }, [countryCode]);

    React.useEffect(() => {
        if (formData?.address?.country) {
            const fetchedStates = State.getStatesOfCountry(formData?.address?.country);
            setStateList(fetchedStates);
        }
    }, [formData?.address?.country, formData?.address?.state]);

    React.useEffect(() => {
        if (formData?.address?.state) {
            const fetchedCities = City.getCitiesOfState(formData?.address?.country, formData?.address?.state);
            setCityList(fetchedCities);
        }
    }, [formData?.address?.state])

    const handleCountryChange = (event, newValue) => {
        if (newValue) {
            setFormData({ ...formData, address: { ...formData.address, country: newValue.isoCode, state: '', city: '', } });
            const fetchedStates = State.getStatesOfCountry(newValue.isoCode);
            setStateList(fetchedStates);
        }
    };

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            setFormData({ ...formData, address: { ...formData.address, state: newValue.isoCode, city: '', } });

            const fetchedCities = City.getCitiesOfState(formData.address.country, newValue.isoCode);
            setCityList(fetchedCities);
        }
    };

    // Handler to update city selection
    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setFormData({ ...formData, address: { ...formData.address, city: newValue.name, } });
        }
    };

    const handleSiteCountryChange = (index, event, newValue) => {
        if (newValue) {
            const countryCode = newValue.isoCode;
            const fetchedStates = State.getStatesOfCountry(countryCode);
            setSitesStateList(fetchedStates);
            // console.log('fetchedStates', fetchedStates)
            setFormData((prev) => {
                const updatedSite = prev?.sites?.map((site, idx) => {
                    if (index === idx) {
                        return { ...site, country: newValue?.isoCode, state: '', city: '' };
                    }
                    return site;
                });
                return { ...prev, sites: updatedSite };
            });
        }
    };


    const handleSiteStateChange = (index, event, newValue) => {
        if (newValue) {
            // console.log("fetchedCitys",  newValue.isoCode)
            const fetchedCities = City.getCitiesOfState(countryCode, newValue.isoCode);
            setSitesCityList(fetchedCities);
            // console.log("fetchedCitys",fetchedCities)
            setFormData((prev) => {
                const updatedSite = prev?.sites?.map((site, idx) => {
                    if (index === idx) {
                        return { ...site, state: newValue?.isoCode, city: '' };
                    }
                    return site;
                });
                return { ...prev, sites: updatedSite };
            });
        }
    };

    const handleSiteCityChange = (index, event, newValue) => {
        if (newValue) {
            setFormData((prev) => {
                const updatedSite = prev?.sites?.map((site, idx) => {
                    if (index === idx) {
                        return { ...site, city: newValue.name };
                    }
                    return site;
                });
                return { ...prev, sites: updatedSite };
            });

        }
    };

    const validateForm = () => {
        const newErrors = {};
        var error = false
        if (!formData.companyName) {
            newErrors.companyName = 'Company name is required';
            // error = true
        }
        if (!formData.contactNo) {
            newErrors.contactNo = 'Contact No is required';
            // error = true
        }

        if (formData?.contactNo?.length < 10) {
            newErrors.contactNo = "Contact No should be of 10 numbers"
            // error = true
        }

        if (!formData.email) {
            newErrors.email = 'Email is required';
            // error = true
        }
        if (!formData.resContactNo) {
            newErrors.resContactNo = 'Residental Contact No is required';
            // error = true
        }

        if (formData?.resContactNo?.length < 10) {
            newErrors.resContactNo = 'Residental Contact should of 10 numbers';
            // error = true
        }


        if (!formData?.address?.street) {
            newErrors.street = 'Street is required';
            error = true
        }
        if (!formData?.address?.state) {
            newErrors.state = 'State is required';
            // error = true
        }
        if (!formData?.address?.city) {
            newErrors.city = 'City is required';
            // error = true
        }
        if (!formData?.address?.zipCode) {
            newErrors.zipCode = 'ZipCode is required';
            // error = true
        }

        if (!formData?.password) {
            newErrors.password = "Please input password"
            error = true
        }

        if (!formData?.confirmPassword) {
            newErrors.confirmPassword = "Please input confirm password"
            // error = true
        }

        if (formData?.password != formData?.confirmPassword) {
            newErrors.confirmPassword = "Password and confirm password should be same"
            // error = true
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSitesInputChange = (index, field, value) => {
        if (field === "zipCode") {
            if (value.length <= 10) {

                setFormData((prev) => {
                    let updatedSites = prev?.sites?.map((form, idx) => {
                        if (index === idx) {
                            return { ...form, [field]: value.replace(/\D/g, '').slice(0, 10) };
                        }
                        return form;
                    });
                    return { ...prev, sites: updatedSites };
                });

                setErrors({ ...errors, [field]: false })
            }
            return
        } else {
            setFormData((prev) => {
                let updatedSites = prev?.sites?.map((form, idx) => {
                    if (index === idx) {
                        return { ...form, [field]: value };
                    }
                    return form;
                });
                return { ...prev, sites: updatedSites };
            });
        }

    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "contactNo" || name === "resContactNo" || name === "zipCode" || name === 'gps_installation_charge' || name === 'gpsRate') {
            if (value.length <= 10) {
                setFormData({ ...formData, [name]: value.replace(/\D/g, '').slice(0, 10) });
                setErrors({ ...errors, [name]: false })
            }
            return
        }
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: false })
    };

    const handleSubmit = async (event) => {

        event.preventDefault();
        if (validateForm()) {
            setLoading(true)
            let body = { ...formData }
            try {
                const response = await post_data('client/create-client', body, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.status === true) {
                    setLoading(false)
                    toast.success("Client created successfully!");
                    setFormData('')
                    navigate('/admin/dashboard/rental/company')
                } else {
                    setLoading(false)
                    toast.error(response?.response?.data?.message || "Something went wrong!");
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        setLoading(true)
        if (isImageUpdate) {
            let body = { ...formData }

            const indexs = imageIndex?.sort((a, b) => a - b);

            const data = new FormData();
            data.append('indexs', JSON.stringify(indexs));
            data.append('doc1', images.bytes);
            data.append('doc2', images2.bytes);
            data.append('doc3', images3.bytes);
            data.append('doc4', images4.bytes);
            data.append('doc5', images5.bytes);

            try {
                const response = await post_data(`client/update-client-image/${body?.id}`, data);
                if (response?.status === true) {
                    toast.success("Client updated successfully!");
                    navigate('/admin/dashboard/rental/company')
                } else {
                }
                setLoading(false);
            } catch (error) {
                setLoading(false)
                toast.error("Something went wrong!");
            }
        }
        else {
            let body = { ...formData }
            try {
                const response = await post_data(`client/update-client/${body?.id}`, body, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response?.status) {
                    setLoading(false)
                    toast.success("client updated successfully!");
                    setFormData('')
                    navigate('/admin/dashboard/rental/company')
                } else {
                }
            } catch (error) {
                setLoading(false)
                toast.error("Something went wrong!");
            }
        }
    }

    const handleCompressImage = async () => {
        const compressedImageArray = [];
        for (let i = 0; i < images?.length; i++) {
            const image = images[i];
            const options = {
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };
            try {
                const compressedImage = await imageCompression(image, options);
                const compressedFile = new File(
                    [compressedImage],
                    image.name,
                    { type: image.type, lastModified: Date.now() }
                );
                compressedImageArray.push(compressedFile);
            } catch (error) {
            }
        }
        if (Update) {
            setCompressedImages([...newImages, ...compressedImageArray]);
        } else {
            setCompressedImages([...compressedImageArray]);
        }
    };

    console.log("formData", formData)


    const modules = React.useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', "strike"],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['image', "link", "video"],
                ['clean'],
                ['code-block'],
                [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
            ],
            imageResize: {
                displaySize: true
            }
        },
    }), []);

    const buttonStyles = {
        borderColor: primaryColor,
        color: 'white',
        background: primaryColor,
        textTransform: 'capitalize',
        borderRadius: 0,
        fontWeight: 400,
        padding: '1% 3.5%',
        boxShadow: 'none',
        '&:hover': {
            borderColor: primaryColor,
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            borderColor: primaryColor,
        }
    };

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const addForm = () => {
        setFormData(prevState => ({
            ...prevState,
            sites: [
                ...prevState.sites,
                {
                    address: {}
                }
            ]
        }));
    };

    const handleDocumant = (event) => {
        setIsImageUpdate(true)
        if (!imageIndex?.includes(1)) {
            setImageIndex([...imageIndex, 1]);
        }
        setImages({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]), name: event.target.files[0].name })
    }
    const handleDocumant2 = (event) => {
        setIsImageUpdate(true)
        if (!imageIndex?.includes(2)) {
            setImageIndex([...imageIndex, 2]);
        }
        setImages2({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]), name: event.target.files[0].name })
    }
    const handleDocumant3 = (event) => {
        setIsImageUpdate(true)
        if (!imageIndex?.includes(3)) {
            setImageIndex([...imageIndex, 3]);
        }
        setImages3({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]), name: event.target.files[0].name })
    }
    const handleDocumant4 = (event) => {
        setIsImageUpdate(true)
        if (!imageIndex?.includes(4)) {
            setImageIndex([...imageIndex, 4]);
        }
        setImages4({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]), name: event.target.files[0].name })
    }
    const handleDocumant5 = (event) => {
        setIsImageUpdate(true)
        if (!imageIndex?.includes(5)) {
            setImageIndex([...imageIndex, 5]);
        }
        setImages5({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]), name: event.target.files[0].name })
    }

    return (
        <div>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Grid container spacing={2} style={{ width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <Grid item md={8}>
                    <Box
                        component="form"
                        onSubmit={isUpdate ? handleUpdate : handleSubmit}
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        margin="auto"
                        padding="35px"
                        bgcolor="white"
                        borderRadius={2}
                    >
                        <TextField
                            label="Company Name"
                            name="companyName"
                            value={formData?.companyName || ""}
                            onChange={handleInputChange}
                            fullWidth
                            variant="outlined"
                            style={{ borderRadius: 0 }}
                            error={!!errors?.companyName}
                            helperText={errors?.companyName}
                        />

                        <div style={{ display: 'flex', gap: 10 }}>
                            <TextField
                                label="Contact Number"

                                name="contactNo"
                                value={formData?.contactNo || ""}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                style={{ borderRadius: 0 }}
                                error={!!errors?.contactNo}
                                helperText={errors?.contactNo}
                            />

                            <TextField
                                type='email'
                                label="Email Id"
                                name="email"
                                value={formData?.email || ""}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                error={!!errors?.email}
                                style={{ borderRadius: 0 }}
                                helperText={errors?.email}
                            />

                            <TextField
                                label="Residental Contact Number"
                                name="resContactNo"

                                value={formData?.resContactNo || ""}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                style={{ borderRadius: 0 }}
                                error={!!errors?.resContactNo}
                                helperText={errors?.resContactNo}
                            />
                        </div>

                        <Grid item xs={12}>
                            <Typography component="h1">Address</Typography>
                        </Grid>

                        <div style={{ display: 'flex', gap: 10 }}>
                            <Grid item xs={4}>
                                <Autocomplete
                                    id="country-select"
                                    options={countryList}
                                    value={countryList.find((c) => c?.isoCode === formData?.address?.country) || null}
                                    onChange={handleCountryChange}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            error={Boolean(errors?.country)}
                                            helperText={errors?.country}
                                            onFocus={() => handleError('', 'country')}
                                            {...params}
                                            label="Select country"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Autocomplete
                                    id="state-select"
                                    options={stateList}
                                    value={stateList.find((s) => s?.isoCode === formData?.address?.state) || null}
                                    onChange={handleStateChange}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            error={Boolean(errors?.state)}
                                            helperText={errors?.state}
                                            onFocus={() => handleError('', 'state')}
                                            {...params}
                                            label="Select State"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Autocomplete
                                    id="city-select"
                                    options={cityList}
                                    value={cityList.find((c) => c?.name === formData?.address?.city) || null}
                                    onChange={handleCityChange}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            error={Boolean(errors?.city)}
                                            helperText={errors?.city}
                                            onFocus={() => handleError('', 'city')}
                                            {...params}
                                            label="Select City"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </div>
                        <div style={{ display: 'flex', gap: 10 }}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Address"
                                    error={Boolean(errors?.landmark)}
                                    helperText={errors?.landmark}
                                    onFocus={() => handleError('', 'landmark')}
                                    value={formData?.address?.landmark || ''}
                                    onChange={(e) => { setFormData({ ...formData, address: { ...formData.address, landmark: e.target.value } }) }}
                                    fullWidth
                                    name='landmark'
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Street"
                                    error={Boolean(errors?.street)}
                                    helperText={errors?.street}
                                    onFocus={() => handleError('', 'street')}
                                    value={formData?.address?.street || ''}
                                    onChange={(e) => { setFormData({ ...formData, address: { ...formData.address, street: e.target.value } }) }}
                                    fullWidth
                                    name='street'
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Zip Code"
                                    value={formData?.address?.zipCode || ''}
                                    error={Boolean(errors.zipCode)}
                                    helperText={errors.zipCode}
                                    onFocus={() => handleError('', 'zipCode')}
                                    onChange={(e) => { setFormData({ ...formData, address: { ...formData.address, zipCode: e.target.value.replace(/\D/g, '').slice(0, 10) } }) }}
                                    fullWidth
                                    name='zipCode'
                                />
                            </Grid>
                        </div>

                        <div style={{ display: 'flex', gap: 10 }}>
                            <Grid item xs={6}>
                                <TextField
                                    label="GPS Rate (Per Day)"
                                    name="gpsRate"
                                    value={formData?.gpsRate || ""}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="outlined"
                                    style={{ borderRadius: 0 }}
                                    error={!!errors?.gpsRate}
                                    helperText={errors?.gpsRate}
                                    onFocus={() => handleError('', 'gpsRate')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Installation Charge"
                                    name="gps_installation_charge"
                                    value={formData?.gps_installation_charge || ""}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="outlined"
                                    style={{ borderRadius: 0 }}
                                    error={!!errors?.formData?.gps_installation_charge}
                                    helperText={errors?.formData?.gps_installation_charge}
                                    onFocus={() => handleError('', 'gps_installation_charge')}
                                />
                            </Grid>
                        </div>


                        <div style={{ display: 'flex', gap: 10 }}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Uninstallation Charge"
                                    name="gps_uninstallation_charge"
                                    value={formData?.gps_uninstallation_charge || ""}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="outlined"
                                    style={{ borderRadius: 0 }}
                                    error={!!errors?.formData?.gps_uninstallation_charge}
                                    helperText={errors?.formData?.gps_uninstallation_charge}
                                    onFocus={() => handleError('', 'gps_uninstallation_charge')}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Claim Charge"
                                    name="claim_charge"
                                    value={formData?.claim_charge || ""}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="outlined"
                                    style={{ borderRadius: 0 }}
                                    error={!!errors?.claim_charge}
                                    helperText={errors?.claim_charge}
                                    onFocus={() => handleError('', 'claim_charge')}
                                />
                            </Grid>
                        </div>


                        <div style={{ display: 'flex', gap: 10 }}>
                            <Grid item xs={4}>
                                <TextField
                                    label="Service Charge"
                                    name="service_charge"
                                    value={formData?.service_charge || ""}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="outlined"
                                    style={{ borderRadius: 0 }}
                                    error={!!errors?.service_charge}
                                    helperText={errors?.service_charge}
                                    onFocus={() => handleError('', 'service_charge')}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    label="Panic Button Charge"
                                    name="panic_button_charge"
                                    value={formData?.panic_button_charge || ""}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="outlined"
                                    style={{ borderRadius: 0 }}
                                    error={!!errors?.formData?.panic_button_charge}
                                    helperText={errors?.formData?.panic_button_charge}
                                    onFocus={() => handleError('', 'panic_button_charge')}
                                />

                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Wiring Charge"
                                    name="wiring_charge"
                                    value={formData?.wiring_charge || ""}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="outlined"
                                    style={{ borderRadius: 0 }}
                                    error={!!errors?.wiring_charge}
                                    helperText={errors?.wiring_charge}
                                    onFocus={() => handleError('', 'wiring_charge')}
                                />
                            </Grid>

                        </div>

                        <div style={{ display: 'flex', gap: 10 }}>
                            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    checked={formData?.speedGoverner === true ? true : false}
                                    // value={formData?.speedGoverner}
                                    onChange={(e) => setFormData({ ...formData, speedGoverner: e.target.checked })}
                                    name="speedGoverner"
                                    color="primary"
                                />
                                <Typography>Speed Governer</Typography>
                            </Grid>
                        </div>

                        <div>
                            <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                                <div style={{ width: '50%' }}>
                                    <Button onFocus={() => handleError('', 'documant')} component="label" variant="contained" style={{ ...styles.documentButton }} >
                                        Document
                                        <input onChange={handleDocumant} hidden type="file" accept="image/*,application/pdf,application/msword" multiple />
                                    </Button>
                                </div>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {images?.filename || formData?.doc1 ? <img
                                        src={images.filename || `${serverURL}/uploads/client-images/${formData?.doc1}`}
                                        alt="image"
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: "8px",
                                            marginLeft: 8,
                                        }}
                                    /> :
                                        <FaRegFileAlt fontSize={30} color={primaryColor} src={images.filename} alt="image" variant="rounded" />}
                                </div>
                                <span style={{ marginLeft: 5 }}>{images?.name || formData?.doc1Name}</span>
                            </div>
                            {errors?.documant ? <div style={{ color: '#d32f2f', fontSize: 13, marginLeft: 10, marginTop: 5 }}>{errors.documant}</div> : ''}


                            <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                                <div style={{ width: '50%' }}>
                                    <Button onFocus={() => handleError('', 'documant2')} component="label" variant="contained" style={{ ...styles.documentButton }} >
                                        Document
                                        <input onChange={handleDocumant2} hidden type="file" accept="image/*,application/pdf,application/msword" multiple />
                                    </Button>
                                </div>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {images2?.filename || formData?.doc2 ? <img
                                        src={images2.filename || `${serverURL}/uploads/client-images/${formData?.doc2}`}
                                        alt="image"
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: "8px",
                                            marginLeft: 8,
                                        }}
                                    /> :
                                        <FaRegFileAlt fontSize={30} color={primaryColor} src={images2.filename} alt="image" variant="rounded" />}
                                </div>
                                <span style={{ marginLeft: 5 }}>{images2?.name || formData?.doc2Name}</span>
                            </div>
                            {errors?.documant2 ? <div style={{ color: '#d32f2f', fontSize: 13, marginLeft: 10, marginTop: 5 }}>{errors.documant2}</div> : ''}

                            <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                                <div style={{ width: '50%' }}>
                                    <Button onFocus={() => handleError('', 'documant3')} component="label" variant="contained" style={{ ...styles.documentButton }} >
                                        Document
                                        <input onChange={handleDocumant3} hidden type="file" accept="image/*,application/pdf,application/msword" multiple />
                                    </Button>
                                </div>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {images3?.filename || formData?.doc3 ? <img
                                        src={images3.filename || `${serverURL}/uploads/client-images/${formData?.doc3}`}
                                        alt="image"
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: "8px",
                                            marginLeft: 8,
                                        }}
                                    /> : <FaRegFileAlt fontSize={30} color={primaryColor} src={images3.filename} alt="image" variant="rounded" />}
                                </div>
                                <span style={{ marginLeft: 5 }}>{images3?.name || formData?.doc3Name}</span>

                            </div>
                            {errors?.documant3 ? <div style={{ color: '#d32f2f', fontSize: 13, marginLeft: 10, marginTop: 5 }}>{errors.documant3}</div> : ''}



                            <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                                <div style={{ width: '50%' }}>
                                    <Button onFocus={() => handleError('', 'documant4')} component="label" variant="contained" style={{ ...styles.documentButton }} >
                                        Document
                                        <input onChange={handleDocumant4} hidden type="file" accept="image/*,application/pdf,application/msword" multiple />
                                    </Button>
                                </div>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {images4?.filename || formData?.doc4 ? <img
                                        src={images4.filename || `${serverURL}/uploads/client-images/${formData?.doc4}`}
                                        alt="image"
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: "8px", // To make the image rounded
                                            marginLeft: 8,
                                        }}
                                    /> :
                                        <FaRegFileAlt fontSize={30} color={primaryColor} src={images4.filename} alt="image" variant="rounded" />}
                                </div>
                                <span style={{ marginLeft: 5 }}>{images4?.name || formData?.doc4Name}</span>

                            </div>
                            {errors?.documant4 ? <div style={{ color: '#d32f2f', fontSize: 13, marginLeft: 10, marginTop: 5 }}>{errors.documant4}</div> : ''}


                            <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                                <div style={{ width: '50%' }}>
                                    <Button onFocus={() => handleError('', 'documant5')} component="label" variant="contained" style={{ ...styles.documentButton }} >
                                        Document
                                        <input onChange={handleDocumant5} hidden type="file" accept="image/*,application/pdf,application/msword" multiple />
                                    </Button>
                                </div>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {images5?.filename || formData?.doc5 ? <img
                                        src={images5.filename || `${serverURL}/uploads/client-images/${formData?.doc5}`}
                                        alt="image"
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: "8px",
                                            marginLeft: 8,
                                        }}
                                    /> :
                                        <FaRegFileAlt fontSize={30} color={primaryColor} src={images5.filename} alt="image" variant="rounded" />}
                                </div>
                                <span style={{ marginLeft: 5 }}>{images5?.name || formData?.doc5Name}</span>

                            </div>
                            {errors?.documant5 ? <div style={{ color: '#d32f2f', fontSize: 13, marginLeft: 10, marginTop: 5 }}>{errors.documant5}</div> : ''}

                        </div>


                        <div style={{ display: 'flex', gap: 10 }}>
                            {!isUpdate && <Grid item xs={12} sm={12} style={{ display: 'flex', gap: 15 }}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Password</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        label="Password"
                                        name='password'
                                        placeholder="Enter Your Password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={formData?.password}
                                        onChange={handleInputChange}
                                        error={Boolean(errors?.password)}
                                        helperText={errors?.password}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText style={{ color: "#D32F2f" }} id="outlined-weight-helper-text">{errors?.password}</FormHelperText>
                                </FormControl>
                            </Grid>}

                            {!isUpdate && <Grid item xs={12} sm={12} style={{ display: 'flex', gap: 15 }}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Confirm  Password</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        label="Confirm Password"
                                        name='confirmPassword'
                                        variant="outlined"
                                        placeholder="Confrim Your Password"
                                        type={showConfrimPassword ? 'text' : 'password'}
                                        value={formData?.confirmPassword}
                                        error={Boolean(errors?.confirmPassword)}
                                        helperText={errors?.confirmPassword}
                                        onChange={handleInputChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfrimPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showConfrimPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText style={{ color: "#D32F2f" }}>{errors?.confirmPassword}</FormHelperText>
                                </FormControl>
                            </Grid>}
                        </div>

                        <Grid item xs={12}>
                            {formData?.sites?.map((form, index) => {
                                return (
                                    <Accordion style={{ marginTop: 10, marginBottom: 10 }}>
                                        <AccordionSummary
                                            expandIcon={<ArrowDownwardIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography>Add Sites {index + 1}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} >
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Site Name"
                                                        // error={Boolean(errors?.street)}
                                                        // helperText={errors?.street}
                                                        // onFocus={() => handleError('', 'street')}
                                                        value={formData?.sites[index]?.name || ''}
                                                        onChange={(e) => handleSitesInputChange(index, 'name', e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Autocomplete
                                                        id="state-country"
                                                        options={sitesCountryList}
                                                        value={sitesCountryList.find((s) => s?.isoCode === formData?.sites[index]?.country) || null}
                                                        onChange={(e, n) => handleSiteCountryChange(index, e, n)}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => <TextField {...params} label="Select Country" fullWidth />}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Autocomplete
                                                        id="state-select"
                                                        options={sitesStateList}
                                                        value={sitesStateList.find((s) => s?.isoCode === formData?.sites[index]?.state) || null}
                                                        onChange={(e, n) => handleSiteStateChange(index, e, n)}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => <TextField {...params} label="Select State" fullWidth />}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Autocomplete
                                                        id="city-select"
                                                        options={sitesCityList}
                                                        value={sitesCityList?.find((c) => c?.name === formData?.sites[index]?.city) || null}
                                                        onChange={(e, n) => handleSiteCityChange(index, e, n)}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => <TextField {...params} label="Select City" fullWidth />}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label="Street"
                                                        // error={Boolean(errors?.street)}
                                                        // helperText={errors?.street}
                                                        // onFocus={() => handleError('', 'street')}
                                                        value={formData?.sites[index]?.street || ''}
                                                        onChange={(e) => handleSitesInputChange(index, 'street', e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label="Zip Code"
                                                        //  
                                                        value={formData?.sites[index]?.zipCode || ''}
                                                        // error={Boolean(errors.zipCode)}
                                                        // helperText={errors?.zipCode}
                                                        // onFocus={() => handleError('', 'zipCode')}
                                                        onChange={(e) => handleSitesInputChange(index, 'zipCode', e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>)
                            })}
                            {isUpdate ? <Grid item xs={12} sm={6}>
                                <AdminChangPasswordModals isUpdate={false} formData={formData} />
                            </Grid> : <></>}

                            <Grid item xs={12} sm={6}>
                                <Button onClick={addForm} variant="contained" style={buttonStyles}>
                                    Add
                                </Button>
                            </Grid>

                        </Grid>



                        <Button
                            type="submit"
                            variant="contained"
                            disabled={loading}
                            style={{
                                backgroundColor: primaryColor,
                                color: 'white',
                                width: '25%',
                                borderRadius: 0,
                                boxShadow: 'none',
                                padding: '10px 0',
                                fontSize: '16px',
                            }}
                        >
                            {
                                isUpdate ?
                                    <>
                                        {
                                            loading ? 'Update...' : 'Update'
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            loading ? 'Submit...' : 'Submit'
                                        }
                                    </>
                            }
                        </Button>
                    </Box>

                </Grid>

                {/* <Grid item md={5}>
                    <Box component="label" sx={dropzoneStyle}>
                        <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={(e) => {
                                const image = Array.from(e.target.files);
                                if (Update) {
                                    setFormData({ ...formData, uploadNewImage: [...(formData.uploadNewImage || []), ...image] });
                                    setNewImages([...newImages, ...image]);
                                    handleCompressImage(image)
                                } else {
                                    setFormData({ ...formData, images: [...(formData.images || []), ...image] });
                                    e.target.value = ''; // Clear the input for the next upload      
                                }
                            }}

                            style={{ display: 'none' }}
                        />
                        <ImageIcon sx={{ fontSize: 48, color: primaryColor }} />
                        <Typography variant="h6" sx={{ color: primaryColor, marginTop: '16px' }}>
                            Choose your Images
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#999999' }}>
                            Accepts all Formats
                        </Typography>
                    </Box>

                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '3%' }}>
                        {formData.images?.map((image, index) => (
                            <Box key={index} sx={imagesBox} position="relative">
                                {Update ? <img
                                    src={`${serverURL}/uploads/products-images/${image}`}
                                    alt={`Uploaded preview ${index}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                /> : <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Uploaded preview ${index}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />}

                                <IconButton
                                    onClick={() => {
                                        const updatedImages = formData.images.filter((_, i) => i !== index);
                                        setFormData({ ...formData, images: updatedImages }); // Update formData with the filtered images
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        top: '4px',
                                        right: '4px',
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                        },
                                    }}
                                >
                                    <CloseIcon sx={{ fontSize: '16px', color: '#f44336' }} />
                                </IconButton>
                            </Box>
                        ))}
                    </div>
                </Grid> */}
            </Grid>
        </div>
    );
}



const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
    },
    rightSide: {
        backgroundColor: '#fff',
        width: '100%'
    },
    loginHeading: {
        fontSize: '1.5rem',
        color: primaryColor,
        marginBottom: '20px',
        fontWeight: 500,
        textAlign: 'center'
    },
    loginText: {
        textAlign: 'center',
        fontSize: '1rem',
        marginTop: 0,
        color: '#2a2a2a',
        marginBottom: '20px',
    },
    input: {
        marginBottom: '10px',
    },
    signInButton: {
        backgroundColor: primaryColor,
        color: '#fff',
        borderRadius: 0,
        width: '100%',
        marginTop: '4%',
        boxShadow: 'none',
        fontSize: '1rem',
        fontWeight: 400,
        cursor: 'pointer',
    },
    documentButton: {
        border: '1px dashed ' + primaryColor,
        color: primaryColor,
        borderRadius: 0,
        width: '100%',
        marginTop: '4%',
        background: 'white',
        boxShadow: 'none',
        fontSize: '1rem',
        fontWeight: 400,
        cursor: 'pointer',
    },
};