import React, { useState } from "react";
import { Grid, ListItemButton, Collapse, List } from "@mui/material";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { MdDashboard, MdPeople, MdSettings } from "react-icons/md";
import { MdPerson } from "react-icons/md";
import { TbCreditCardRefund, TbFileInvoice } from "react-icons/tb";
import { TbBrandAirtable } from "react-icons/tb";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { primaryColor } from "../../../constant";
import { AiOutlineProduct } from "react-icons/ai";
import ClientDashComp from "../../admin/pages/Client";
import { FaCar } from "react-icons/fa";
import ClientSettings from "../../../components/ClientSettings"
import ClientSidebar from "../../../components/ClientSidebar";
import { HiCalendar } from "react-icons/hi";
import { BiSolidUserPin } from "react-icons/bi";
import ClientsUninstall from "./ClientsUninstall";
import ClientsClaims from "./ClientslClaim";
import ClientsReturn from "./ClientsReturn";
import PlaceIcon from '@mui/icons-material/Place';
import { LiaFileInvoiceSolid } from "react-icons/lia"
import ClientRequests from "../../../components/ClientRequests";
import RequestDetails from "../../../components/RequestDetails";
import ApartmentIcon from '@mui/icons-material/Apartment';
import SubCompany from "../../../components/SubCompany";
import SubCompanyForm from "../../../components/SubCompanyForm";
import ClientsVendor from "./ClientsVendor";
import ClientSubRequests from "./ClientSubRequest";
import SubCompanyDetails from "../../../components/SubCompanyDetails";
import ClientSites from "./ClientSites";
import ClientInvoice from "./ClientInvoice";
import ServiceRequest from "./ServiceRequest";
import CompanyDashComp from "../../../components/CompanyDashComp";
import ClientVehicle from "./ClientVehicle";
import ClientVehicleDetails from "../../../components/ClientVehicleDetails";
import Billing from "./Billing";
import ShowInventory from "../../../components/ShowInventory";
import { FaRegCreditCard } from "react-icons/fa";
import { BsBoxFill } from "react-icons/bs";
import { RiBankLine } from "react-icons/ri";
import ReturnForm from "../../../components/ReturnForm";
import ClaimForm from "../../../components/ClaimForm";
import UninstallForm from "../../../components/UninstallForm";
import RequestForm from "../../../components/RequestForm";
import Models from "../../admin/pages/Models";
import Brands from "../../admin/pages/Brands";
import ClientRecords from "./ClientRecords";
import ClientSite2 from "./ClientSite2";
import { SiSpeedtest } from "react-icons/si";
import SpeedGoverner from "./SpeedGoverner";
import ClientBilling from "../CllientBilling";

export default function ClientDashboard() {
    const { user_data } = useSelector(state => state.user);
    const location = useLocation(); // Hook to get the current route
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const navigate = useNavigate();
    let current_path = window.location.pathname;

    const handleClick = (index) => {
        setOpen(!open);
        setIndex(index);
    }

    const handleClickSubMenu = (subtitle, url) => {
        const link = `/company/dashboard/${url}/${subtitle.toLowerCase().replace(/\s+/g, '')}`;
        navigate(link);
        window.scrollTo(0, 0);
    };

    const listItemButton = (subtitle, url, icon) => {
        const link = `/company/dashboard/${url}/${subtitle.toLowerCase().replace(/\s+/g, '')}`;
        return (
            <ListItemButton
                onClick={() => handleClickSubMenu(subtitle, url)}
                style={{
                    padding: '5% 6%',
                    borderRadius: 8,
                    margin: '1% 0',
                    background: window.location.pathname === link ? '#1e6d8a19' : 'transparent'
                }}
            >
                <div style={{
                    display: "flex",
                    gap: '8%',
                    width: '100%',
                    color: window.location.pathname === link ? primaryColor : '#757575',
                }}>
                    {/* {icon} */}
                    <p style={{ color: window.location.pathname === link ? primaryColor : '#757575', fontWeight: window.location.pathname === link ? 500 : 400, fontSize: 14, margin: 0, padding: 0 }}>
                        {subtitle}
                    </p>
                </div>
            </ListItemButton >
        )
    }

    const dashboard_items = [
        {
            title: 'Dashboard',
            link: '/company/dashboard',
            icon: <MdDashboard style={{ color: location.pathname === '/company/dashboard' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Billing',
            link: '/company/dashboard/billing',
            icon: <FaRegCreditCard style={{ color: location.pathname === '/company/dashboard/billing' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Invoice',
            link: '/company/dashboard/invoice',
            icon: <LiaFileInvoiceSolid style={{ color: location.pathname === '/company/dashboard/invoice' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Requests',
            link: '/company/dashboard/request',
            icon: <AiOutlineProduct style={{ color: location.pathname === '/company/dashboard/request' ? primaryColor : '#757575' }} />,
        },
        // {
        //     title: 'Sub Requests',
        //     link: '/company/dashboard/subrequest',
        //     icon: <AiOutlineProduct style={{ color: location.pathname === '/company/subrequest' ? primaryColor : '#757575' }} />,
        // },
        {
            title: 'Uninstalls',
            link: '/company/dashboard/uninstall',
            icon: <HiCalendar style={{ color: location.pathname === '/company/dashboard/uninstall' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Claims',
            link: '/company/dashboard/claims',
            icon: <BiSolidUserPin style={{ color: location.pathname === '/company/dashboard/claims' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Return',
            link: '/company/dashboard/returns',
            icon: <TbCreditCardRefund style={{ color: location.pathname === '/company/dashboard/returns' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Vendors',
            link: '/company/dashboard/vendors',
            icon: <AiOutlineProduct style={{ color: location.pathname === '/company/dashboard/vendors' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Record',
            link: '/company/dashboard/records',
            icon: <ApartmentIcon style={{ color: location.pathname === '/company/dashboard/records' ? primaryColor : '#757575' }} />,
        },
        // {
        //     title: 'My Sites',
        //     link: '/company/dashboard/clientsites',
        //     icon: <PlaceIcon style={{ color: location.pathname === '/company/dashboard/clientsites' ? primaryColor : '#757575' }} />,
        // },
        {
            title: 'Inventory',
            link: '/company/dashboard/inventory',
            icon: <BsBoxFill style={{ color: location.pathname === '/company/dashboard/inventory' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Sites',
            link: '/company/dashboard/sites',
            icon: <PlaceIcon style={{ color: location.pathname === '/company/dashboard/clientsites' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Service Request',
            link: '/company/dashboard/service/request',
            icon: <HiCalendar style={{ color: location.pathname === '/company/dashboard/service/request' ? primaryColor : '#757575' }} />,
        },

        {
            title: 'Speed Governer',
            link: '/company/dashboard/speedgoverner',
            icon: <SiSpeedtest style={{ color: location.pathname === '/company/dashboard/speedgoverner' ? primaryColor : '#757575' }} />,
        },
        // {
        //     title: 'Vehicle',
        //     link: '/company/dashboard/vehicle',
        //     icon: <FaCar style={{ color: location.pathname === '/company/dashboard/vehicle' ? primaryColor : '#757575' }} />
        // },
       
        
        {
            title: 'Vendor Gps Request',
            link: '/company/dashboard/vehicle',
            icon: <BsBoxFill style={{ color: location.pathname === '/company/dashboard/vehicle' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Vehicle Brands',
            link: 1,
            icon: <TbBrandAirtable style={{ color: window.location.pathname === '/company/dashboard/vehicle-brand' ? primaryColor : '#757575' }} />,
            subMenu: () => (
                open && index === 1 ? (
                    <KeyboardArrowDownIcon
                        onClick={() => handleClick(1)}
                        style={{ color: 'black' }}
                    />
                ) : (
                    <KeyboardArrowRightIcon
                        onClick={() => handleClick(1)}
                        style={{ color: 'black' }}
                    />
                )
            ),
            collapse: () => <Collapse in={open && index === 1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                    {listItemButton("Brands", 'vehicle-brand', <MdPeople style={{ color: window.location.pathname === '/company/dashboard/vehicle-brand/brands' ? primaryColor : '#757575' }} />)}
                    {listItemButton("Models", 'vehicle-brand', <MdPerson style={{ color: window.location.pathname === '/company/dashboard/vehicle-brand/models' ? primaryColor : '#757575' }} />)}
                </List>
            </Collapse >
        },
        {
            title: 'Oredo GPS Bank',
            link: '/company/dashboard/bank',
            icon: <RiBankLine style={{ color: location.pathname === '/company/dashboard/bank' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Settings',
            link: '/company/dashboard/settings',
            icon: <HiMiniWrenchScrewdriver style={{ color: location.pathname === '/company/dashboard/settings' ? primaryColor : '#757575' }} />,
        },



    ];

    const filtered_dashboard_items = user_data?.isSubClient
        ? dashboard_items?.filter(item => item?.title !== 'Sub Company')
        : dashboard_items;

    return (
        <div className="layout-container">
            <div className="sidebar-container">
                <ClientSidebar dashboard_items={filtered_dashboard_items} setIndex={setIndex} setOpen={setOpen} open={open} />
            </div>
            <div className="content-container" style={{ paddingTop: '1%' }}>
                <Routes>
                    <Route element={<CompanyDashComp />} path="/" />
                    <Route element={<ClientRequests />} path="/request" />
                    <Route element={<RequestDetails />} path="clientrequestdetails" />
                    {/* <Route element={<ClientRequests />} path="/" /> */}
                    <Route element={<ClientSubRequests />} path="/subrequest" />
                    {/* <Route element={<RequestDetails />} path="clientrequestdetails" /> */}
                    <Route element={<ClientSettings />} path="/Settings" />
                    <Route element={<SubCompany />} path="/subcompany" />
                    <Route element={<SubCompanyForm />} path="/create-sub-company" />
                    <Route element={<ClientsClaims />} path="/claims" />
                    <Route element={<ClientsUninstall />} path="/uninstall" />
                    <Route element={<ClientsReturn />} path="/returns" />
                    <Route element={<ClientsVendor />} path="/vendors" />
                    <Route element={<SubCompanyDetails />} path="/subcompanydetails" />
                    {/* <Route element={<ClientSites />} path="/clientsites" /> */}
                    <Route element={<ClientInvoice />} path="/invoice" />
                    <Route element={<ServiceRequest />} path="/service/request" />
                    <Route element={<ClientVehicle />} path="/vehicle" />
                    <Route element={<ClientVehicleDetails />} path="/vehicles/detail" />
                    <Route element={<Billing />} path="/bank" />
                    <Route element={<ShowInventory />} path="/inventory" />
                    <Route element={<ReturnForm />} path="returnform" />
                    <Route element={<ClaimForm />} path="claimform" />
                    <Route element={<UninstallForm />} path="uninstallform" />
                    <Route element={<RequestForm />} path="requestform" />
                    <Route element={<Models />} path="/vehicle-brand/models" />
                    <Route element={<Brands />} path="/vehicle-brand/brands" />
                    <Route element={<ClientRecords />} path="/records" />
                    <Route element={<ClientSite2 />} path="/sites" />
                    <Route element={<SpeedGoverner />} path="/speedgoverner" />
                    <Route element={<ClientBilling />} path="/billing" />
                </Routes>
            </div>
        </div>
    );
}
