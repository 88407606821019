import { Grid, useMediaQuery, InputLabel, MenuItem, FormControl, Select, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { primaryColor } from '../constant';
import { CiSearch } from "react-icons/ci";
import { get_data, post_data } from '../api';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const HeroSection2 = () => {
    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '3% 6%',
            backgroundSize: 'cover',
            backgroundColor: '#EFF5F7',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            position: 'relative',
            color: '#fff',
            textAlign: 'center',
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
        },
        content: {
            zIndex: 2,
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
        },
        title: { fontSize: 18, fontWeight: 400 },
        heading: {
            fontSize: isDesktop ? 50 : 32,
            fontWeight: 600,
            marginBottom: 20,
            lineHeight: 1.1,
        },
        createButton: {
            borderRadius: 5,
            // background: '#000',
            color: primaryColor,
            padding: '15px 20px',
            fontWeight: 600,
            textTransform: 'none',
            boxShadow: 'none',
        },
    };

    const headings = ["GPS Installation", "Easy Claim & Return", "Powerful Connections"];
    const [currentHeading, setCurrentHeading] = useState(0);
    const [displayText, setDisplayText] = useState("");
    const [charIndex, setCharIndex] = useState(0);
    const [formState, setFormState] = useState({ feature: [], network: '' });
    const [features, setFeatures] = useState([]);

    const fetchFeatures = async () => {
        let result = await get_data(`feature/get-all-features-for-options`);

        if (result?.status == true) {
            const feature = result?.data?.map((item) => item?.name);
            setFeatures(feature);
        }
    }

    useEffect(function () {
        fetchFeatures();
    }, [])

    useEffect(() => {
        if (charIndex < headings[currentHeading].length) {
            const interval = setInterval(() => {
                setDisplayText(prev => prev + headings[currentHeading][charIndex]);
                setCharIndex(prev => prev + 1);
            }, 100);
            return () => clearInterval(interval);
        } else {
            setTimeout(() => {
                setCurrentHeading((prev) => (prev + 1) % headings.length);
                setDisplayText("");
                setCharIndex(0);
            }, 2000);
        }
    }, [charIndex, currentHeading, headings]);



    const handleSearch = async () => {
        try {
            const result = await post_data(`product/search-product-for-home`, formState);

            if (result?.status === true) {
                navigate(`/gps/${formState?.feature?.join('-')}/${formState?.network}`, { state: { product: result?.data, formState } });
            } else {
                toast.error(result?.response?.data?.message || "Something went wrong");
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.overlay} />
            <div style={styles.content}>
                <h2 style={styles.heading}>
                    <span style={{ color: "#1e6e8a" }}>{displayText}</span> <br />
                    Best GPS Solution for Your Vehicles
                </h2>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '25px', }}>
                    <Grid container spacing={2} width={'80%'} style={{ marginTop: '25px', display: 'flex', justifyContent: 'center', }}>
                        {/* <Grid item xs={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel>Amount</InputLabel>
                                <Select
                                    style={{ backgroundColor: 'white' }}
                                    name="amount"
                                    value={formState.amount}
                                    onChange={handleSelectChange}
                                    label="Amount"
                                >
                                    <MenuItem value="10">₹1,000 - ₹3,000</MenuItem>
                                    <MenuItem value="20">₹4,000 - ₹7,000</MenuItem>
                                    <MenuItem value="30">₹8,000 - ₹10,000</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel>Feature</InputLabel>
                                <Select
                                    style={{ backgroundColor: 'white' }}
                                    name="feature"
                                    multiple
                                    value={formState?.feature || []}
                                    onChange={(event) =>
                                        setFormState({ ...formState, feature: event.target.value })
                                    }
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {features?.map((feature) => (
                                        <MenuItem key={feature} value={feature}>
                                            {feature}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item xs={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel>Network</InputLabel>
                                <Select
                                    style={{ backgroundColor: 'white' }}
                                    name="network"
                                    value={formState.network}
                                    onChange={(event) => setFormState({ ...formState, network: event.target.value })}
                                    label="Network"
                                >
                                    <MenuItem value="4G">4G</MenuItem>
                                    <MenuItem value="2G">2G</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Button
                                fullWidth
                                startIcon={<CiSearch />}
                                variant="outlined"
                                style={styles.createButton}
                                onClick={handleSearch}
                            >
                                Search New
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default HeroSection2;
