import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import { useState, useEffect } from "react";
import { primaryColor } from '../constant';
import { Toaster, toast } from 'react-hot-toast';
import { get_data, post_data } from '../api';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

export default function CreateSales({ setOpen, open, editData, isUpdate, getAllInvoices }) {
    const [paymentAmount, setPaymentAmount] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [transactionNumber, setTransactionNumber] = useState('');
    const [mode, setMode] = useState('');
    const [errors, setErrors] = useState({});
    const [companyList, setCompanyList] = useState([]);
    const [companyId, setCompanyId] = useState(null);

    useEffect(() => {
        if (isUpdate && editData) {
        } else {
        }
    }, [editData, isUpdate]);

    const fetchAllCompany = async () => {
        const result = await get_data(`client/get-all-clients-for-options`);
        setCompanyList(result?.data || []);
    };

    useEffect(() => {
        fetchAllCompany();
    }, []);

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = {};
        let hasError = false;
        if (!companyId) {
            error = true;
            handleError('Please Select Comapany...', 'companyName');
        }
        if (!paymentAmount) {
            error = true;
            handleError('Please Enter Payment Amount...', 'paymentAmount');
        }

        if (!paymentDate) {
            error = true;
            handleError('Please Enter Payment Date...', 'paymentDate');
        }

        if (!transactionNumber) {
            error = true;
            handleError('Please Enter Transaction Number...', 'transactionNumber');
        }

        if (!mode) {
            error = true;
            handleError('Please Enter Mode...', 'mode');
        }

        return hasError;
    };

    const handleClose = () => {
        setOpen(false);
        setCompanyId(null);
        setPaymentAmount('');
        setPaymentDate('');
        setTransactionNumber('');
        setMode('');
    };

    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            try {
                let data;
                if (isUpdate) {
                    // data = await post_data(`brand/update-brand/${editData?._id}`, { name });
                    setOpen(false);
                } else {
                    data = await post_data(`invoice/create-invoice`, { clientId: companyId, mode, transactionNumber, paymentAmount, paymentDate, item: "Rental Charges" });
                    getAllInvoices();
                    handleClose();
                }

                if (data?.status === true) {
                    toast.success(data?.message || 'Invoice Created successful!');
                } else {
                    toast.error('Something went wrong!');
                }
            } catch (error) {
                toast.error('An error occurred. Please try again.');
            }
        }
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isUpdate ? 'Edit Brand' : 'Create Record'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    fullWidth
                                    options={companyList}
                                    value={companyList?.find((s) => s?._id === companyId) || null}
                                    onChange={(event, newValue) => setCompanyId(newValue?._id)}
                                    autoHighlight
                                    getOptionLabel={(option) => option.companyName || ''}
                                    renderInput={(params) => <TextField {...params} label="Select Company" fullWidth />}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    type='text'
                                    label='Payment Amount'
                                    error={!!errors.paymentAmount}
                                    helperText={errors.paymentAmount}
                                    onFocus={() => handleError('', "paymentAmount")}
                                    value={paymentAmount}
                                    onChange={(e) => setPaymentAmount(e.target.value)}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    type='date'
                                    label='Payment Date'
                                    error={!!errors.paymentDate}
                                    helperText={errors.paymentDate}
                                    onFocus={() => handleError('', "paymentDate")}
                                    value={paymentDate}
                                    onChange={(e) => setPaymentDate(e.target.value)}
                                    fullWidth
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <TextField
                                    type='text'
                                    label='transaction Number'
                                    error={!!errors.transactionNumber}
                                    helperText={errors.transactionNumber}
                                    onFocus={() => handleError('', "transactionNumber")}
                                    value={transactionNumber}
                                    onChange={(e) => setTransactionNumber(e.target.value)}
                                    fullWidth
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <TextField
                                    type='text'
                                    label='Payment Mode'
                                    error={!!errors.paymentMode}
                                    helperText={errors.paymentMode}
                                    onFocus={() => handleError('', "paymentMode")}
                                    value={mode}
                                    onChange={(e) => setMode(e.target.value)}
                                    fullWidth
                                />
                            </Grid>


                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0
                                    }}
                                    onClick={handleSubmit}
                                    fullWidth
                                >
                                    {isUpdate ? 'Edit' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
            {/* <Toaster /> */}
        </div>
    );
}
