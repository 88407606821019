import { Box, Button, Chip, Modal, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { primaryColor } from "../../constant";
import CloseIcon from '@mui/icons-material/Close';

const AddFeature = ({ open, setOpen, featuresList, setFeaturesList, isUpdate }) => {
    const [keyValuePairs, setKeyValuePairs] = useState({});
    const [keyInput, setKeyInput] = useState("");
    const [valueInput, setValueInput] = useState("");

    useEffect(() => {
        setKeyValuePairs(featuresList)
    }, [featuresList])

    const handleAddPair = () => {
        if (keyInput && valueInput) {
            setKeyValuePairs((prevPairs) => ({
                ...prevPairs,
                [keyInput]: valueInput,
            }));
            setKeyInput("");
            setValueInput("");
        }
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        borderRadius: "7px",
        border: "0px solid #fff",
        p: 4,
    };

    const handleDelete = (key) => {
        setKeyValuePairs((prevPairs) => {
            const updatedPairs = { ...prevPairs };
            delete updatedPairs[key];
            return updatedPairs;
        });
    };

    const handleClose = () => {
        setOpen(false);
        setFeaturesList(keyValuePairs);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ maxWidth: 400, mx: "auto" }}>
                    <CloseIcon onClick={handleClose} style={{ position: 'absolute', right: '3%', top: '4%', cursor: 'pointer', opacity: '70%' }} />
                    <TextField
                        label="Key"
                        variant="outlined"
                        value={keyInput}
                        onChange={(e) => setKeyInput(e.target.value)}
                        sx={{ mt: 2, mb: 2, width: "100%" }}
                    />
                    <TextField
                        label="Value"
                        variant="outlined"
                        value={valueInput}
                        onChange={(e) => setValueInput(e.target.value)}
                        sx={{ mb: 2, width: "100%" }}
                    />
                    <Button fullWidth variant="contained" style={{ background: primaryColor }} onClick={handleAddPair} sx={{ mb: 2 }}>
                        Add Feature
                    </Button>
                    <Box style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                        {Object.entries(keyValuePairs).map(([key, value]) => (
                            <Chip
                                key={key}
                                label={`${key}: ${value}`}
                                onDelete={() => handleDelete(key)}
                                color="primary"
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddFeature;