import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { State, City } from 'country-state-city';
import { IoMdAdd } from "react-icons/io";
import { createButtonBg, formatDate, primaryColor } from '../../constant';
import { Autocomplete, Button, FormControl, FormHelperText, Grid, MenuItem, Select, setRef, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { post_data } from '../../api';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '7px',
    border: '0px solid #fff',
    p: 4,
};

export default function UpdateBilling({ open, setOpen, updateData, getAllBilling, billingData }) {
    // console.log('updateData', updateData)
    const { user_data } = useSelector(state => state.user);
    const dispatch = useDispatch()
    const [referenceNumber, setReferenceNumber] = useState('');
    const [date, setDate] = useState('');
    const [invoiceStatus, setInvoiceStatus] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (billingData) {
            setReferenceNumber(billingData?.referenceNumber || '');
            setDate(formatDate(billingData?.date) || '');
            setInvoiceStatus(billingData?.invoiceStatus || '');
        }
    }, [billingData]);

    const formatDate = (isoDate) => {
        if (!isoDate) return '';

        const dateObj = new Date(isoDate);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        if (updateData) {
            setReferenceNumber(updateData?.referenceNumber || '');
            setDate(formatDate(updateData?.date) || '');
            setInvoiceStatus(updateData?.invoiceStatus || '');
        }
    }, [updateData]);


    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!referenceNumber) {
            error = true;
            handleError('Please input Reference Number...', 'referenceNumber');
        }
        if (!date) {
            error = true;
            handleError('Please input Date...', 'date');
        }
        if (!invoiceStatus) {
            error = true;
            handleError('Please input Invoice Status...', 'invoiceStatus');
        }
        return error;
    };

    const handleClose = () => {
        setErrors({}); 
        setReferenceNumber('');
        setDate('');
        setInvoiceStatus('');
        setOpen(false);
    };

    const handleSubmit = async () => {
        const error = validation();
        if (!error) {
            const payload = {
                referenceNumber,
                date,
                invoiceStatus
            }

            const result = await post_data(`billing/update-billing/${updateData?._id}`, payload);
            if (result?.status === true) {
                toast?.success(result?.message);
                getAllBilling()
            } else {
                toast?.error(result?.message);
            }
            handleClose();
        }
    };

    return (
        <div>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Update Billing
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                        <Grid item xs={12}>
                            <TextField
                                error={Boolean(errors.referenceNumber)}
                                helperText={errors.referenceNumber}
                                onFocus={() => handleError('', 'referenceNumber')}
                                value={referenceNumber}
                                label="Reference Number"
                                onChange={(e) => setReferenceNumber(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={Boolean(errors.date)}
                                helperText={errors.date}
                                onFocus={() => handleError('', 'date')}
                                value={date}
                                type='date'
                                label="Payment Date"
                                onChange={(e) => setDate(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth error={Boolean(errors.invoiceStatus)}>
                                <Select
                                    onFocus={() => handleError('', 'invoiceStatus')}
                                    value={invoiceStatus}
                                    onChange={(e) => setInvoiceStatus(e.target.value)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value="" disabled>
                                        <em>Invoice Status</em>
                                    </MenuItem>
                                    <MenuItem value="Paid">Paid</MenuItem>
                                    <MenuItem value="Unpaid">Unpaid</MenuItem>
                                </Select>
                                {errors.invoiceStatus && <FormHelperText>{errors.invoiceStatus}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                            <Button variant="outlined" style={{ textTransform: 'capitalize', color: primaryColor, border: `1px solid ${primaryColor}`, borderRadius: 0 }} fullWidth onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button style={{ textTransform: 'capitalize', color: '#fff', background: primaryColor, borderRadius: 0 }} onClick={handleSubmit} fullWidth>
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
