import { TextField, Grid, Switch, Checkbox, FormControlLabel, Button, FormGroup, Typography, Autocomplete, FormHelperText, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { formatDate, formatPrice, primaryColor, primaryColorHover } from '../constant';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Country, State, City } from 'country-state-city';
import VendorsTableName from './VanderTableName';
import { baseURL, get_data, post_data, serverURL } from '../api';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Swal from 'sweetalert2';
import AddSubVendorModal from './AddSubVendorModel';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import * as XLSX from 'xlsx';
import ClientSitesForm from './models/ClientSitesForm';
import AddSiteModal from './models/AddSiteModal';
import SiteTableName from './tables/SiteTableName';
import { useNavigate } from 'react-router-dom';

const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
});

export default function RequestForm() {
    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const [driverCityList, setDriverCityList] = useState([]);
    const [siteCityList, setSiteCityList] = useState([]);
    const [deviceModelList, setDeviceModelList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [data, setData] = useState([]);
    const [siteData, setSiteData] = useState([]);
    const [vandorTableOpen, setVandorTableOpen] = useState({});
    const [siteTableOpen, setSiteTableOpen] = useState({});
    const [stateList, setStateList] = useState([]);
    const [brandList, setBrandList] = useState([[]]);
    const [modelList, setModelList] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [fileName, setFileName] = useState('');
    const [deviceUniqueIdList, setDeviceUniqueIdList] = useState([]);
    const [openSite, setOpenSite] = useState(false);
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        clientId: user_data?._id,
        customerDetails: {
            companyName: user_data?.companyName || '',
            vendorName: user_data?.vanderId?.name || '',
            contactNo: user_data?.contactNo || '',
            resContactNo: user_data?.resContactNo || '',
            address: {
                street: user_data?.address?.street || '',
                landmark: user_data?.address?.landmark || '',
                city: user_data?.address?.city || '',
                state: user_data?.address?.state || '',
                zipCode: user_data?.address?.zipCode || ''
            },
        },
        gps: [{
            deviceDetails: {
                network: { airtel: false, vi: false, bsnl: false, jio: false, none: false },
            },
            driverDetails: {
                resContactNo: ''
            },
            vehicleDetails: {
                color: ''
            },
            siteDetails: {
                street: '', landmark: '', state: '', city: '', zipCode: ''
            },
            vendor: '',
            vendorDetails: { name: '', address: { street: '', landmark: '', city: '', state: '', zipCode: '' }, contactNo: '', email: '' },
        }],
    });
    const [errors, setErrors] = useState({});
    const [countryCode] = useState('IN');
    const [vendorList, setVendorList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [index, setIndex] = useState(0);
    const [defaultnetwork, setDefaultnetwork] = useState('');
    const [siteList, setSiteList] = useState([]);
    const [selectedUniqueId, setSelectedUniqueId] = useState([]);

    useEffect(function () {
        fetchAllDeviceUniqueIds();
    }, [])

    const handleVehicleInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    // Update the vehicleDetails field dynamically
                    return {
                        ...form,
                        vehicleDetails: {
                            ...form.vehicleDetails,
                            [field]: value, // Dynamically update the vehicle field
                        },
                    };
                }
                return form; // Return unchanged form for other indices
            });

            return {
                ...prevState,
                gps: updatedGps, // Update the gps array in formData
            };
        });

        if (field == 'brand') {
            getAllModels(index, value)
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [`${field}_${index}`]: '',
        }))
    };

    const handleDriverInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    if (['street', 'landmark', 'city', 'state', 'zipCode'].includes(field)) {
                        // Update address inside driverDetails
                        return {
                            ...form, driverDetails: { ...form.driverDetails, address: { ...form.driverDetails.address, [field]: value, } },
                        };
                    } else {

                        return {
                            ...form, driverDetails: { ...form.driverDetails, [field]: value }
                        };
                    }
                }
                return form; // Return unchanged form for other indices
            });
            return { ...prevState, gps: updatedGps, };
        });

        setErrors(prevErrors => ({
            ...prevErrors,
            [`${field}_${index}`]: '',
        }))
    };

    const handleDeviceInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    const newDeviceInfo = { ...form.deviceDetails, [field]: value };
                    return { ...form, deviceDetails: newDeviceInfo };
                }
                return form;
            });

            return { ...prevState, gps: updatedGps };
        });
    };

    const handleVendorDeviceChange = (index, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    return { ...form, vendor: value };
                }
                return form;
            })

            return { ...prevState, gps: updatedGps };
        });
    }

    const addForm = () => {
        if (validate()) return
        setIndex(index + 1);
        setFormData(prevState => ({
            ...prevState,
            gps: [
                ...prevState.gps,
                {
                    deviceDetails: {
                        network: { airtel: defaultnetwork == "airtel" ? true : false, vi: defaultnetwork == "vi" ? true : false, bsnl: defaultnetwork == "bsnl" ? true : false, jio: defaultnetwork == "jio" ? true : false },
                        otherDetails: { new: false, rental: false, renew: false, repair: false }
                    },
                    driverDetails: {},
                    vehicleDetails: { color: [], chassisNumber: false, },
                    siteDetails: {
                        street: '', landmark: '', state: '', city: '', zipCode: ''
                    },
                    vendor: '',
                    vendorDetails: { name: '', address: { street: '', landmark: '', city: '', state: '', zipCode: '' }, contactNo: '', email: '' },
                }
            ]
        }));
    };

    console.log('form new', formData)

    const removeGpsForm = (index) => {
        setIndex(index - 1);
        setFormData(prevState => ({
            ...prevState,
            gps: prevState.gps.filter((_, i) => i !== index)
        }));
    };

    // Fetch device model list
    const fetchdeviceModelList = async () => {
        try {
            const data = await get_data(`product/get-all-products-for-options`);
            setDeviceModelList(data?.data || []);
        } catch (error) {
            // console.log('Error fetching device model list:', error);
        }
    };

    const fetchVendorsList = async () => {
        const result = await get_data(`vendor/get-all-vendors-for-options`);

        if (result?.status) {
            setVendorList(result?.data || []);
        }
    }

    const fetchBrands = async () => {
        const result = await get_data(`brand/get-all-brands-for-options`);
        if (result?.status) {
            setBrandList(result?.data || []);

        }
    }

    const getAllModels = async (index, value) => {
        let result = await get_data(`model/get-all-model-by-brand/${value}`)
        if (result?.status == true) {

            setModelList((prev) => {
                let updatedModelList = formData?.gps?.map((item, idx) => {
                    if (index == idx) {
                        return result?.data
                    }
                    return modelList?.[idx]
                })
                return updatedModelList
            })
        } else {
            toast.error(result?.message)
        }
    }

    const fetchSiteList = async () => {
        const result = await get_data(`sites/get-all-sites-by-company/${user_data?._id}`);
        if (result?.status) {
            setSiteList(result?.data?.sites || []);
        }
    }

    useEffect(() => {
        fetchdeviceModelList();
        fetchVendorsList();
        fetchSiteList();
        fetchBrands();
    }, []);

    const handleError = (message, field) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [`${field}_${index}`]: message,
        }));
    }

    const validate = () => {
        let hasError = false;

        formData.gps.forEach((form) => {
            if (!form.driverDetails?.fullName) {
                hasError = true;
                handleError('Please enter Full Name', 'fullName');
            }
            if (!form.driverDetails?.contactNo) {
                hasError = true;
                handleError('Please enter Contact No', 'contactNo');
            }
            if (!form.vehicleDetails?.regnNumber) {
                hasError = true;
                handleError('Please enter Registration Number', 'regnNumber', index);
            }
        })

        return hasError;
    };

    console.log('request error: ', errors)

    const handleDeviceSwitchChange = (index, provider) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    // Set the network as the selected provider's name as a string
                    return { ...form, deviceDetails: { ...form.deviceDetails, network: { [provider.toLowerCase()]: true } } };
                }
                return form;
            });

            return { ...prevState, gps: updatedGps };
        });
    };
    const handleDeviceSwitchChange2 = (index, provider) => {
        setFormData(prevState => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    let updatedOtherDetails = form.deviceDetails.otherDetails === provider
                        ? ''  // Deselect if the same provider is clicked again
                        : provider; // Set the selected provider

                    updatedOtherDetails = updatedOtherDetails.toLowerCase();

                    return { ...form, deviceDetails: { ...form.deviceDetails, otherDetails: { [updatedOtherDetails]: true } } };
                }
                return form;
            });

            return { ...prevState, gps: updatedGps, };
        });
    };

    // Function to handle switch changes for vehicle color
    const handleDeviceSwitchChange3 = (index, provider) => {
        setFormData(prevState => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    const currentColor = form.vehicleDetails.color || []; // Ensure color is initialized
                    const updatedColor = currentColor.includes(provider)
                        ? currentColor.filter(item => item !== provider) // Remove if already selected
                        : [...currentColor, provider]; // Add if not selected

                    return {
                        ...form, vehicleDetails: { ...form.vehicleDetails, color: updatedColor, }
                    };
                } return form;
            });

            return { ...prevState, gps: updatedGps, };
        });
    };


    const handleChange = (index, field, value) => {
        setFormData(prevState => {
            const updatedGps = [...prevState.gps];
            updatedGps[index].vehicleDetails[field] = value; // Update the chassisNumber or other fields
            return {
                ...prevState,
                gps: updatedGps,
            };
        });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validate()) {
            setLoader(true)

            if (user_data?.isSubClient) {
                formData.subClientId = user_data?._id
                formData.raisedBy = "subClient"
            } else {
                formData.approved = true
            }

            const respons = await post_data("request/create-request", formData)
            if (respons.status === true) {
                toast.success("Request Created Successfully")
                navigate('/company/dashboard/request')
            }
            else {
                toast.error(respons?.response?.data?.message || "Something Went Wrong")
            }
            setLoader(false)
        }
    }

    const handleSubmitExcel = async (event) => {
        event.preventDefault();
        // if (!validate()) {
        setLoader(true)
        const respons = await post_data("request/create-excel-request", excelData)

        if (respons.status === true) {
            Toast.fire({
                title: "Request Successfully",
                icon: "success",
                color: "green"
            })
            navigate('/company/dashboard/request')
        }
        else {
            Toast.fire({
                title: respons?.response?.data?.message || "Something Went Wrong",
                icon: "error",
                color: "red"
            })
        }

        setLoader(false)
    }

    const handleSiteSubmit = (newSite, isUpdate) => {
        setSiteData(prevData => [...prevData, newSite]);
    }
    const handleVandorSubmit = (newVendor, isUpdate) => {
        setData(prevData => [...prevData, newVendor]);
    }

    const fetchAllDeviceUniqueIds = async () => {
        let result = await get_data(`inventory/get-all-stock-gps`)
        if (result?.status === true) {
            setDeviceUniqueIdList(result?.data)
        }
    }


    useEffect(() => {
        setStateList(State.getStatesOfCountry(countryCode));
    }, [countryCode]);


    const handleStateChange = (event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const fetchedCities = City.getCitiesOfState(countryCode, newValue.isoCode);

            setFormData(prevState => ({
                ...prevState,
                customerDetails: {
                    ...prevState.customerDetails,
                    address: {
                        ...prevState.customerDetails.address,
                        state: stateCode, // Update state
                        city: '' // Reset city when state changes
                    }
                }
            }));

            setCityList(fetchedCities); // Populate city list based on selected state
        }
    };

    const handleDriverStateChange = (index, event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const countryCode = 'IN'; // Replace with actual country code

            // Fetch cities based on selected state
            const fetchedCities = City.getCitiesOfState(countryCode, stateCode);
            setDriverCityList(fetchedCities); // Update city list based on state

            setFormData(prevState => {
                const updatedGps = prevState.gps.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            driverDetails: {
                                ...form.driverDetails,
                                address: {
                                    ...form.driverDetails.address,
                                    state: stateCode,
                                }
                            },
                        };
                    }
                    return form; // Return unchanged form for other indices
                });

                return {
                    ...prevState,
                    gps: updatedGps,
                };
            });

            setErrors(prevErrors => ({
                ...prevErrors,
                [`driverState_${index}`]: '',
            }))
        }
    };

    // Handle city change
    const handleDriverCityChange = (index, event, newValue) => {
        if (newValue) {
            setFormData(prevState => {
                const updatedGps = prevState.gps.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            driverDetails: {
                                ...form.driverDetails,
                                address: {
                                    ...form.driverDetails.address,
                                    city: newValue.name,
                                }
                            },
                        };
                    }
                    return form;
                });

                return {
                    ...prevState,
                    gps: updatedGps,
                };
            });

            setErrors(prevErrors => ({
                ...prevErrors,
                [`driverCity_${index}`]: '',
            }))
        }
    };

    const handleSiteChange = (index, newValue) => {
        siteList.map((site) => {
            if (site?._id === newValue) {
                setFormData((prevState) => {
                    const updatedGps = prevState.gps.map((gpsItem, idx) => {
                        if (index === idx) {
                            return {
                                ...gpsItem,
                                site: newValue,
                                siteDetails: {
                                    ...gpsItem.siteDetails,
                                    site: newValue
                                }
                            };
                        }
                        return gpsItem;
                    });
                    return { ...prevState, gps: updatedGps };
                });
            }
        })

        if (newValue) {
            setFormData((prevState) => {
                const updatedGps = prevState.gps.map((gpsItem, idx) => {
                    if (index === idx) {
                        return {
                            ...gpsItem,
                            siteDetails: {
                                ...gpsItem.siteDetails,
                                site: newValue
                            }
                        };
                    }
                    return gpsItem;
                });
                return { ...prevState, gps: updatedGps };
            });
        }
    }


    const buttonStyles = {
        borderColor: primaryColor,
        color: 'white',
        background: primaryColor,
        textTransform: 'capitalize',
        borderRadius: 0,
        fontWeight: 400,
        padding: '1% 3.5%',
        boxShadow: 'none',
        '&:hover': {
            borderColor: primaryColor,
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            borderColor: primaryColor,
        }
    };

    const styles = {
        summarySection: {
            flex: 1,
            padding: '20px',
            height: 300,
            border: '1px solid #E0E0E0',
            borderRadius: '10px',
            zIndex: 2,
            marginLeft: '20px',
            marginTop: '0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
        },
        placeOrderButton: {
            padding: '13px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: primaryColor,
            border: 'none',
            borderRadius: 0,
            cursor: 'pointer',
            width: '100%',
            marginTop: '20px',
            boxShadow: 'none',
        },
        priceRow: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1% 0',
        },
    };


    const downloadCSV = () => {
        const userAgent = window.navigator.userAgent;
        let fileType = '';
        let fileName = '';

        if (userAgent.includes('Mac')) {
            fileType = 'numbers';
            fileName = 'request_record_file.numbers';
        } else if (userAgent.includes('Windows')) {
            fileType = 'xlsx';
            fileName = 'request_record_file.xlsx';
        } else {
            fileType = 'xlsx';
            fileName = 'request_record_file.xlsx';
        }

        const link = document.createElement('a');
        link.href = `${baseURL}/images/${fileName}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0]; // Get the file
        setFileName(file.name);
        const reader = new FileReader();

        // When file is loaded
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            setExcelData(jsonData);
        };

        reader.readAsArrayBuffer(file);
    };

    return (
        <Grid container spacing={2} style={{ padding: '3% 7%' }}>
            <Grid item md={9}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {formData?.gps?.map((form, index) => {
                                return (
                                    <Accordion style={{ marginTop: 10, marginBottom: 10 }} key={index} defaultExpanded={index === 0} >
                                        <AccordionSummary
                                            expandIcon={<ArrowDownwardIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography>Add GPS {index + 1}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} style={{ boxShadow: '1px solid gray' }}>

                                                <Grid item xs={12} sm={12}>
                                                    <Grid item xs={12}><Typography variant="h6">Vendor Details</Typography></Grid>
                                                    <Box sx={{ minWidth: 120 }}>
                                                        <Autocomplete
                                                            fullWidth
                                                            options={vendorList}
                                                            getOptionLabel={(option) => option?.name || ''}
                                                            isOptionEqualToValue={(option, value) => option?._id === value}
                                                            value={formData?.gps[index]?.vendorName}
                                                            onChange={(event, newValue) => handleVendorDeviceChange(index, newValue?._id || '')}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Vendor Name"
                                                                    error={!!errors.vendorName}
                                                                    helperText={errors.vendorName}
                                                                    onFocus={() => handleError('', 'vendorName')}
                                                                />
                                                            )}
                                                        />

                                                        {!!errors.vendorName ? <span style={{ color: '#EA2024', fontSize: '12px' }}>{errors.vendorName}</span> : <></>}
                                                    </Box>
                                                    {!vandorTableOpen[index] ? <AddSubVendorModal index={index} screen={"requestForm"} handleVandorSubmit={handleVandorSubmit} isUpdate={false} vandorTableOpen={vandorTableOpen} setVandorTableOpen={setVandorTableOpen} formData={formData} setFormData={setFormData} /> : <></>}

                                                </Grid>
                                                {vandorTableOpen[index] ?
                                                    <Grid item xs={12} sm={12} >
                                                        <div style={{ overflowY: 'scroll', scrollbarWidth: 'none', height: '25vh' }}>
                                                            <VendorsTableName data={data} setData={setData} index={index} setFormData={setFormData} formData={formData} handleVandorSubmit={handleVandorSubmit} isUpdate={false} vandorTableOpen={vandorTableOpen} setVandorTableOpen={setVandorTableOpen} />
                                                        </div>

                                                    </Grid> : <></>}

                                                {/* Device Details */}
                                                <Grid item xs={12}><Typography variant="h6">Device Details</Typography></Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Autocomplete
                                                        id="device-unique-id-select"
                                                        options={deviceUniqueIdList?.filter(s => !selectedUniqueId.includes(s?.gpsDetail?.deviceUniqueId))}
                                                        value={deviceUniqueIdList.find(s => s?.gpsDetail?.deviceUniqueId == formData?.gps[index]?.deviceDetails?.uniqueId || '') || null}
                                                        onChange={(event, newValue) => {
                                                            handleDeviceInputChange(index, 'uniqueId', newValue?.gpsDetail?.deviceUniqueId)
                                                            handleDeviceInputChange(index, 'imeiNo', newValue?.gpsDetail?.imei)
                                                            handleDeviceInputChange(index, 'simNo', newValue?.gpsDetail?.simNumber)
                                                            setSelectedUniqueId([...selectedUniqueId, newValue?.gpsDetail?.deviceUniqueId]);
                                                        }}
                                                        getOptionLabel={(option) => option?.gpsDetail?.deviceUniqueId || ''}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Select Device Unique Id"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField label="IMEI No"
                                                        name="imeiNo"
                                                        value={form.deviceDetails.imeiNo || ''}
                                                        error={!!errors?.imeiNo}
                                                        helperText={errors?.imeiNo}
                                                        disabled
                                                        onFocus={() => handleError('', 'imeiNo')}
                                                        onChange={(e) => handleDeviceInputChange(index, 'imeiNo', e.target.value)}
                                                        fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField label="SIM No"
                                                        name="simNo"
                                                        disabled
                                                        value={form.deviceDetails.simNo || ''}
                                                        onChange={(e) => handleDeviceInputChange(index, 'simNo', e.target.value)}
                                                        error={!!errors.simNo}
                                                        helperText={errors.simNo}
                                                        onFocus={() => handleError('', 'simNo')}
                                                        // onChange={handleChange}
                                                        fullWidth />
                                                </Grid>

                                                {/* <Grid item xs={12}><Typography variant="h6">Network</Typography></Grid> */}

                                                {/* <Grid item xs={12} sm={12}>
                                                    <FormGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        {['Airtel', 'Vi', 'BSNL', 'Jio', 'None'].map((provider) => (
                                                            <FormControlLabel
                                                                key={provider}
                                                                control={
                                                                    <Switch
                                                                        error={!!errors.network}
                                                                        onFocus={() => handleError('', 'network')}
                                                                        // checked={formData.gps[index]?.deviceDetails?.network[provider.toLowerCase()] === true}
                                                                        checked={
                                                                            provider.toLocaleLowerCase() === defaultnetwork
                                                                                ? formData.gps[index]?.deviceDetails?.network[provider.toLowerCase()] === true ||
                                                                                formData.gps[index]?.deviceDetails?.network[provider.toLowerCase()] === false
                                                                                : formData.gps[index]?.deviceDetails?.network[provider.toLowerCase()] === true

                                                                        }
                                                                        onChange={() => handleDeviceSwitchChange(index, provider)} // Pass index and provider
                                                                    />
                                                                }
                                                                label={provider}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                    {!!errors.network && (
                                                        <span style={{ color: '#EA2024', fontSize: '12px' }}>{errors.network}</span>
                                                    )}
                                                </Grid> */}
                                                {/* <Grid item xs={12} sm={6}>
                                                    <TextField label="Network Provider"
                                                        name="networkProvider"
                                                        error={!!errors.networkProvider}
                                                        helperText={errors.networkProvider}
                                                        onChange={(e) => handleDeviceInputChange(index, 'networkProvider', e.target.value)}
                                                        fullWidth />
                                                </Grid> */}
                                                {/* <Grid item xs={12} sm={6}>
                                                    <TextField label="Plan Type"
                                                        error={!!errors.planType}
                                                        helperText={errors.planType}
                                                        onFocus={() => handleError('', 'planType')}
                                                        onChange={(e) => handleDeviceInputChange(index, 'planType', e.target.value)}
                                                        name="planType" fullWidth />
                                                </Grid>*/}

                                                {/* <Grid item xs={12} sm={6}>
                                                    <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            fullWidth
                                                            label="Date of Install"
                                                            value={selectedDateInstall}
                                                            error={!!errors.dateOfInstall}
                                                            onFocus={() => handleError('', 'dateOfInstall')}
                                                            // onChange={(newValue) => setSelectedDateInstall(newValue)}
                                                            onChange={(newValue) => handleDeviceInputChange(index, 'dateOfInstall', newValue)}
                                                        />
                                                    </LocalizationProvider>
                                                    <div> {!!errors.dateOfInstall ? <span style={{ color: '#EA2024', fontSize: '12px', marginLeft: '15px' }}>{errors.dateOfInstall}</span> : <></>}</div>

                                                </Grid> */}

                                                {/* Other Details */}
                                                {/* <Grid item xs={12}><Typography variant="h6">Other Details</Typography></Grid> */}

                                                {/* <Grid item xs={12} sm={12}>
                                                    <FormGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        {['New', 'Repair', 'Renew', 'Rental'].map((provider) => (
                                                            <FormControlLabel
                                                                key={provider}
                                                                control={
                                                                    <Switch
                                                                        error={!!errors.OtherDetails}
                                                                        onFocus={() => handleError('', 'OtherDetails')}

                                                                        checked={
                                                                            provider === 'Rental'
                                                                                ? formData.gps[index]?.deviceDetails?.otherDetails[provider.toLowerCase()] === true ||
                                                                                formData.gps[index]?.deviceDetails?.otherDetails[provider.toLowerCase()] === ''
                                                                                : formData.gps[index]?.deviceDetails?.otherDetails[provider.toLowerCase()] === true

                                                                        }
                                                                        onChange={() => handleDeviceSwitchChange2(index, provider)} // Pass index and provider

                                                                    />
                                                                }
                                                                label={provider}

                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </Grid> */}

                                                {/* <Grid item xs={12}><TextField label="Amount" name="amount"   onChange={(e) => handleDeviceInputChange(index, 'amount', e.target.value)} fullWidth /></Grid> */}

                                                {/* Driver Details */}
                                                <Grid item xs={12}><Typography variant="h6">Driver Details</Typography></Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Full Name"
                                                        name="fullName"
                                                        onChange={(e) => handleDriverInputChange(index, 'fullName', e.target.value)}
                                                        fullWidth
                                                        error={errors[`fullName_${index}`]}
                                                        helperText={errors[`fullName_${index}`] || ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Father's Name" name="fatherName" onChange={(e) => handleDriverInputChange(index, 'fatherName', e.target.value)} fullWidth error={errors[`fatherName_${index}`]} helperText={errors[`fatherName_${index}`]} /></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Contact No" name="contactNo" error={errors[`contactNo_${index}`]} helperText={errors[`contactNo_${index}`]}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value; if (/^\d*$/.test(newValue)) {
                                                            handleDriverInputChange(index, 'contactNo', newValue);
                                                            setErrors((prev) => ({ ...prev, contactNo: '' }));
                                                        } else {
                                                            setErrors((prev) => ({ ...prev, contactNo: 'Only numbers are allowed' })); // Set error message
                                                        }
                                                    }}
                                                    fullWidth
                                                />
                                                </Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Residential Contact No" name=" resContactNo"
                                                    error={errors[`resContactNo_${index}`]} helperText={errors[`resContactNo_${index}`]}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value; if (/^\d*$/.test(newValue)) {
                                                            handleDriverInputChange(index, 'resContactNo', e.target.value)
                                                            setErrors((prev) => ({ ...prev, resContactNo: '' }));
                                                        } else {
                                                            setErrors((prev) => ({ ...prev, resContactNo: 'Only numbers are allowed' })); // Set error message
                                                        }
                                                    }}
                                                    fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField label="Aadhaar No"
                                                        error={errors[`aadhar_${index}`]}
                                                        helperText={errors[`aadhar_${index}`]}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value; if (/^\d*$/.test(newValue)) {
                                                                handleDriverInputChange(index, 'aadhar', e.target.value)
                                                                setErrors((prev) => ({ ...prev, aadhar: '' }));
                                                            } else {
                                                                setErrors((prev) => ({ ...prev, aadhar: 'Only numbers are allowed' }));
                                                            }
                                                        }} name="aadhaar" fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Pan Card No" name="pan" onChange={(e) => handleDriverInputChange(index, 'pan', e.target.value)} fullWidth error={errors[`pan_${index}`]} helperText={errors[`pan_${index}`]} /></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Badge No"
                                                    error={errors[`badgeNo_${index}`]} helperText={errors[`badgeNo_${index}`]}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value; if (/^\d*$/.test(newValue)) {
                                                            handleDriverInputChange(index, 'badgeNo', e.target.value)
                                                            setErrors((prev) => ({ ...prev, [`badgeNo_${index}`]: '' }));
                                                        } else {
                                                            setErrors((prev) => ({ ...prev, [`badgeNo_${index}`]: 'Only numbers are allowed' }));
                                                        }
                                                    }} name="badge" fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Shift</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            error={errors[`shift_${index}`]}
                                                            helperText={errors[`shift_${index}`]}
                                                            onFocus={() => handleError('', 'shift')}
                                                            label="Shift"
                                                            name="shift"
                                                            onChange={(e) => handleDriverInputChange(index, 'shift', e.target.value)}
                                                        >
                                                            <MenuItem value="Day">Day</MenuItem>
                                                            <MenuItem value="Night">Night</MenuItem>
                                                        </Select>
                                                        <FormHelperText style={{ color: "#d32f2f" }}>{errors[`shift_${index}`]}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}><Typography variant="h6"> Driver Address</Typography></Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField label="street"
                                                        error={errors[`street_${index}`]}
                                                        helperText={errors[`street_${index}`]}
                                                        onFocus={() => handleError('', 'street')}
                                                        onChange={(e) => handleDriverInputChange(index, 'street', e.target.value)}
                                                        name="street"
                                                        fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField label="Landmark"
                                                        error={errors[`landmark_${index}`]}
                                                        helperText={errors[`landmark_${index}`]}
                                                        onFocus={() => handleError('', 'landmark')}
                                                        // onChange={(e) => handleDeviceInputChange(index, 'driverLandmark', e.target.value)}
                                                        onChange={(e) => handleDriverInputChange(index, 'landmark', e.target.value)}
                                                        name="landmark"
                                                        fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Autocomplete
                                                        id="state-select"
                                                        options={stateList}
                                                        value={stateList.find(s => s.isoCode === formData?.gps[index]?.driverDetails?.address?.state) || null}
                                                        onChange={(event, newValue) => handleDriverStateChange(index, event, newValue)}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                error={errors['driverState_' + index]}
                                                                helperText={errors['driverState_' + index]}
                                                                onFocus={() => handleError('', 'state')}
                                                                {...params}
                                                                label="Select State"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Autocomplete
                                                        id="city-select"
                                                        options={driverCityList}
                                                        value={driverCityList.find(c => c.name === formData?.gps[index]?.driverDetails?.address?.city) || null}
                                                        onChange={(event, newValue) => handleDriverCityChange(index, event, newValue)}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={errors['driverCity_' + index]}
                                                                helperText={errors['driverCity_' + index]}
                                                                onFocus={() => handleError('', 'driverCity')}
                                                                label="Select City"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Zip Code'
                                                        error={errors[`zipCode_${index}`]}
                                                        helperText={errors[`zipCode_${index}`]}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value; if (/^\d*$/.test(newValue)) {
                                                                const newValue = e.target.value.replace(/\D/g, '').slice(0, 10)
                                                                handleDriverInputChange(index, 'zipCode', newValue)
                                                                setErrors((prev) => ({ ...prev, zipCode: '' }));
                                                            } else {
                                                                setErrors((prev) => ({ ...prev, zipCode: 'Only numbers are allowed' }));
                                                            }
                                                        }}
                                                        name='zipCode'
                                                        fullWidth
                                                    />
                                                </Grid>


                                                {/* Site Details */}
                                                <Grid item xs={12}>
                                                    <Typography variant="h6">Site Details</Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        fullWidth
                                                        options={siteList}
                                                        getOptionLabel={(option) => option?.name}
                                                        // isOptionEqualToValue={(option, value) => option?._id === value}
                                                        // value={formData?.gps[index]?.vendorName}
                                                        onChange={(event, newValue) => handleSiteChange(index, newValue?._id || '')}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Sites"
                                                                error={!!errors.siteName}
                                                                helperText={errors.siteName}
                                                                onFocus={() => handleError('', 'siteName')}
                                                            />
                                                        )}
                                                    />
                                                    {/* {!siteTableOpen[index] ? <AddSiteModal index={index} screen={"requestForm"} handleSiteSubmit={handleSiteSubmit} isUpdate={false} siteTableOpen={siteTableOpen} setSiteTableOpen={setSiteTableOpen} formData={formData} setFormData={setFormData} /> : <></>} */}
                                                </Grid>

                                                {siteTableOpen[index] ?
                                                    <Grid item xs={12} sm={12} >
                                                        <div style={{ overflowY: 'scroll', scrollbarWidth: 'none', height: '25vh' }}>
                                                            <SiteTableName siteData={siteData} setSiteData={setSiteData} index={index} setFormData={setFormData} formData={formData} handleSiteSubmit={handleSiteSubmit} isUpdate={false} siteTableOpen={siteTableOpen} setSiteTableOpen={setSiteTableOpen} />
                                                        </div>

                                                    </Grid> : <></>}

                                                {/* <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Street"
                                                        error={!!errors.street}
                                                        helperText={errors.street}
                                                        onFocus={() => handleError('', 'street')}
                                                        onChange={(e) => handleSiteInputChange(index, 'street', e.target.value)}  // Updated to use handleSiteInputChange
                                                        name="street"
                                                        fullWidth
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Landmark"
                                                        error={!!errors.landmark}
                                                        helperText={errors.landmark}
                                                        onFocus={() => handleError('', 'landmark')}
                                                        onChange={(e) => handleSiteInputChange(index, 'landmark', e.target.value)}  // Updated to use handleSiteInputChange
                                                        name="landmark"
                                                        fullWidth
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Autocomplete
                                                        id="state-select"
                                                        options={stateList}
                                                        value={stateList.find(s => s.isoCode === formData.gps[index].siteDetails.state) || null}
                                                        onChange={(event, newValue) => handleSiteStateChange(index, event, newValue)}  // Correctly pass newValue
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={!!errors.state}
                                                                helperText={errors.state}
                                                                onFocus={() => handleError('', 'state')}
                                                                label="Select State"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Autocomplete
                                                        id="city-select"
                                                        options={siteCityList}
                                                        value={cityList.find(c => c.name === formData.gps[index].siteDetails.city) || null}
                                                        onChange={(event, newValue) => handleSiteCityChange(index, event, newValue)}  // Correctly pass newValue
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={!!errors.city}
                                                                helperText={errors.city}
                                                                onFocus={() => handleError('', 'city')}
                                                                label="Select City"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Zip Code"
                                                         
                                                        name="zipCode"
                                                        error={!!errors.zipCode}
                                                        helperText={errors.zipCode}
                                                        onFocus={() => handleError('', 'zipCode')}
                                                        onChange={(e) => handleSiteInputChange(index, 'zipCode', e.target.value)}  // Updated to use handleSiteInputChange
                                                        fullWidth
                                                    />
                                                </Grid> */}

                                                {/* Vehicle Details */}

                                                <Grid item xs={12}><Typography variant="h6">Vehicle Details</Typography></Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth error={errors?.brand}>
                                                        <Autocomplete
                                                            disablePortal
                                                            options={brandList}
                                                            getOptionLabel={(option) => option?.name}
                                                            sx={{ width: 'auto' }}
                                                            required
                                                            value={
                                                                brandList?.find((brand) => brand?._id === formData?.gps[index]?.vehicleDetails?.brand) || null
                                                            }
                                                            onChange={(event, newValue) => handleVehicleInputChange(index, 'brand', newValue?._id)}
                                                            renderInput={(params) => {
                                                                return <TextField {...params} label="Select Brands" />;
                                                            }}
                                                        />
                                                        <FormHelperText sx={{ color: '#D32f2f' }}>{errors?.brand}</FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <  Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth error={errors?.model}>
                                                        <Autocomplete
                                                            disablePortal
                                                            options={modelList?.[index] || []}
                                                            getOptionLabel={(option) => option?.name}
                                                            sx={{ width: 'auto' }}
                                                            value={modelList?.[index]?.find((model) => model?._id === formData?.gps[index]?.vehicleDetails?.model) || null}
                                                            required
                                                            onChange={(event, newValue) => handleVehicleInputChange(index, 'model', newValue?._id)}

                                                            renderInput={(params) => {
                                                                return <TextField {...params} label="Select Models" />
                                                            }}
                                                        />
                                                        <FormHelperText sx={{ color: '#D32f2f' }}>{errors?.model}</FormHelperText>
                                                    </FormControl>
                                                </Grid >

                                                {/* <Grid item xs={12} sm={6}><TextField label="Brand" name="brand" onChange={(e) => handleVehicleInputChange(index, 'brand', e.target.value)} fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Model" name="model" onChange={(e) => handleVehicleInputChange(index, 'model', e.target.value)} fullWidth /></Grid> */}
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        error={!!errors[`regnNumber_${index}`]}
                                                        helperText={errors[`regnNumber_${index}`]}
                                                        label="Registration Number"
                                                        name="regnNumber"
                                                        onChange={(e) => handleVehicleInputChange(index, 'regnNumber', e.target.value)}
                                                        fullWidth

                                                    />
                                                </Grid>
                                                {/* <Grid item xs={12} sm={6}><TextField label="Plate" name="plate" onChange={(e) => handleVehicleInputChange(index, 'plate', e.target.value)} fullWidth /></Grid> */}
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id={`plate-select-label-${index}`}>Plate</InputLabel>
                                                        <Select
                                                            label="Plate"
                                                            labelId={`plate-select-label-${index}`}
                                                            value={formData.gps[index]?.vehicleDetails?.plate || ''}
                                                            onChange={(e) => handleVehicleInputChange(index, 'plate', e.target.value)}
                                                        >
                                                            <MenuItem value="Yellow">Yellow</MenuItem>
                                                            <MenuItem value="Green">Green</MenuItem>
                                                            <MenuItem value="Black">Black</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Owner Name" name="regnOwnerName" onChange={(e) => handleVehicleInputChange(index, 'regnOwnerName', e.target.value)} fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Owner Contact Number" name="regnOwnerContactNo"
                                                    onChange={(e) => {
                                                        const newValue = e.target.value; if (/^\d*$/.test(newValue)) {
                                                            handleVehicleInputChange(index, 'regnOwnerContactNo', e.target.value)
                                                            setErrors((prev) => ({ ...prev, regnOwnerName: '' }));
                                                        } else {
                                                            setErrors((prev) => ({ ...prev, regnOwnerName: 'Only numbers are allowed' })); // Set error message
                                                        }
                                                    }} fullWidth /></Grid>
                                                {/* <Grid item xs={12} sm={6}><TextField label="Type of Vehicle" name="typeOfVehicle" onChange={(e) => handleVehicleInputChange(index, 'typeOfVehicle', e.target.value)} fullWidth /></Grid> */}
                                                {/* <Grid item xs={12} sm={6}><TextField label="Vehicle Class" name="vehicleClass" onChange={(e) => handleVehicleInputChange(index, 'vehicleClass', e.target.value)} fullWidth /></Grid> */}
                                                <Grid item xs={12} sm={6}><TextField label="Chassis Number" name="chassisNumber" onChange={(e) => handleVehicleInputChange(index, 'chassisNumber', e.target.value)} fullWidth /></Grid>

                                                {/* <Grid item xs={12} sm={6}><TextField label="SIM Card" name="simCard" onChange={(e) => handleVehicleInputChange(index, 'simCard', e.target.value)} fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Device" name="device" onChange={(e) => handleVehicleInputChange(index, 'device', e.target.value)} fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Wiring" name="wiring" onChange={(e) => handleVehicleInputChange(index, 'wiring', e.target.value)} fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="SOS Panic" name="sosPanic" onChange={(e) => handleVehicleInputChange(index, 'sosPanic', e.target.value)} fullWidth /></Grid> */}
                                                {/* <Grid item xs={12} sm={6}><TextField label="Vehicle Class" name="vehicleClass" onChange={(e) => handleVehicleInputChange(index, 'vehicleClass', e.target.value)} fullWidth /></Grid> */}



                                                {/* <Grid item xs={12} sm={6}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography>With Relay</Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={formData.gps[index]?.deviceDetails?.relay || false}
                                                                    onChange={(e) => handleDeviceInputChange(index, 'relay', e.target.checked)}
                                                                />
                                                            }
                                                        />
                                                    </Box>
                                                </Grid> */}
                                                {/*  {formData.gps[index]?.deviceDetails?.relay &&
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl fullWidth>
                                                            <InputLabel>Relay Voltage</InputLabel>
                                                            <Select
                                                                label="Relay Voltage"
                                                                value={formData.gps[index]?.deviceDetails?.voltage || ''}
                                                                onChange={(e) => handleDeviceInputChange(index, 'voltage', e.target.value)}  // Update voltage value in formData
                                                            >
                                                                <MenuItem value="12 volt">12 volt</MenuItem>
                                                                <MenuItem value="24 volt">24 volt</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>} */}

                                                {/* <Grid item xs={12} sm={6}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography>With network</Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={formData.gps[index]?.deviceDetails?.networkSwitch || false}
                                                                    onChange={(e) => handleDeviceInputChange(index, 'networkSwitch', e.target.checked)}
                                                                />
                                                            }
                                                        />
                                                    </Box>
                                                </Grid> */}

                                                {/* <Grid item xs={12} sm={6}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography>with Server</Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={formData.gps[index]?.deviceDetails?.serverSwitch || false}
                                                                    onChange={(e) => handleDeviceInputChange(index, 'serverSwitch', e.target.checked)}
                                                                />
                                                            }
                                                        />
                                                    </Box>
                                                </Grid> */}

                                                {/* {formData.gps[index]?.deviceDetails?.serverSwitch &&
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField label="Server"
                                                            name="server"
                                                            error={!!errors.server}
                                                            helperText={errors.server}
                                                            onFocus={() => handleError('', 'server')}
                                                            onChange={(e) => handleDeviceInputChange(index, 'server', e.target.value)}
                                                            fullWidth />
                                                    </Grid>} */}

                                                {/* <Grid item xs={12} sm={6}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography>with installation</Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={formData.gps[index]?.deviceDetails?.installation || false}
                                                                    onChange={(e) => handleDeviceInputChange(index, 'installation', e.target.checked)}
                                                                />
                                                            }
                                                        />
                                                    </Box>
                                                </Grid> */}

                                                {/* <Grid item xs={12} sm={6}>
                                                  
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography>Panic Button</Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={formData.gps[index]?.deviceDetails?.panicButton || false}
                                                                    onChange={(e) => handleDeviceInputChange(index, 'panicButton', e.target.checked)}
                                                                />
                                                            }
                                                        />
                                                    </Box>
                                                </Grid> */}

                                                {/* Installer Details */}
                                                {/* <Grid item xs={12}><Typography variant="h6">Installer Details</Typography></Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField label="Installer Name" name="name"
                                                        onChange={(e) => handleInstallerInputChange(index, 'name', e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Installer Contact No"   name="contactNo"
                                                    onChange={(e) => handleInstallerInputChange(index, 'contactNo', e.target.value)}
                                                    fullWidth /></Grid> */}

                                                <Grid item xs={12} sm={12}>
                                                    <Typography> We Will provide SIM Card , Wiring , Device , SOS Panic Button </Typography>
                                                </Grid>


                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>)
                            })}
                            <Grid item xs={12} sx={{ textAlign: 'right' }}>
                                {index > 0 && <Button onClick={() => removeGpsForm(index)}
                                    variant="outlined"
                                    style={{
                                        borderColor: primaryColor,
                                        color: primaryColor,
                                        border: `2px solid ${primaryColor}`,
                                        textTransform: 'capitalize',
                                        padding: '1% 3.5%',
                                        textAlign: 'center',
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                        marginRight: "10px"
                                    }}
                                >
                                    Remove
                                </Button>}
                                <Button onClick={addForm} variant="contained" style={buttonStyles}>
                                    Add More
                                </Button>
                            </Grid>
                        </Grid>


                        {/* Submit Button */}
                        <Grid item xs={12}>
                            <Button variant="contained" type="submit" style={buttonStyles}>
                                {loader ? 'Loading...' : "Submit"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid >
            <Grid item md={3} style={{ position: 'sticky', top: "8%", height: "100%" }}>
                {
                    excelData.length === 0 &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%", gap: "10px" }}>
                        <Button
                            onClick={downloadCSV}
                            variant='outlined'
                            sx={{
                                borderColor: primaryColor,
                                color: primaryColor,
                                textTransform: 'capitalize',
                                borderRadius: 0,
                                fontWeight: 400,
                                boxShadow: 'none',
                                '&:hover': {
                                    borderColor: primaryColor,
                                    boxShadow: 'none',
                                },
                                '&:focus': {
                                    outline: 'none',
                                    boxShadow: 'none',
                                    borderColor: primaryColor,
                                }
                            }}
                        >
                            Download Excel
                        </Button>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{
                                textTransform: 'capitalize',
                                background: primaryColor,
                                textAlign: 'center',
                                color: 'white',
                                borderRadius: 0,
                                fontWeight: 400,
                                boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: primaryColorHover,
                                    boxShadow: 'none',
                                },
                                '&:focus': {
                                    outline: 'none',
                                    boxShadow: 'none',
                                    backgroundColor: primaryColor,
                                },
                            }}
                        >
                            UPLOAD EXCEL
                            <input
                                type="file"
                                accept=".xlsx, .xls"
                                hidden
                                onChange={handleFileUpload}
                            />
                        </Button>
                    </div>
                }
                {/* Show file name after upload */}
                {fileName && <p>Uploaded File: {fileName}</p>}

                {excelData.length > 0 && (
                    <Button
                        variant="contained"
                        sx={{
                            background: primaryColor,
                            color: 'white',
                            borderRadius: 0,
                            fontWeight: 400,
                            textTransform: 'capitalize',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: primaryColorHover,
                                boxShadow: 'none',
                            },
                            '&:focus': {
                                outline: 'none',
                                boxShadow: 'none',
                                backgroundColor: primaryColor,
                            }
                        }}
                        onClick={handleSubmitExcel}
                    >
                        Submit
                    </Button>
                )}
            </Grid>
        </Grid >
    );
}


