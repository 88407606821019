import React, { useEffect, useState } from 'react';
import { Grid, Pagination, Typography, useTheme, useMediaQuery } from '@mui/material';
import { CiFilter } from "react-icons/ci";
import { useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { primaryColor } from '../../constant';
import Filters from '../../components/Filters';
import FilterResults from '../../components/FilterResults';
import { get_data, post_data } from '../../api';
import { useLocation, useParams } from 'react-router-dom';

export default function SearchedGps() {
    const location = useLocation();

    const { product, formState } = location.state;

    const { user_data } = useSelector(state => state.user);

    const theme = useTheme();
    const matches1 = useMediaQuery(theme.breakpoints.down('md'));
    const matches2 = useMediaQuery(theme.breakpoints.down(600));
    const matches3 = useMediaQuery(theme.breakpoints.down(400));

    const [data, setData] = useState(product?.products || []);
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState({ right: false });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(product?.totalPage);
    const [totalData, setTotalData] = useState(product?.totalProducts);


    const itemsPerPage = 15;
    const count = data?.length;

    // useEffect(function () {
    //     if (currentPage) {
    //         fetchProduct();
    //     }
    // }, [currentPage, brandName]);


    return (
        <>
            <Header />
            <div style={{ background: '#EFF5F7' }}>
                <Grid container spacing={2} style={{ padding: '3% 2%' }}>
                    {/* <Grid item md={2.5} sm={0}>
                        {matches1 ? <></> : matches3 ? <></> :
                            <Filters />
                        }
                    </Grid> */}

                    <Grid item md={12} sm={12} xs={12}>
                        <FilterResults
                            user_data={user_data}
                            itemsPerPage={itemsPerPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            count={count}
                            totalData={totalData}
                            totalPages={totalPages}
                            data={data}
                            formState={formState}
                        />
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    );
}

const styles = {
    searchContainer: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '100%',

    },
    separator: {
        width: '1px',
        height: '20px',
        backgroundColor: primaryColor,
        marginRight: '10px',
    },
};
