import { Box, Button, Menu, MenuItem, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from 'xlsx';
import { formatDate, formatPrice, primaryColor } from "../../constant";
import EmptyPage from "../EmptyPage";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const InvoiceTransactionModal = ({ open, setOpen, data, fromDate, toDate, isRental }) => {

    const contentRef = useRef(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const Menuopen = Boolean(anchorEl);

    const styles = {
        invoice: {
            padding: "4%",
            height: 470,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #000",
            paddingBottom: "10px",
            marginBottom: "5%",
        },
        brand: {
            textAlign: "left",
        },
        info: {
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        infoSection: {
            textAlign: "left",
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
        },
        th: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        td: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        footer: {
            marginTop: "20px",
            borderTop: "2px solid #000",
            marginTop: "5%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        terms: {
            textAlign: "left",
        },
        payment: {
            textAlign: "left",
        },
        summary: {
            marginTop: "20px",
            textAlign: "right",
        },
        text: {
            fontSize: 14,
        },
    };


    const handleDownloadPdf = async () => {
        if (contentRef.current) {
            const customPageWidth = 397;
            const margin = 10;
            const rowHeight = 10;
            const headerHeight = 20;

            const totalRows = data?.length || 0;
            const contentHeight = totalRows * rowHeight + headerHeight;

            const pdf = new jsPDF({
                orientation: "landscape",
                unit: "mm",
                format: [customPageWidth, contentHeight + margin * 2],
            });

            const tableStartY = margin;
            let positionY = tableStartY;

            pdf.setFontSize(12);
            pdf.text("S No.", margin + 30, positionY);
            pdf.text("Invoice no.", margin + 70, positionY);
            pdf.text("Invoice To", margin + 110, positionY);
            pdf.text("Transaction Number", margin + 140, positionY);
            pdf.text("Mode", margin + 200, positionY);
            pdf.text("Amount", margin + 240, positionY);
            pdf.text("Date", margin + 280, positionY);

            positionY += rowHeight;

            data.forEach((item, i) => {
                pdf.setFontSize(10);
                pdf.text(`${i + 1}` || "-", margin + 30, positionY);
                pdf.text(item?.invoiceNumber || "-", margin + 70, positionY);
                pdf.text(item?.clientId?.companyName || "-", margin + 110, positionY);
                pdf.text(item?.item?.transactionNumber || '-', margin + 150, positionY);
                pdf.text(item?.item?.mode || '-', margin + 200, positionY);
                pdf.text(formatPrice(item?.paymentAmount) || '-', margin + 240, positionY);
                pdf.text(formatDate(item?.paymentAmount) || '-', margin + 280, positionY);

                positionY += rowHeight;
            });

            pdf.save("invoice.pdf");
        }
    };


    const handleDownloadExcel = () => {
        if (data && data.length > 0) {
            // Prepare the data in the format needed for Excel

            const excelData = data.map(item => ({
                // 'S No.': item?.invoiceNumber || '',
                'Invoice no.': item?.invoiceNumber || '',
                'Invoice To': item?.userId?.name || '-',
                'Transaction Number': item?.transactionNumber || '',
                'Status': item?.mode || '',
                'Amount': formatPrice(item?.paymentAmount) || '',
                'Date': formatDate(item?.createdAt) || '',

            }));


            // Create a new workbook and add the data
            const worksheet = XLSX.utils.json_to_sheet(excelData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");

            // Download the file
            XLSX.writeFile(workbook, "invoices.xlsx");
        }
    };

    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        maxHeight: '90%',
        borderRadius: 2,
        overflowY: 'auto',
        bgcolor: "white",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        border: "none",
        boxShadow: 24,
        padding: '0 0 5%',
    };

    const button = {
        padding: '5px',
        fontSize: 13,
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '15%',
        marginTop: '20px',
        boxShadow: 'none',
        marginLeft: '2%'
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={styleModal}>
                    {
                        data?.length === 0 ? (
                            <EmptyPage />
                        ) : (
                            <>
                                {/* <Button variant="contained" onClick={() => { handleDownloadExcel() }} style={button}>Download</Button> */}
                                <Button
                                    variant="contained"
                                    onClick={handleClick}
                                    style={button}
                                >
                                    Download <ArrowDropDownIcon />
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Menuopen}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MenuItem onClick={handleDownloadPdf}>Download as PDF</MenuItem>
                                    <MenuItem onClick={handleDownloadExcel}>Download as Excel</MenuItem>
                                </Menu>
                                <div ref={contentRef} id="invoice" style={styles.invoice}>
                                    <div style={styles.header}>
                                        <div style={styles.brand}>
                                            <h2 style={{ fontWeight: 600, margin: 0, }}>Invoice's Details</h2>
                                            {/* <p style={{ fontSize: 11, opacity: "70%" }}>
                                    Services with a Lifestyle
                                </p> */}
                                        </div>
                                        <h2 style={{ fontWeight: 600, margin: 0 }}>SALES INVOICE</h2>
                                    </div>

                                    <table style={{ ...styles.table, ...styles.text, margin: "3% 0" }}>
                                        <thead style={{ textAlign: "left" }}>
                                            <tr>
                                                <th>S No.</th>
                                                <th>Invoice no.</th>
                                                <th>Invoice To</th>
                                                <th>Transaction Number</th>
                                                <th>Status</th>
                                                <th>Ammount</th>
                                                <th>Date</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {
                                                data?.map((item, i) => {
                                                    return (
                                                        <>
                                                            <tr style={{ margin: 0, borderBottom: '1px solid gainsboro' }}>
                                                                <td style={{ padding: '1% 0' }}>{i + 1}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.invoiceNumber}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.userId?.name || '-'}</td>
                                                                {/* <td style={{ padding: '1% 0' }}>{item?.listingId?.title} (Boosted AD)</td> */}
                                                                <td style={{ padding: '1% 0' }}>{item?.transactionNumber || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.mode || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{formatPrice(item?.paymentAmount)}</td>
                                                                <td style={{ padding: '1% 0' }}>{formatDate(item?.createdAt)}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{ ...styles.footer, ...styles.text }}>
                                        <div style={styles.terms}>
                                            <p>All Invoices are from {formatDate(fromDate)} to {formatDate(toDate)} period.</p>
                                            <p>For GPS</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </Box>
            </Modal>
        </>
    );
};

export default InvoiceTransactionModal;