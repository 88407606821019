import './App.css';
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { get_data } from "./api"
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { login } from './redux/slices/user-slice';
import AdminDashboard from './web-app/admin/pages/AdminDashboard';
import Home from './website/pages/Home';
import Request from './website/pages/Request';
import Uninstall from './website/pages/Uninstall';
import Claim from './website/pages/Claim';
import ProductPage from './website/pages/ProductPage';
import AdminLogin from './web-app/admin/pages/AdminLogin';
import VendorSignup from './web-app/admin/pages/VendorSignup';
import VendorLogin from './web-app/admin/pages/VendorLogin';
import VendorDashboard from './web-app/vendor/pages/VendorDashboard';
import Return from './website/pages/Return';
import Store from './website/pages/Store';
import PermissionDenied from "../src/components/PermissionDenied";
import CompanySignup from './components/CompanySignup';
import CompanyLogin from './components/CompanyLogin';
import ClientDashboard from './web-app/client/pages/ClientDashboard';
import Record from './website/pages/Record';
import Checkout from "./website/pages/Checkout";
import AdminForgetPassword from './web-app/admin/pages/AdminForgetPassword'
import AdminResetPassword from './web-app/admin/pages/AdminResetPassword'
import { Toaster } from 'react-hot-toast';
import ChangeAdminEmail from './components/ChangeAdminEmail';
import ChangeAdminPhone from './components/ChangeAdminPhone';
import SubCompanyLogin from './components/SubCompanyLogin';
import Contact from './website/pages/Contact';
import Faq from './website/pages/FAQ';
import UserDashboard from './web-app/User/UserDashboard';
import PrivacyPolicy from './website/pages/Privacy';
import Terms from './website/pages/Terms';
import ReturnPolicy from './website/pages/ReturnPolicy';
import PartsPolicy from './website/pages/PartsPolicy';
import FeedbackPolicy from './website/pages/FeedbackPolicy';
import ShippingPolicy from './website/pages/ShippingPolicy';
import NetworkPolicy from './website/pages/NetworkPolicy';
import AmcPolicy from './website/pages/AmcPolicy';
import InsurancePolicy from './website/pages/InsurancePolicy';
import AboutUs from './website/pages/AboutUs';
import SearchedGps from './website/pages/SearchedGps';

function App() {
  const dispatch = useDispatch();
  const { isLoggedIn, user_data } = useSelector(state => state.user);

  const token = localStorage.getItem("authToken");


  const get_user = async () => {
    const current_data = await get_data("user/get-user", { headers: { "Authorization": token } });

    if (current_data?.status) {
      dispatch(login(current_data?.data));
    } else {
    }
  };

  useEffect(() => {
    get_user();
  }, []);

  const [screenLoading, setScreenLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setScreenLoading(false)
    }, 2000);
  }, [])


  return (
    <div className="App">
      <Toaster position="top-right" />
      {
        screenLoading ?
          <>
          </>
          :
          <>
            <Router>
              <Routes>
                {
                  user_data?.isSuperAdmin ?
                    <Route element={<AdminDashboard />} path="/admin/dashboard/*" />
                    :
                    <>
                      <Route element={<PermissionDenied
                        type='auth'
                        title={'Login to view your Dashboard'} />} path="/admin/dashboard/*" />
                    </>
                }

                {
                  user_data?.isVendor ?
                    <Route element={<VendorDashboard />} path="/vendor/dashboard/*" />
                    :
                    <>
                      <Route element={<PermissionDenied
                        type='auth'
                        title={'Login to view your Dashboard'} />} path="/vendor/dashboard/*" />
                    </>
                }


                {
                  user_data?.isClient || user_data?.isSubClient ?
                    <Route element={<ClientDashboard />} path="/company/dashboard/*" />
                    :
                    <>
                      <Route element={<PermissionDenied
                        type='auth'
                        title={'Login to view your Dashboard'} />} path="/company/dashboard/*" />
                    </>
                }

                {user_data?.isUser ?
                  <Route element={<UserDashboard />} path="/userdashboard" />
                  :
                  <>
                    <Route element={<PermissionDenied
                      type='auth'
                      title={'Login to view your Dashboard'} />} path="/userdashboard/*" />
                  </>
                }


                <Route element={<Home />} path="/" />
                <Route element={(token != null && user_data?.isClient == true || user_data?.isSubClient || user_data?.isUser) ? <Request /> : <CompanyLogin />} path="/request" />
                <Route element={(token && user_data?.isClient || user_data?.isSubClient || user_data?.isUser) ? <Uninstall /> : <CompanyLogin />} path="/uninstall" />
                <Route element={(token && user_data?.isClient || user_data?.isSubClient || user_data?.isUser) ? <Claim /> : <CompanyLogin />} path="/claim" />
                <Route element={(token && user_data?.isClient || user_data?.isSubClient || user_data?.isUser) ? <Return /> : <CompanyLogin />} path="/return" />
                <Route element={(token && user_data?.isClient || user_data?.isSubClient || user_data?.isUser) ? <Record /> : <CompanyLogin />} path="/record" />
                <Route element={<ProductPage />} path="/product/:slug/:id" />
                <Route element={<Store />} path="/store" />
                <Route element={<ChangeAdminEmail />} path="/admin/change-email" />
                <Route element={<ChangeAdminPhone />} path="/admin/change-phone" />
                <Route element={<AdminLogin />} path="/admin/login" />
                <Route element={<VendorSignup />} path="/vendor/signup" />
                <Route element={<VendorLogin />} path="/vendor/login" />
                <Route element={<AdminForgetPassword />} path="/admin/forget-password" />
                <Route element={<AdminResetPassword />} path="/admin/reset-password/:token" />
                <Route element={<CompanySignup />} path="/company/signup" />
                <Route element={<CompanyLogin />} path="/company/login" />
                <Route element={<SubCompanyLogin />} path="/sub-client/login" />
                <Route element={<Checkout />} path="/checkout" />
                <Route element={<Contact />} path="/contact" />
                <Route element={<Faq />} path="/faq" />
                <Route element={<PrivacyPolicy />} path="/privacy-policy" />
                <Route element={<Terms />} path="/terms" />
                <Route element={<ReturnPolicy />} path="/return-policy" />
                <Route element={<PartsPolicy />} path="/parts-policy" />
                <Route element={<FeedbackPolicy />} path="/feedback-policy" />
                <Route element={<ShippingPolicy />} path="/shipping-policy" />
                <Route element={<NetworkPolicy />} path="/network-policy" />
                <Route element={<AmcPolicy />} path="/amc-policy" />
                <Route element={<InsurancePolicy />} path="/insurance-policy" />
                <Route element={<AboutUs />} path="/about" />
                <Route element={<Store />} path="/brand/:brandName" />
                <Route element={<SearchedGps />} path="/gps/:feature/:network" />
              </Routes>
            </Router>
          </>
      }
    </div>
  );
}

export default App;