import React, { useEffect, useRef, useState } from 'react';
import { Grid, FormLabel, FormControlLabel, CardMedia, IconButton, Typography, Button, useTheme, useMediaQuery, List, ListItem, ListItemText, Divider, Box, Autocomplete, FormControl, Avatar, TextField, Card, CardContent, MenuItem, Select, InputLabel, Radio, RadioGroup, Checkbox, FormHelperText } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Slider from 'react-slick';
import { formatDate, formatPrice, primaryColor, primaryColorHover } from '../constant';
import { get_data, serverURL } from '../api';
import parse from 'html-react-parser';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaAmazon } from 'react-icons/fa'
import { SiFlipkart } from "react-icons/si";
import SvgIcon from '@mui/material/SvgIcon';
import { ShoppingBag, SimCard } from '@mui/icons-material';
import { ShoppingCart } from '@mui/icons-material'
import { Download } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BottomCart from './BottomCart';
import { RiWhatsappFill } from "react-icons/ri";

const ProductComp = ({ product, qty, setQty, handleCheckout, productInfo, relay, setRelay, server, setServer, insurance, setInsurance, simCard, setSimCard, totalPrice, setTotalPrice, formData, setFormData, globalPrices, setGlobalPrices, installation, setInstallation, handleAddToCart, down, setDown }) => {
    const location = useLocation();
    const [accessoriesList, setAccessoriesList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedAccessories, setSelectedAccessories] = useState([])
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false)
    const displayKeys = [
        'type',
        'model',
        'trackingDeviceType',
        'trackingMethod',
        'gpsTrackerType',
        'serviceCompletion',
        'maintenanceType',
        'frequency',
        'color',
        'warranty',
    ];
    const theme = useTheme();
    const matches1 = useMediaQuery(theme.breakpoints.down(1100));
    const matches3 = useMediaQuery(theme.breakpoints.down(500));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const [mainImage, setMainImage] = useState(null);

    const diveleRef = useRef(null);
    const listenToScroll = () => {
        const scrollableDiv = diveleRef.current;

        if (scrollableDiv) {
            setDown(scrollableDiv.scrollTop > 135);
        }
    };


    useEffect(() => {
        const scrollableDiv = diveleRef.current;
        if (scrollableDiv) {
            scrollableDiv.addEventListener('scroll', listenToScroll);
        }

        return () => {
            if (scrollableDiv) {
                scrollableDiv.removeEventListener('scroll', listenToScroll);
            }
        };
    }, [])

    const fetchSelectedAccessories = () => {
        let accessoriesIds = JSON.parse(localStorage.getItem('accessoryId'))
        if (accessoriesIds?.[product?._id]) {
            setSelectedAccessories(accessoriesIds?.[product?._id])
        }
    }

    useEffect(function () {
        fetchSelectedAccessories();
    }, [refresh])

    useEffect(() => {
        setMainImage(product?.images?.[0]);
    }, [product]);

    const styles = {
        arrow: {
            borderRadius: '50%',
            padding: '5px',
            cursor: 'pointer',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        requestButton: {
            background: primaryColor,
            borderRadius: 0,
            color: 'white',
            width: '100%',
            padding: '3% 0',
        },
        cartButton: {
            width: '20%',
            marginLeft: '5px',
            borderColor: primaryColor,
            color: primaryColor,
            textTransform: 'capitalize',
            fontWeight: 400,
            boxShadow: 'none',
            borderRadius: 2,
            '&:hover': {
                borderColor: primaryColor,
                boxShadow: 'none',
            },
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
                borderColor: primaryColor,
            }
        },
        buyButton: {
            width: '50%',
            background: primaryColor,
            color: 'white',
            borderRadius: 0,
            fontWeight: 400,
            textTransform: 'capitalize',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: primaryColorHover,
                boxShadow: 'none'
            },
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
                backgroundColor: primaryColor,
            }
        },
        linkButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // padding: '5px',
            fontWeight: 'bold',
            textTransform: 'none',
            borderRadius: '10px',
        },
        amazon: {
            borderColor: '#FF9900',
            color: '#FF9900',
            '&:hover': {
                backgroundColor: '#FF990010'
            }
        },
        flipkart: {
            borderColor: '#2874F0',
            color: '#2874F0',
            '&:hover': {
                backgroundColor: '#2874F010'
            }
        },
        meesho: {
            borderColor: '#FF00B5',
            color: '#FF00B5',
            '&:hover': {
                backgroundColor: '#FF00B510'
            }
        },
        snapdeal: {
            borderColor: '#E40046',
            color: '#E40046',
            '&:hover': {
                backgroundColor: '#E4004610'
            }
        },
        card: {
            // border: '1px solid #eee',
            width: 'fit-content',
            borderRadius: '12px',
            textAlign: 'center',
            // padding: '5px',
            transition: 'all 0.3s ease',
            '&:hover': {
                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)'
            }
        }

    };


    const shareLink = 'https://oredogps.com' + location.pathname
    const shareWA = `https://wa.me/?text=${encodeURIComponent(shareLink)}`

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                style={{ ...styles.arrow, right: '-50px', position: 'absolute', top: '30%' }}
            >
                <ArrowForwardIosIcon style={{ fontSize: matches3 ? '1.8rem' : '2.2rem' }} />
            </IconButton>
        );
    };

    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                style={{ ...styles.arrow, left: '-50px', position: 'absolute', top: '30%' }}
            >
                <ArrowBackIosIcon style={{ fontSize: matches3 ? '1.8rem' : '2.2rem' }} />
            </IconButton>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 1200,
        autoplay: true,
        slidesToShow: matches1 ? 3 : 4,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    const getAllAccessoriesList = async () => {
        let result = await get_data(`accessory/get-all-accessories?pageNumber=${currentPage}`);

        let global = await get_data('global/get-global-prices');

        if (global?.status == true) {
            setGlobalPrices(global?.data);
        }

        if (result?.status == true) {
            setAccessoriesList(result?.data?.accessories);
        }



        Object.keys(productInfo).map((key) => {
            if (key == product?._id) {
                setTotalPrice(productInfo[key]?.totalPrice);
                setFormData({ ...formData, accessory: productInfo[key]?.accessory, accessories: productInfo[key]?.accessories });
                setRelay(productInfo[key]?.relay);
                setServer(productInfo[key]?.server);
                setInsurance(productInfo[key]?.insurance);
                setSimCard(productInfo[key]?.simCard);
                setQty(productInfo[key]?.qty);
            }
        })
    }


    React.useEffect(function () {
        if (currentPage) {
            getAllAccessoriesList();
        }
    }, [currentPage])

    const showSlider = () => {
        return product?.images?.map((item, index) => {
            return item?.includes(".pdf") ? null :
                <div key={index} style={{ width: '100%' }}>
                    <img
                        style={{
                            width: '90%',
                            cursor: 'pointer',
                            border: '1px solid gainsboro',
                            aspectRatio: '1 / 1',
                            objectFit: 'contain',
                        }}
                        src={`${serverURL}/uploads/products-images/${item}`}
                        alt={`Product ${index + 1}`}
                        onClick={() => setMainImage(item)}
                    />
                </div>
        });
    };

    function camelToNormal(str) {
        // Insert a space before each uppercase letter and convert the whole string to lowercase
        return str
            .replace(/([A-Z])/g, ' $1')    // Insert space before uppercase letters
            .toLowerCase()                 // Convert the entire string to lowercase
            .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
    }




    const handleSelectAccessories = (accessory, isSelected) => {

        const accessoryIds = JSON.parse(localStorage.getItem("accessoryId")) || {};

        accessoryIds[product?._id] = accessory?._id;

        localStorage.setItem("accessoryId", JSON.stringify(accessoryIds));

        setRefresh(!refresh);
    };

    const handleDownloadExcel = async () => {
        const pdfFile = product?.images?.find(item => item.includes(".pdf"));

        window.open(`${serverURL}/uploads/products-images/${pdfFile}`, '_blank');

        // console.log("pdfFile", pdfFile);

        // if (pdfFile) {
        //     const pdfUrl = `${serverURL}/uploads/products-images/${pdfFile}`;

        //     try {
        // const response = await fetch(pdfUrl);
        // const blob = await response.blob();

        // // Create a link element
        // const link = document.createElement('a');
        // link.href = window.URL.createObjectURL(blob);
        // link.setAttribute('download', 'product-file.pdf'); // Set the download file name

        // // Append the link to the document body
        // document.body.appendChild(link);

        // // Programmatically click the link to trigger download
        // link.click();

        // // Clean up
        // window.URL.revokeObjectURL(link.href); // Release the object URL
        // document.body.removeChild(link); // Remove the link element
        // } catch (error) {
        //     console.log("Error downloading file:", error);
        // }
        // } else {
        //     console.log("No PDF found in the product images.");
        // }
    };

    return (
        <>
            <div style={{ padding: matches_md ? '1%' : '2% 4% 0' }} id='scroll'>
                <Grid container spacing={4} style={{ padding: 10, marginBottom: 10, }}>
                    {/* Left Side - Images */}
                    <Grid item xs={12} md={6} style={{ marginTop: matches3 ? 5 : 20, overflowY: matches_md ? '' : 'scroll', height: matches_md ? 'auto' : '100vh', scrollbarWidth: 'none' }} >
                        <Grid container spacing={2} style={{ width: '100%' }}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <CardMedia
                                    style={{
                                        width: matches3 ? '80%' : '60%',
                                        aspectRatio: '1 / 1',
                                        objectFit: 'contain',
                                    }}
                                    component="img"
                                    image={`${serverURL}/uploads/products-images/${mainImage}`}
                                    alt="Product Image"
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Slider {...settings} style={{ width: '80%' }}>
                                    {showSlider()}
                                </Slider>
                            </Grid>

                        </Grid>
                    </Grid>

                    <a
                        rel="noopener noreferrer"
                        aria-label="Share on WhatsApp"
                        href={shareWA}
                        target="_blank"
                        style={{
                            position: "fixed",
                            bottom: "120px",
                            right: "20px",
                            color: "white",
                            width: "60px",
                            height: "60px",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                            cursor: "pointer",
                            transition: "background-color 0.3s ease",
                        }}
                        className='whatsapp'
                    >
                        <IconButton style={styles.shareButton}>
                            <RiWhatsappFill style={{ fontSize: '30px', color: '#25D366' }} />
                        </IconButton>
                    </a>

                    {/* Right Side - Product Info */}
                    <Grid ref={diveleRef} item xs={12} md={6} padding={matches3 ? '' : 10} style={{ marginTop: matches3 ? 5 : 20, overflowY: matches_md ? '' : 'scroll', height: matches_md ? 'auto' : '85vh', scrollbarWidth: 'none' }}>
                        <Typography variant="h5" component="h1" style={{ fontWeight: 500, display: 'flex', justifyContent: 'space-between' }} >
                            {product?.brand} {product?.name}
                            {/* <a href={`${serverURL}/uploads/products-images/06e81e66-c287-4730-b3f5-9e2e12ab9cb9.pdf`} download >download</a> */}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            By Oredo GPS
                        </Typography>

                        {/* Price Section */}
                        <Grid container alignItems="center" spacing={1} sx={{ marginTop: 2 }}>
                            <Grid item>
                                <Typography variant="h4" style={{ color: primaryColor, fontWeight: 500 }}>
                                    {formatPrice(totalPrice)}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                            In stock: Dispatch in 5 working days
                        </Typography>

                        <Box sx={{ mt: 3 }}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    <Typography variant="h6" >
                                        Choose a Network
                                    </Typography>
                                </FormLabel>
                                <RadioGroup
                                    row
                                    value={simCard}
                                    onChange={(e) => {
                                        setSimCard(e.target.value);
                                        if (e.target.value === 'no sim') {
                                            setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.[`${simCard}Price`]));
                                        }
                                        else {
                                            if (simCard != 'no sim') {
                                                setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.[`${simCard}Price`]));
                                                setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.[`${e.target.value}Price`]));
                                            } else {
                                                setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.[`${e.target.value}Price`]));
                                            }
                                        }
                                        setSimCard(e.target.value)
                                    }}
                                    sx={{
                                        justifyContent: "space-between",
                                        mt: 2,
                                        p: 2,
                                        border: "1px solid #ccc",
                                        borderRadius: 2,
                                        backgroundColor: "#f9f9f9",
                                    }}
                                >
                                    {product?.simCard?.includes('airtel') && <FormControlLabel
                                        value="airtel"
                                        control={<Radio />}
                                        label={<Typography variant="body1">Airtel</Typography>}
                                    />}
                                    {product?.simCard?.includes('vi') && <FormControlLabel
                                        value="vi"
                                        control={<Radio />}
                                        label={<Typography variant="body1">Vi</Typography>}
                                    />}
                                    {product?.simCard?.includes('bsnl') && <FormControlLabel
                                        value="bsnl"
                                        control={<Radio />}
                                        label={<Typography variant="body1">BSNL</Typography>}
                                    />}
                                    {product?.simCard?.includes('jio') && <FormControlLabel
                                        value="jio"
                                        control={<Radio />}
                                        label={<Typography variant="body1">Jio</Typography>}
                                    />}
                                    <FormControlLabel
                                        value="no sim"
                                        control={<Radio />}
                                        label={<Typography variant="body1">No Sim</Typography>}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>


                        {
                            product?.relay?.find((item) => item === 'no relay') ? ''
                                :
                                product?.relay?.includes('12v') && product?.relay?.includes('24v') ? (
                                    <Grid item md={12}>
                                        <FormControl fullWidth sx={{ mt: 2 }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: '20%' }}>
                                                    <FormLabel>
                                                        <Typography>
                                                            Relay
                                                        </Typography>
                                                    </FormLabel>
                                                </div>
                                                <div style={{ width: '80%' }}>
                                                    <Select
                                                        value={relay}
                                                        size='small'
                                                        fullWidth
                                                        onChange={(e) => {
                                                            if (e.target.value === 'no relay') {
                                                                setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.relay));
                                                            } else if (relay != 'no relay') {
                                                                // setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.relay));
                                                            } else {
                                                                setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.relay));
                                                            }
                                                            setRelay(e.target.value);
                                                        }}
                                                    // label="Relay"
                                                    >
                                                        <MenuItem value="no relay">No Relay</MenuItem>
                                                        <MenuItem value="12v">12V</MenuItem>
                                                        <MenuItem value="24v">24V</MenuItem>
                                                    </Select>
                                                </div>
                                            </div>
                                        </FormControl>
                                    </Grid>
                                )
                                    :
                                    <Grid item md={12}>
                                        <FormControl fullWidth sx={{ mt: 2 }}>
                                            {/* <InputLabel>Relay</InputLabel> */}
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: '20%' }}>
                                                    <FormLabel>
                                                        <Typography>
                                                            Relay
                                                        </Typography>
                                                    </FormLabel>
                                                </div>
                                                <div style={{ width: '80%' }}>
                                                    <Select
                                                        value={relay}
                                                        onChange={(e) => {
                                                            if (e.target.value === 'no relay') {
                                                                setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.relay));
                                                            } else if (relay != 'no relay') {
                                                                // setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.relay));
                                                            } else {
                                                                setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.relay));
                                                            }
                                                            setRelay(e.target.value);
                                                        }}
                                                        size='small'
                                                        fullWidth
                                                    >
                                                        <MenuItem value="no relay">No Relay</MenuItem>
                                                        {
                                                            product?.relay?.includes('12v') ? (
                                                                <MenuItem value="12v">12V</MenuItem>
                                                            ) : product?.relay?.includes('24v') ? (
                                                                <MenuItem value="24v">24V</MenuItem>
                                                            ) : null
                                                        }
                                                    </Select>
                                                </div>
                                            </div>

                                        </FormControl>
                                    </Grid>}

                        {/* <Grid item md={12}>

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>SIM Card</InputLabel>
                            <Select
                                value={simCard}
                                onChange={(e) => {
                                    setSimCard(e.target.value);
                                    if (e.target.value === 'no sim') {
                                        setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.simCard));
                                    } else if (simCard != 'no sim') { }
                                    else {
                                        setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.simCard));
                                    }
                                }}
                                label="SIM Card"
                            >
                                <MenuItem value="no sim">No Sim</MenuItem>
                                <MenuItem value="airtel">Airtel</MenuItem>
                                <MenuItem value="idea">Idea</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}

                        <Grid item md={12}>
                            <FormControl sx={{ mt: 2 }} fullWidth>
                                {/* <InputLabel>Server</InputLabel> */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '20%' }}>
                                        <FormLabel>
                                            <Typography>
                                                Server
                                            </Typography>
                                        </FormLabel>
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <Select
                                            value={server}
                                            onChange={(e) => {
                                                setServer(e.target.value);
                                                if (e.target.value === 'with server') {
                                                    setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.server));
                                                } else {
                                                    setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.server));
                                                }
                                            }}
                                            size='small'
                                            fullWidth
                                        >
                                            <MenuItem value="with server">With Server</MenuItem>
                                            <MenuItem value="without server">Without Server</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item md={12}>
                            <FormControl sx={{ mt: 2 }} fullWidth>
                                {/* <InputLabel>Insurance</InputLabel> */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '20%' }}>
                                        <FormLabel>
                                            <Typography>
                                                Insurance
                                            </Typography>
                                        </FormLabel>
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <Select
                                            value={insurance}
                                            onChange={(e) => {
                                                if (e.target.value === 'without insurance') {
                                                    setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.[insurance]));
                                                } else {
                                                    if (insurance != 'without insurance') {
                                                        setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.[insurance]));
                                                        setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.[e.target.value]));
                                                    } else {
                                                        setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.[e.target.value]));
                                                    }
                                                }
                                                setInsurance(e.target.value);
                                            }}
                                            size='small'
                                            fullWidth
                                        >
                                            <MenuItem value="insuranceOneYear">With 1 Year Insurance</MenuItem>
                                            <MenuItem value="insuranceTwoYear">With 2 Year Insurance</MenuItem>
                                            <MenuItem value="insuranceThreeYear">With 3 Year Insurance</MenuItem>
                                            <MenuItem value="without insurance">Without Insurance</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item md={12}>
                            <FormControl sx={{ mt: 2 }} fullWidth>
                                {/* <InputLabel>Insurance</InputLabel> */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '20%' }}>
                                        <FormLabel>
                                            <Typography>
                                                Installation
                                            </Typography>
                                        </FormLabel>
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <Select
                                            value={installation}
                                            onChange={(e) => {
                                                setInstallation(e.target.value);
                                                if (e.target.value === 'with installation') {
                                                    setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(globalPrices?.installation));
                                                } else {
                                                    setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(globalPrices?.installation));
                                                }
                                            }}
                                            size='small'
                                            fullWidth
                                        >
                                            <MenuItem value="with installation">With Installation</MenuItem>
                                            <MenuItem value="without installation">Without Installation</MenuItem>
                                        </Select>
                                        <FormHelperText>Installtion Only Available for Telangana</FormHelperText>
                                    </div>
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl sx={{ marginTop: '20px' }} fullWidth>
                                <FormLabel>
                                    <Typography variant="h6" >
                                        Accessories
                                    </Typography>
                                </FormLabel>
                                <Box sx={{
                                    // border: '1px solid #ccc', padding: '10px', borderRadius: '5px',
                                    // backgroundColor: "#f9f9f9",
                                }}>
                                    <Grid container spacing={1}> {/* Reduced spacing for compact look */}
                                        {product?.accessories?.map((item, i) => {
                                            const isSelected = formData?.accessories?.some((acc) => acc.title === item.title);
                                            return (
                                                <Grid item xs={4} md={3} key={i}>
                                                    <div style={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
                                                        <div
                                                            key={i}
                                                            style={{
                                                                position: 'relative',
                                                                width: '100%',
                                                                textAlign: 'center',
                                                                borderRadius: '8px',
                                                                cursor: 'pointer',
                                                                transition: 'transform 0.2s, box-shadow 0.2s',
                                                                padding: '8px',
                                                                border: isSelected ? '3px solid #000' : '1px solid #ccc',
                                                                height: '100%',
                                                                boxSizing: 'border-box',
                                                            }}
                                                            onClick={() => {
                                                                const selectedAccessory = product?.accessories.find((acc) => acc.title === item.title);
                                                                if (isSelected) {
                                                                    setFormData((prevData) => ({
                                                                        ...prevData,
                                                                        accessories: prevData?.accessories?.filter((acc) => acc.title !== item.title),
                                                                    }));
                                                                    setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(selectedAccessory?.price));
                                                                    handleSelectAccessories(selectedAccessory, false);
                                                                } else {
                                                                    setFormData((prevData) => ({
                                                                        ...prevData,
                                                                        accessories: [...(prevData?.accessories || []), selectedAccessory],
                                                                    }));
                                                                    setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(selectedAccessory?.price));
                                                                    handleSelectAccessories(selectedAccessory, true);
                                                                }
                                                            }}
                                                        >
                                                            {/* Accessory Image */}
                                                            <img
                                                                src={`${serverURL}/uploads/accessories-images/${item?.images[0]}`}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '120px',
                                                                    borderRadius: '8px',
                                                                    objectFit: 'cover',
                                                                }}
                                                            />

                                                            {/* Transparent Overlay for Accessory Name and Price */}
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '0px',
                                                                    left: '0',
                                                                    right: '0',
                                                                    padding: '5px 10px',
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Slightly darker transparent background for better contrast
                                                                    borderRadius: '4px',
                                                                    color: '#fff',
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                {/* Accessory Name */}
                                                                <div
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        maxWidth: '100%',
                                                                        fontSize: '14px'
                                                                    }}
                                                                >
                                                                    {item?.title}
                                                                </div>

                                                                {/* Accessory Price */}
                                                                <div
                                                                    style={{
                                                                        fontSize: '14px',
                                                                        textAlign: 'center',
                                                                    }}
                                                                >
                                                                    {formatPrice(item?.price)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Box>
                            </FormControl>
                        </Grid>

                        {/* Buttons */}
                        <Grid container spacing={2} sx={{ marginTop: 2 }}>
                            <Grid item xs={12} sx={{ display: 'flex', gap: '10px' }}>
                                {
                                    qty === 0 ?
                                        <Button variant="contained" sx={styles.buyButton} onClick={handleAddToCart}>Add to Cart</Button>
                                        :
                                        <>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 2,
                                                    border: '1px solid #ccc',
                                                    borderRadius: '8px',
                                                    padding: '8px',
                                                    width: 'fit-content',
                                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                                }}
                                            >
                                                <IconButton onClick={() => setQty(qty + 1)} color="primary">
                                                    <AddIcon />
                                                </IconButton>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {qty}
                                                </Typography>
                                                <IconButton onClick={() => {
                                                    if (qty > 0) setQty(qty - 1);
                                                }}
                                                    color="secondary">
                                                    <RemoveIcon />
                                                </IconButton>
                                            </Box>

                                            <Button variant="outlined" sx={styles.cartButton} onClick={handleCheckout}>Checkout</Button>
                                        </>
                                }
                                {/* <Button variant="contained" sx={styles.buyButton} onClick={handleBuyNow}>Buy Now</Button> */}
                            </Grid>
                        </Grid>



                        {/* Product Details Section */}
                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                            <ul className="product-details-list">
                                {
                                    Object.entries(product?.features)?.map(([key, value]) => (
                                        <li key={key}>
                                            {key} <span>{value}</span>
                                        </li>
                                    ))
                                }
                                <li>
                                    Type <span>{product?.type}</span>
                                </li>
                                <li>
                                    Model <span>{product?.model}</span>
                                </li>
                                <li>
                                    Tracking Device Type <span>{product?.trackingDeviceType}</span>
                                </li>
                                <li>
                                    Tracking Method <span>{product?.trackingMethod}</span>
                                </li>
                                <li>
                                    GPS Tracker Type <span>{product?.gpsTrackerType}</span>
                                </li>
                                <li>
                                    Service Completion <span>{product?.serviceCompletion}</span>
                                </li>
                                <li>
                                    Maintenance Type <span>{product?.maintenanceType}</span>
                                </li>
                                <li>
                                    Frequency <span>{product?.frequency}</span>
                                </li>
                                <li>
                                    Color <span>{product?.color}</span>
                                </li>
                                <li>
                                    Warranty <span>{product?.warranty}</span>
                                </li>
                            </ul>
                        </Typography>

                    </Grid>
                    {/* 
                <Grid item xs={12}>
                    <FormControl sx={{ marginTop: '10px' }} fullWidth>
                        <Box sx={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                            <Typography variant="h6" gutterBottom sx={{ marginBottom: '10px' }}>
                                Select Accessories
                            </Typography>
                            <Grid container spacing={2}>
                                {product?.accessories?.map((item, i) => {
                                    const isSelected = formData?.accessories?.some((acc) => acc.title === item.title);

                                    return (
                                        <Grid item xs={12} sm={6} md={4} key={i}>
                                            <Card
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    padding: '15px',
                                                    textAlign: 'center',
                                                    borderRadius: '10px',
                                                    cursor: 'pointer',
                                                    transition: 'transform 0.2s, box-shadow 0.2s',
                                                    // backgroundColor: isSelected ? primaryColor : '#fff', // Change color if selected
                                                    border: isSelected ? '2px solid #00796b' : '1px solid #ccc',
                                                    '&:hover': {
                                                        transform: 'scale(1.02)',
                                                        boxShadow: 2,
                                                    },
                                                }}
                                                onClick={() => {
                                                    const selectedAccessory = product?.accessories.find((acc) => acc.title === item.title);

                                                    if (isSelected) {
                                                        setFormData((prevData) => ({
                                                            ...prevData,
                                                            accessories: prevData?.accessories?.filter((acc) => acc.title !== item.title),
                                                        }));
                                                        setTotalPrice((prevPrice) => parseInt(prevPrice) - parseInt(selectedAccessory?.price));
                                                        handleSelectAccessories(selectedAccessory, false);
                                                    } else {
                                                        setFormData((prevData) => ({
                                                            ...prevData,
                                                            accessories: [...(prevData?.accessories || []), selectedAccessory],
                                                        }));
                                                        setTotalPrice((prevPrice) => parseInt(prevPrice) + parseInt(selectedAccessory?.price));
                                                        handleSelectAccessories(selectedAccessory, true);
                                                    }
                                                }}
                                            >
                                                <img
                                                    src={`${serverURL}/uploads/accessories-images/${item?.images[0]}`}
                                                    alt={item.title}
                                                    style={{
                                                        width: '80px',
                                                        height: '80px',
                                                        borderRadius: '5px',
                                                        objectFit: 'cover',
                                                        marginBottom: '10px',
                                                    }}
                                                />
                                                <Typography
                                                    variant="subtitle1"
                                                    fontWeight="bold"
                                                    sx={{
                                                        marginBottom: '5px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{ marginBottom: '10px' }}
                                                >
                                                    ${formatPrice(item?.price)}
                                                </Typography>
                                            </Card>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    </FormControl>
                </Grid> */}



                    {product?.description && (
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Accordion style={{ width: '100%' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                                    Product Description
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="h5" component="h1" style={{ fontWeight: 500, display: 'flex', justifyContent: 'space-between' }}>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Download />}
                                            style={{
                                                padding: '5px 20px',
                                                fontSize: 13,
                                                color: primaryColor,
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                                marginLeft: 'auto',
                                            }}
                                            onClick={handleDownloadExcel}
                                            download
                                        >
                                            Brochure
                                        </Button>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                        {parse(product?.description || '')}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                        Get it by Thu, 20 Aug
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Easy returns available | Cash on delivery available
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    )}

                    {(product?.amazon || product?.flipkart || product?.meesho || product?.snapdeal) && (
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Accordion style={{ width: '100%' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                                    Visit Other Platforms
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sx={{ display: 'flex', gap: '10px' }}>
                                            {product?.amazon && (
                                                <Button
                                                    variant="outlined"
                                                    sx={{ ...styles.linkButton, ...styles.amazon }}
                                                    href={product?.amazon}
                                                    target="_blank"
                                                >
                                                    Visit Amazon
                                                </Button>
                                            )}

                                            {product?.flipkart && (
                                                <Button
                                                    variant="outlined"
                                                    sx={{ ...styles.linkButton, ...styles.flipkart }}
                                                    href={product?.flipkart}
                                                    target="_blank"
                                                >
                                                    Visit Flipkart
                                                </Button>
                                            )}
                                            {product?.meesho && (
                                                <Button
                                                    variant="outlined"
                                                    sx={{ ...styles.linkButton, ...styles.meesho }}
                                                    href={product?.meesho}
                                                    target="_blank"
                                                >
                                                    Visit Meesho
                                                </Button>
                                            )}
                                            {product?.snapdeal && (
                                                <Button
                                                    variant="outlined"
                                                    sx={{ ...styles.linkButton, ...styles.snapdeal }}
                                                    href={product?.snapdeal}
                                                    target="_blank"
                                                >
                                                    Visit Snapdeal
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    )}
                </Grid>
            </div>
            {/* {!down ? <div style={{ width: '100%', position: 'sticky', bottom: 0 }}>
    </div> : <></>}   */}
        </>
    );
};

export default ProductComp;