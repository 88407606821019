import React, { useEffect, useState } from "react";
import { get_data, post_data } from "../api";
import { createButtonBg, formatDate, formatPrice } from "../constant";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Avatar, Backdrop, Box, Button, Card, CardContent, CircularProgress, List, ListItem, Paper, Typography } from "@mui/material";
import EmptyPage from "./EmptyPage";
import { useSelector } from "react-redux";
import BusinessIcon from "@mui/icons-material/Business";
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '0px',
        marginLeft: '15px',
        '&.Installed': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.pending': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.rejected': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
});

const CompanyDashComp = () => {
    const classes = useStyles();
    const { user_data } = useSelector(state => state.user)
    const navigate = useNavigate()
    const [uninstalledList, setUninstalledList] = useState([])
    const [requestList, setRequestList] = useState([])
    const [returnList, setReturnList] = useState([])
    const [claimsList, setClaimsList] = useState([]);
    const [inventorysList, setInventorysList] = useState([]);
    //   const [ecommerceSale, setEcommerceSale] = useState(0);
    //   const [rentalSale, setRentalSale] = useState(0);
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(true)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [isEmpty, setIsEmpty] = useState(false);

    const getUninstalledList = async () => {
        let result = await get_data(`uninstall/get-uninstall-for-client-dashboard`)

        if (result?.status == true) {
            setUninstalledList(result?.data)
            if (result?.data) {
                setOpen(false)
            }
        }
    }

    const getRequestList = async () => {
        let result = await get_data(`request/get-request-for-client-dashboard`)
        if (result?.status == true) {
            setRequestList(result?.data)
            if (result?.data) {
                setOpen(false)
            }
        }
    }

    const getReturnList = async () => {
        let result = await get_data(`return/get-return-for-client-dashboard`)
        if (result?.status == true) {
            setReturnList(result?.data)
            if (result?.data) {
                setOpen(false)
            }
        }
    }

    const getClaimsList = async () => {
        let result = await get_data(`claim/get-claim-for-client-dashboard`)
        if (result?.status == true) {
            setClaimsList(result?.data)
            if (result?.data) {
                setOpen(false)
            }
        }
    }

    const getInventoryList = async () => {
        let result = await get_data(`inventory/get-inventory-by-client-for-dashboard/${user_data?._id}`)
        if (result?.status == true) {
            setInventorysList(result?.data)
            if (result?.data) {
                setOpen(false)
            }
        }
    }


    useEffect(function () {
        getClaimsList()
        getRequestList()
        getReturnList()
        getUninstalledList()
        getInventoryList()
    }, []);

    const showList = (data, title) => {
        return (
            <Card sx={{ borderRadius: 3, boxShadow: 2, mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ borderBottom: "1px solid #000", marginBottom: 2 }}>
                        {title}
                    </Typography>
                    {
                        data?.map((item, index) => (
                            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid #ddd" }}>
                                <Typography key={index} variant="body1" sx={{ marginTop: 2 }}>
                                    {item?.uniqueRequestId}
                                </Typography>
                                <Typography key={index} variant="body1" sx={{ marginTop: 2 }}>
                                    {item?.gps?.length} Devices
                                </Typography>
                                <Typography key={index} variant="body1" sx={{ marginTop: 2 }}>
                                    {formatDate(item?.createdAt)}
                                </Typography>
                            </div>
                        ))}
                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: 4 }}>
                        <Button style={{ fontSize: 12, color: '#000' }} onClick={() => navigate('/company/dashboard/request')}>
                            View all
                        </Button>
                    </div>
                </CardContent>
            </Card>
        );
    };



    const showUninstallList = (data, title) => {
        return (
            <Card sx={{ borderRadius: 3, boxShadow: 2, mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ borderBottom: "1px solid #000", marginBottom: 2 }}>
                        {title}
                    </Typography>
                    {
                        data?.map((item, index) => (
                            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid #ddd" }}>
                                <Typography key={index} variant="body1" sx={{ marginTop: 2 }}>
                                    {title === 'UNINSTALL' ? item?.uniqueUninstallId : title === "RETURN" ? item?.uniqueReturnId : item?.uniqueClaimId}
                                </Typography>
                                <Typography key={index} variant="body1" sx={{ marginTop: 2 }}>
                                    {item?.vehicleNumbers?.map((item) => item)}
                                </Typography>
                                <Typography key={index} variant="body1" sx={{ marginTop: 2 }}>
                                    {formatDate(item?.createdAt)}
                                </Typography>
                            </div>
                        ))}

                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: 4 }}>
                        <Button style={{ fontSize: 12, color: '#000' }} onClick={() => title === 'UNINSTALL' ? navigate('/company/dashboard/uninstall') : title === 'RETURN' ? navigate('/company/dashboard/returns') : navigate('/company/dashboard/claims')}>
                            View all
                        </Button>
                    </div>
                </CardContent>
            </Card>
        );
    };



    const showInventoryList = (data, title) => {
        console.log('data', data)
        return (
            <Card sx={{ borderRadius: 3, boxShadow: 2, mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ borderBottom: "1px solid #000", marginBottom: 2 }}>
                        {title}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid #ddd" }}>
                        <Typography variant="body1" sx={{ marginTop: 2, width: '20%' }}>
                            Vehicle Number
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, width: '15%' }}>
                            Imei Number
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, width: '15%' }}>
                            Sim Number
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, width: '15%' }}>
                            Device UniqueId
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, width: '15%' }}>
                            Network
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, width: '15%' }}>
                            Status
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, width: '15%' }}>
                            Date
                        </Typography>
                    </div>
                    {
                        data?.map((item, index) => (
                            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid #ddd" }}>
                                <Typography key={index} variant="body1" sx={{ marginTop: 2, width: '20%' }}>
                                    {item?.gpsDetail?.vehicleNumber}
                                </Typography>
                                <Typography key={index} variant="body1" sx={{ marginTop: 2, width: '15%' }}>
                                    {item?.gpsDetail?.imei}
                                </Typography>
                                <Typography key={index} variant="body1" sx={{ marginTop: 2, width: '15%' }}>
                                    {item?.gpsDetail?.simNumber}
                                </Typography>
                                <Typography key={index} variant="body1" sx={{ marginTop: 2, width: '15%' }}>
                                    {item?.gpsDetail?.deviceUniqueId}
                                </Typography>
                                <Typography key={index} variant="body1" sx={{ marginTop: 2, width: '15%' }}>
                                    {item?.gpsDetail?.network}
                                </Typography>
                                <Typography key={index} variant="body1" sx={{ marginTop: 2, width: '15%' }}>
                                    <Button
                                        style={{ fontSize: 11, fontWeight: 500, borderRadius: 30, background: item?.gpsDetail?.gpsStatus === 'Installed' ? '#e8f5e9' : item?.gpsDetail?.gpsStatus === 'in-service' ? '#fff3e0' : item?.gpsDetail?.gpsStatus === 'uninstalled' ? '#ffebee' : item?.gpsDetail?.gpsStatus === 'stock' ? '#ffebee' : '', color: item?.gpsDetail?.gpsStatus === 'Installed' ? '#43a047' : item?.gpsDetail?.gpsStatus === 'in-service' ? '#fb8c00' : item?.gpsDetail?.gpsStatus === 'uninstalled' ? '#fb8c00' : item?.gpsDetail?.gpsStatus === 'stock' ? '#e53935' : '' }}
                                    >
                                        {item?.gpsDetail?.gpsStatus === "Installed" ? 'Active' : item?.gpsDetail?.gpsStatus === "UnInstalled" ? 'Stock' : item?.gpsDetail?.gpsStatus || '-'}
                                    </Button>
                                </Typography>
                                <Typography key={index} variant="body1" sx={{ marginTop: 2, width: '15%' }}>
                                    {formatDate(item?.createdAt)}
                                </Typography>
                            </div>
                        ))}

                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: 4 }}>
                        <Button style={{ fontSize: 12, color: '#000' }} onClick={() => navigate('/company/dashboard/inventory')}>
                            View all
                        </Button>
                    </div>
                </CardContent>
            </Card>
        );
    };


    const rangePickerStyle = {
        display: 'flex',
        fontSize: 14,
        background: 'white',
        padding: '4% 0',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        width: 250,
        color: '#7E8299',
        border: '1px solid #ccc',
    };


    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    useEffect(() => {
        if (startDate && endDate) {
            fetchExportData();
            setOpen(true)
        }
    }, [startDate, endDate]);

    const fetchExportData = async () => {
        try {
            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();
            let response = await post_data('order/get-client-dashboard-statics-by-date', {
                from: fromDate,
                to: toDate
            })

            console.log('response', response)
            if (response?.status === true) {
                // setEcommerceSale(response?.data?.totalEcommerceSales)
                // setRentalSale(response?.data?.totalRentalSale)
                setClaimsList(response?.data?.claims)
                setRequestList(response?.data?.gpsRequests)
                setReturnList(response?.data?.returns)
                setUninstalledList(response?.data?.uninstall)
                setInventorysList(response?.data?.inventory)
                setOpen(false)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)

            } else {
            }
        } catch (error) {
        }
    };
    if (claimsList?.length == 0 && requestList?.length == 0 && returnList?.length == 0 && uninstalledList?.length == 0) {
        return (
            <EmptyPage dashboard={true} />
        )
    }

    return (
        <div style={styles.dashboardContainer}>
            <div style={styles.header}>
                <div>
                    <Card sx={{ borderRadius: 3, boxShadow: 1 }}>
                        <CardContent>
                            <Typography variant="h6">{user_data?.companyName}</Typography>
                            <Typography>{user_data?.uniqueClientId}</Typography>
                        </CardContent>
                    </Card>
                </div>
                <span style={styles.datePicker}>
                    <div style={{ ...rangePickerStyle, border: 'none', }}>
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            dateFormat="dd-MM-yyyy"
                            maxDate={new Date()}
                            customInput={
                                <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                    {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                </button>
                            }
                        />
                    </div>
                    <Backdrop
                        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </span>
            </div>

            <div style={{ display: 'flex', marginTop: '2%', gap: 20 }}>
                <div style={{ width: '50%' }}>
                    {showList(requestList, 'REQUEST')}
                </div>

                <div style={{ width: '50%' }}>
                    {showUninstallList(uninstalledList, 'UNINSTALL')}
                </div>
            </div>

            <div style={{ display: 'flex', marginTop: '2%', gap: 20 }}>
                <div style={{ width: '50%' }}>
                    {showUninstallList(returnList, 'RETURN')}
                </div>

                <div style={{ width: '50%' }}>
                    {showUninstallList(claimsList, 'CLAIM')}
                </div>
            </div>


            <div>
                {showInventoryList(inventorysList, 'INVENTORY')}
            </div>

        </div >
    );
};

const styles = {
    dashboardContainer: {
        padding: '0 2%',
        fontFamily: 'Arial, sans-serif',
        minHeight: '100vh',
    },
    header: {
        gridColumn: 'span 3',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    datePicker: {
        fontSize: '14px',
        color: '#666',
        display: 'flex',
        gap: 10
    },
    income: {
        fontSize: 35,
        margin: 0,
        fontWeight: 600
    },
    cardsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '20px',
        gridColumn: 'span 2',
    },
    updateCard: {
        backgroundColor: '#1E6D8A',
        color: '#fff',
        padding: '20px',
        borderRadius: '10px',
    },
    statCard: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
    },
    button: {
        backgroundColor: '#fff',
        color: '#004400',
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
    },
    positiveStat: {
        color: '#4caf50',
        fontSize: '12px',
    },
    negativeStat: {
        color: '#f44336',
        fontSize: '12px',
    },
    transactionContainer: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        gridColumn: 'span 1',
    },
    transactionList: {
        listStyleType: 'none',
        padding: 0,
    },
    transactionItem: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0',
        borderBottom: '1px solid #ddd',
    },
    completed: {
        color: '#4caf50',
    },
    pending: {
        color: '#ff9800',
    },
    revenueContainer: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        gridColumn: 'span 1',
    },
    salesReportContainer: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        gridColumn: 'span 1',
    },
    chart: {
        backgroundColor: '#ddd',
        height: '200px',
        borderRadius: '10px',
    },
};

export default CompanyDashComp;