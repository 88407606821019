import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, CardMedia, Box, Grid, IconButton, Checkbox, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import { formatPrice, primaryColor, primaryColorHover } from '../constant';
import { useNavigate } from 'react-router-dom';
import { serverURL } from '../api';

const ProductCard = ({ item }) => {
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const { user_data } = useSelector(state => state?.user);
    const [isHoverOnImage, setIsHoverOnImage] = useState(false)
    const navigate = useNavigate()

    const handleContextMenu = (e) => {
        e?.preventDefault();
    }

    const buttonContainer = {
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        marginTop: '5%'
    }

    const signInButton = {
        borderColor: primaryColorHover,
        color: primaryColor,
        textTransform: 'capitalize',
        padding: '3% 0',
        borderRadius: 0,
        fontWeight: 400,
        boxShadow: 'none',
        '&:hover': {
            borderColor: primaryColor,
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            borderColor: primaryColor,
        }
    }

    const getStartedButton = {
        background: primaryColor,
        color: 'white',
        padding: '3% 0',
        borderRadius: 0,
        fontWeight: 400,
        textTransform: 'capitalize',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: primaryColorHover,
            boxShadow: 'none'
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            backgroundColor: primaryColor,
        }
    }

    const handleNavigate = () => {
        let slug = item?.name?.toLowerCase()?.split(' ')?.join('-')
        navigate(`/product/${slug}/${item?.model}`)
        window.scrollTo(0, 0)
    }

    return (
        <div style={{ width: '100%' }}>
            <Card sx={{ position: 'relative', width: '100%', borderRadius: 0, cursor: 'pointer', border: '1px solid #e0e0e0', padding: 0, boxShadow: 'none' }} onClick={() => handleNavigate()}>
                <Box onMouseOver={() => setIsHoverOnImage(true)} onMouseLeave={() => setIsHoverOnImage(false)} onContextMenu={handleContextMenu} >
                    <center>
                        {item?.images && <img
                            style={{
                                width: matches_sm ? '80%' : '80%',
                                aspectRatio: '1 / 1',
                                objectFit: 'contain',
                            }}
                            src={`${serverURL}/uploads/products-images/${item?.images[0]}`}
                        />}
                        {/* <img
                            style={{
                                width: matches_sm ? '80%' : '80%',
                                aspectRatio: '1 / 1',
                                objectFit: 'contain',
                            }}
                            src={'https://5.imimg.com/data5/SELLER/Default/2020/9/FT/QQ/YS/31026576/bw09-gps-tracker-500x500.jpeg'}
                        /> */}
                    </center>
                </Box>
                <CardContent sx={{ padding: 2 }}>

                    <div style={{ display: 'flex', alignItems: 'start', height: 60 }}>
                        <Typography variant="h6" component="div" sx={{ fontWeight: '600', color: primaryColor }}>
                            {item?.name || ''}
                        </Typography>
                    </div>

                    <Typography variant="body1" component="div" sx={{ fontWeight: '600', marginTop: 0.5, fontWeight: 500 }}>
                        {formatPrice(item?.price) || '-'}
                    </Typography>

                    <div style={buttonContainer}>
                        <Button variant="outlined" sx={signInButton} onClick={() => handleNavigate()} fullWidth>View GPS</Button>
                        {/* <Button variant="contained" sx={getStartedButton} fullWidth>Request GPS</Button> */}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default ProductCard;
