import React from "react";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RiBuilding2Line } from "react-icons/ri";
import { primaryColor } from "../constant";
import Logo from "../assests/oredogpslogo.jpeg"

export default function VendorSidebar({
    dashboard_items,
    setIndex,
    open,
    setOpen
}) {

    const navigate = useNavigate();
    const { user_data } = useSelector(state => state.user);

    const user_dashboard_sidebar = {
        // background: "#F9FBFC",
        background: "white",
        width: '100%',
        height: '100vh',
        position: 'relative',
        borderRight: '1px solid gainsboro',
        top: 0,
        display: 'flex',
        flexDirection: 'column',
    };

    const logo_section = {
        padding: '4% 5% 0',
        display: "flex",
        gap: 10,
        alignItems: 'center',
    };

    const sidebar_items_div = {
        padding: '2% 4%',
    };

    const handleListItem = (item, i) => {
        if (item?.link) {
            navigate(item.link);
            window.scrollTo(0, 0);
        }
    };

    let current_path = window.location.pathname;


    const display_sidebar_items = () => {
        return (
            <List sx={{ width: '100%', maxWidth: 360 }} component="nav">
                {dashboard_items?.map((item, i) => {
                    return (
                        <div key={i}>
                            <ListItemButton
                                onClick={() => handleListItem(item, i)}
                                style={{
                                    padding: '5% 6%',
                                    borderRadius: 8,
                                    margin: '1% 0',
                                    background: item?.type !== 'dropdown' ? current_path === item.link ? '#1e6d8a19' : 'transparent' : ''
                                }}
                            >
                                <div style={{
                                    display: "flex",
                                    gap: '8%',
                                    width: '100%',
                                    color: current_path === item?.link ? primaryColor : '#757575',
                                    alignItems: item?.type !== 'dropdown' ? "center" : 'center'
                                }}>
                                    {item.icon}
                                    <p style={{ color: current_path === item?.link ? primaryColor : '#757575', fontWeight: current_path === item?.link ? 500 : 400, fontSize: 14, margin: 0, padding: 0 }}>
                                        {item?.type !== 'dropdown' ? item?.title : ''}
                                    </p>
                                    {item?.subMenu && <span style={{ marginLeft: 'auto' }}>
                                        {item?.subMenu()}
                                    </span>}
                                </div>
                            </ListItemButton>
                            {item?.collapse && item?.collapse()}
                        </div>
                    );
                })}
            </List>
        );
    };


    // const display_sidebar_items = () => {
    //     return (
    //         <List sx={{ width: '100%', maxWidth: 360 }} component="nav">
    //             {dashboard_items?.map((item, i) => {
    //                 return (
    //                     <div key={i}>
    //                         <ListItemButton
    //                             onClick={() => handleListItem(item, i)}
    //                             style={{
    //                                 padding: '5% 6%',
    //                                 borderRadius: 8,
    //                                 margin: '1% 0',
    //                                 background: item?.type !== 'dropdown' ? current_path === item.link ? '#1e6d8a19' : 'transparent' : ''
    //                             }}
    //                         >
    //                             <div style={{
    //                                 display: "flex",
    //                                 gap: '8%',
    //                                 width: '100%',
    //                                 color: current_path === item?.link ? primaryColor : '#757575',
    //                                 alignItems: item?.type !== 'dropdown' ? "center" : 'center'
    //                             }}>
    //                                 {item.icon}
    //                                 <p style={{ color: current_path === item?.link ? primaryColor : '#757575', fontWeight: current_path === item?.link ? 500 : 400, fontSize: 14, margin: 0, padding: 0 }}>
    //                                     {item?.type !== 'dropdown' ? item?.title : ''}
    //                                 </p>
    //                             </div>
    //                         </ListItemButton>
    //                     </div>
    //                 );
    //             })}
    //         </List>
    //     );
    // };

    return (
        <>
            <div style={user_dashboard_sidebar} className="sidebar-container">
                <div style={logo_section}>
                    <img src={Logo}
                        style={{ width: 60, height: 60, borderRadius: '50%', cursor: 'pointer' }}
                    />
                    <p style={{ fontSize: 18, fontWeight: 500, margin: 0, padding: 0 }}>Oredo GPS</p>
                </div>
                <div style={sidebar_items_div}>
                    {display_sidebar_items()}
                </div>
            </div >
        </>
    );
}
