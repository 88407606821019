import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Menu,
    MenuItem,
    IconButton,
    Avatar,
    Typography,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import { post_data, serverURL } from '../../api'; // Ensure serverURL is correctly imported
import { useNavigate } from 'react-router-dom';
import TableLoader from './TableLoader';
import { Toaster, toast } from 'react-hot-toast';
import { formatPrice, primaryColor } from '../../constant';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.processing': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.shipped': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.delivered': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.cancelled': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const SalesTable = ({
    data,
    tableHeader,
    getAllProducts,
    setLoading,
    totalData,
    loading,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    totalPages,
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [rowsData, setRowsData] = useState(data || [])
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    // Handle menu open
    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };
    useEffect(() => { setRowsData(data) }, [data])
    // Handle menu close
    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    // Edit product
    const handleEdit = (row) => {

        setAnchorEl(null);
        setCurrentRow(null);
    };

    // Delete product with confirmation
    const handleDelete = async (row) => {
        setAnchorEl(null);
        setCurrentRow(null);

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await post_data(`sales/delete-sales/${row?._id}`, {});
                    if (response.status === true) {
                        toast.success("Sales Deleted Successfully");
                        getAllProducts();
                    } else {
                        toast.error("Something Went Wrong");
                    }
                } catch (error) {
                    // toast.error("An error occurred while deleting the sales.");
                }
            }
        });
    };

    // Handle page change for pagination
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);



    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {tableHeader?.map((item, idx) => (
                                <TableCell key={idx} style={{ borderRight: '1px solid gainsboro', textAlign: 'center', padding: '16px 10px' }}>
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {loading ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHeader.length} align="center">
                                    <TableLoader />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {rowsData?.length > 0 ? (
                                rowsData?.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro', width: '5%' }} >{startEntry + index}</TableCell>
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro', width: '15%' }}>{row?.transactionNumber || row?.uniqueOrderId || '-'}</TableCell>
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro', width: '10%' }}>{row?.productId?.map((item) => { return (<div style={{ marginBottom: '5px' }}>{item?.uniqueProductId}</div>) }) || '-'}</TableCell>
                                        <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>{row?.userId?.name}</TableCell>
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro', width: '15%' }}>{row?.productId?.map((item) => { return (<div style={{ marginBottom: '5px' }}>{item?.name}</div>) }) || '-'}</TableCell>
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro', width: '10%' }}>{formatPrice(row?.amount) || '-'}</TableCell>
                                        <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>{row?.shippingAddress?.city + " " + row?.shippingAddress?.state}</TableCell>
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro' }}>
                                            <Button
                                                style={{ fontSize: 11, fontWeight: 500, borderRadius: 30 }}
                                                className={`${classes.stageButton} ${row?.orderStatus?.toLowerCase()?.replace(' ', '')}`}
                                            >
                                                {row?.orderStatus || '-'}
                                            </Button>
                                        </TableCell>
                                        {/* <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro' }}>
                                            <IconButton className={classes.moreIcon} onClick={(event) => handleMenuOpen(event, index)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl) && currentRow === index}
                                                onClose={handleMenuClose}
                                            >
                                                <MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>
                                                <MenuItem onClick={() => handleDelete(row)}>Delete</MenuItem>
                                            </Menu>
                                        </TableCell> */}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={tableHeader.length} align="center">
                                        <Typography>No products found.</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    )}
                </Table>

                {rowsData?.length > 0 && (
                    <div style={{ borderTop: "1px solid gainsboro", padding: "2%", display: "flex", justifyContent: "space-between" }}>
                        <Typography style={{ fontWeight: 500, fontSize: 15, color: "black" }}>
                            {`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}
                        </Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}
            </TableContainer>
        </>
    );
};

export default SalesTable;
