import { Autocomplete, Box, Button, Dialog, DialogContent, DialogContentText, Divider, FormControlLabel, FormGroup, Grid, IconButton, List, ListItem, Modal, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLocation, useNavigate } from "react-router-dom";
import { get_data, post_data } from "../api";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { City, State } from "country-state-city";
import { createButtonBg, primaryColor } from "../constant";
import Logo from "../assests/oredogpslogo.jpeg"
import { Delete } from "@mui/icons-material";
import Swal from "sweetalert2";

export default function RequestDetails({ requestData }) {
    const navigate = useNavigate();
    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const location = useLocation();
    const requests = location?.state?.row || requestData;
    const [toggleGpsStatus, setToggleGpsStatus] = useState([])
    const [reason, setReason] = useState('');
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countryCode] = useState('IN');
    const [errors, setErrors] = useState({});
    const [deviceModelList, setDeviceModelList] = useState([]);
    const [driverCityList, setDriverCityList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [index, setIndex] = useState(0);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [deviceUniqueIdList, setDeviceUniqueIdList] = useState([]);
    const [sno, setSno] = useState(0);

    const fetchAllDeviceUniqueIds = async () => {
        let result = await get_data(`inventory/get-all-stock-gps`)
        if (result?.status === true) {
            setDeviceUniqueIdList(result?.data)
        }
    }

    const [formData, setFormData] = useState({
        clientId: user_data?._id || requests?.clientId || '',
        customerDetails: {
            companyName: requests?.clientId?.companyName || '',
            contactNo: requests?.clientId?.contactNo || '',
            resContactNo: requests?.clientId?.resContactNo || '',
            address: {
                street: requests?.clientId?.address?.street || '',
                landmark: requests?.clientId?.address?.landmark || '',
                city: requests?.clientId?.address?.city || '',
                state: requests?.clientId?.address?.state || '',
                zipCode: requests?.clientId?.address?.zipCode || '',
            },
        },
        gps: requests?.gps?.map((gpsData) => ({
            deviceDetails: {
                network: {
                    airtel: gpsData?.deviceDetails?.network?.airtel || false,
                    vi: gpsData?.deviceDetails?.network?.vi || false,
                    bsnl: gpsData?.deviceDetails?.network?.bsnl || false,
                    jio: gpsData?.deviceDetails?.network?.jio || false,
                    none: gpsData?.deviceDetails?.network?.none || false,
                },
                imeiNo: gpsData?.deviceDetails?.imeiNo || '',
                simNo: gpsData?.deviceDetails?.simNo || '',
                uniqueId: gpsData?.deviceDetails?.uniqueId || '',
                panicButton: gpsData?.deviceDetails?.panicButton || false,
                relay: gpsData?.deviceDetails?.relay || false,
            },
            driverDetails: {
                fullName: gpsData?.driverDetails?.fullName || '',
                pan: gpsData?.driverDetails?.pan || '',
                aadhar: gpsData?.driverDetails?.aadhar || '',
                badgeNo: gpsData?.driverDetails?.badgeNo || '',
                shift: gpsData?.driverDetails?.shift || '',
                contactNo: gpsData?.driverDetails?.contactNo || '',
                resContactNo: gpsData?.driverDetails?.resContactNo || '',
                address: {
                    street: gpsData?.driverDetails?.address?.street || '',
                    zipCode: gpsData?.driverDetails?.address?.zipCode || '',
                    state: gpsData?.driverDetails?.address?.state || '',
                    city: gpsData?.driverDetails?.address?.city || '',
                },
                fatherName: gpsData?.driverDetails?.fatherName || '',
            },
            vehicleDetails: {
                regnNumber: gpsData?.vehicleDetails?.regnNumber || '',
                plate: gpsData?.vehicleDetails?.plate || '',
                regnOwnerName: gpsData?.vehicleDetails?.regnOwnerName || '',
                typeOfVehicle: gpsData?.vehicleDetails?.typeOfVehicle || '',
                vehicleClass: gpsData?.vehicleDetails?.vehicleClass || '',
                chassisNumber: gpsData?.vehicleDetails?.chassisNumber || '',
            },
            gpsStatus: gpsData?.gpsStatus || '',
        })),
        installerDetails: {
            name: requests?.installerDetails?.name || '',
            Phone: requests?.installerDetails?.phone || '',
        },
        isVendorRequest: requests?.isVendorRequest,
    });


    const handleClose = () => {
        setReason('');
        setOpen(false);
    }

    useEffect(function () {
        setToggleGpsStatus((prev) => {
            let updatedGpsStatus = requests?.gps?.map((item, idx) => {
                if (!item?.reason) {
                    return [...prev, false]
                }
                return [...prev, item?.gpsStatus]
            })
            return updatedGpsStatus
        });
        fetchAllDeviceUniqueIds()
    }, [requests])

    const getTrueNetworkProvider = (network) => {
        const activeProviders = Object.keys(network).filter((provider) => network[provider]);
        return activeProviders.length > 0 ? activeProviders.join(', ') : 'None'; // Display comma-separated if multiple
    };

    const getTrueOtherDetails = (details) => {
        const activeDetails = Object.keys(details).filter((key) => details[key]);
        return activeDetails.length > 0 ? activeDetails.join(', ') : 'None'; // Display comma-separated if multiple
    };

    // const handleChange = async (value, index) => {
    //     if (value === "Not Installed") {
    //         setOpen(true);
    //         setSno(index);
    //         return;
    //     }

    //     let result = await post_data(`request/change-gps-status/${requests?._id}`,
    //         {
    //             index: index,
    //             gpsStatus: value === "Installed" ? "Installed" : "Not Installed",
    //             reason: reason
    //         });

    //     if (result?.status == true) {
    //         toast.success(result?.message)

    //         setToggleGpsStatus((prev) => {
    //             let updatedGpsStatus = prev?.map((item, idx) => {
    //                 if (index == idx) {
    //                     return [
    //                         value === "Installed" ? "Installed" : "Not Installed"
    //                     ]
    //                 }
    //                 return item
    //             })
    //             return updatedGpsStatus
    //         })

    //         handleClose()

    //     } else {
    //         toast.error(result?.message)
    //     }
    // }

    useEffect(() => {
        setStateList(State.getStatesOfCountry(countryCode));
        requests?.gps?.map((gpsData) => {
            if (gpsData?.driverDetails?.address?.state) {
                setDriverCityList(City.getCitiesOfState(countryCode, gpsData?.driverDetails?.address?.state));
            }
        })
    }, [countryCode]);

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const fetchedCities = City.getCitiesOfState(countryCode, newValue.isoCode);

            setFormData(prevState => ({
                ...prevState,
                customerDetails: {
                    ...prevState.customerDetails,
                    address: {
                        ...prevState.customerDetails.address,
                        state: stateCode,
                        city: ''
                    }
                }
            }));

            setCityList(fetchedCities);
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setFormData(prevState => ({
                ...prevState,
                customerDetails: {
                    ...prevState.customerDetails,
                    address: {
                        ...prevState.customerDetails.address,
                        city: newValue.name
                    }
                }
            }));
        }
    };

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const handleDeviceInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    const newDeviceInfo = { ...form.deviceDetails, [field]: value };

                    // Automatically set IMEI No if deviceModel is changed
                    if (field === 'deviceModel') {
                        const imeiNo = fetchIMEINo(value);
                        newDeviceInfo.imeiNo = imeiNo ? imeiNo : ''; // Auto-fill IMEI if available
                    }

                    return { ...form, deviceDetails: newDeviceInfo };
                }
                return form;
            });

            return { ...prevState, gps: updatedGps };
        });
    };

    // Fetch device model list
    const fetchdeviceModelList = async () => {
        try {
            const data = await get_data(`product/get-all-products-for-options`);
            setDeviceModelList(data?.data || []);
        } catch (error) {
            console.log('Error fetching device model list:', error);
        }
    };

    // Fetch IMEI based on selected device model
    const fetchIMEINo = (deviceModel) => {
        const device = deviceModelList.find((d) => d._id === deviceModel);
        return device ? device.imeiNo : '';
    };

    useEffect(() => {
        fetchdeviceModelList();
    }, []);

    const handleDeviceSwitchChange = (index, provider) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    // Set the network as the selected provider's name as a string
                    return { ...form, deviceDetails: { ...form.deviceDetails, network: { [provider.toLowerCase()]: true } } };
                }
                return form;
            });

            return { ...prevState, gps: updatedGps };
        });
    };
    const handleDeviceSwitchChange2 = (index, provider) => {
        setFormData(prevState => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    let updatedOtherDetails = form.deviceDetails.otherDetails === provider
                        ? ''  // Deselect if the same provider is clicked again
                        : provider; // Set the selected provider

                    updatedOtherDetails = updatedOtherDetails.toLowerCase();

                    return { ...form, deviceDetails: { ...form.deviceDetails, otherDetails: { [updatedOtherDetails]: true } } };
                }
                return form;
            });

            return { ...prevState, gps: updatedGps, };
        });
    };

    const handleDriverInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    if (['street', 'landmark', 'city', 'state', 'zipCode'].includes(field)) {
                        // Update address inside driverDetails
                        return {
                            ...form, driverDetails: { ...form.driverDetails, address: { ...form.driverDetails.address, [field]: value, } },
                        };
                    } else {

                        return {
                            ...form, driverDetails: { ...form.driverDetails, [field]: value }
                        };
                    }
                }
                return form; // Return unchanged form for other indices
            });
            return { ...prevState, gps: updatedGps, };
        });
    };

    console.log('formData', formData)

    const handleDriverStateChange = (index, event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const countryCode = 'IN'; // Replace with actual country code

            // Fetch cities based on selected state
            const fetchedCities = City.getCitiesOfState(countryCode, stateCode);
            setDriverCityList(fetchedCities); // Update city list based on state

            setFormData(prevState => {
                const updatedGps = prevState.gps.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            driverDetails: {
                                ...form.driverDetails,
                                address: {
                                    ...form.driverDetails.address,
                                    state: stateCode,
                                }
                            },
                        };
                    }
                    return form; // Return unchanged form for other indices
                });

                return {
                    ...prevState,
                    gps: updatedGps,
                };
            });
        }
    };

    // Handle city change
    const handleDriverCityChange = (index, event, newValue) => {
        console.log('city', newValue)
        if (newValue) {
            setFormData(prevState => {
                const updatedGps = prevState.gps.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            driverDetails: {
                                ...form.driverDetails,
                                address: {
                                    ...form.driverDetails.address,
                                    city: newValue?.name,
                                }
                            },
                        };
                    }
                    return form; // Return unchanged form for other indices
                });

                return {
                    ...prevState,
                    gps: updatedGps,
                };
            });
        }
    };

    const handleVehicleInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    // Update the vehicleDetails field dynamically
                    return {
                        ...form,
                        vehicleDetails: {
                            ...form.vehicleDetails,
                            [field]: value, // Dynamically update the vehicle field
                        },
                    };
                }
                return form; // Return unchanged form for other indices
            });

            return {
                ...prevState,
                gps: updatedGps, // Update the gps array in formData
            };
        });

        if (field == 'brand') {
            getAllModels(index, value)
        }
    };

    const getAllModels = async (index, value) => {
        let result = await get_data(`model/get-all-model-by-brand/${value}`)
        if (result?.status == true) {

            setModelList((prev) => {
                let updatedModelList = formData?.gps?.map((item, idx) => {
                    if (index == idx) {
                        return result?.data
                    }
                    return modelList?.[idx]
                })
                return updatedModelList
            })
        } else {
            toast.error(result?.message)
        }
    }

    const handleInstallerInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    return {
                        ...form,
                        installerDetails: {
                            ...form.installerDetails,
                            [field]: value,
                        },
                    };
                }
                return form;
            });
            return { ...prevState, gps: updatedGps };
        });
    }

    const handleEdit = async (event) => {
        event.preventDefault();
        setLoader(true)
        const respons = await post_data(`request/update-request/${requests?._id}`, { ...formData, installerDetails: formData?.installerDetails?._id })
        if (respons.status) {
            toast.success('Request Details Updated Successfully')
        }
        else {
            toast.success('Something Went Wrong')
        }
        setLoader(false)
    }
    console.log("00", requestData);

    const handleDownloadPdf = async () => {
        const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: "a4",
        });

        const pageWidth = 210;
        const pageHeight = 297;
        const margin = 10;
        const contentWidth = pageWidth - 2 * margin;
        const contentHeight = pageHeight - 2 * margin;

        // Add company logo (replace 'logoUrl' with your actual base64 image or URL)
        const logoUrl = Logo; // Replace with your base64 string or image URL
        const logoWidth = 10; // Set the width of the logo
        const logoHeight = 10; // Set the height of the logo

        const renderHeader = (pdf, positionY) => {
            // Add logo to header (aligned to the left)
            pdf.addImage(logoUrl, 'PNG', margin, positionY, logoWidth, logoHeight);

            // Set the starting position for text next to the logo
            let textX = margin + logoWidth + 5; // Adjust text position to the right of the logo

            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(14);
            pdf.text("Oredo GPS Solutions Private Limited", textX, positionY + 5);

            // Add the address next to the company name
            pdf.setFontSize(10);
            pdf.setFont("helvetica", "normal");
            pdf.text("6-67, Yerrakunta, Chandrayangutta, Hyderabad, Telangana 50000 / support@oredogps.com | +91 8008379717", textX, positionY + 10);

            positionY += logoHeight + 5;

            pdf.setLineWidth(0.5);
            pdf.line(margin, positionY, pageWidth - margin, positionY);

            return positionY + 5;
        };

        const renderCustomerDetails = (pdf, positionY) => {
            const customerDetails = [
                ["Request ID", requests?.uniqueRequestId || "-"],
                ["Company Name", requests?.customerDetails?.companyName || "-"],
                ["State", requests?.customerDetails?.address?.state || "-"],
                ["City", requests?.customerDetails?.address?.city || "-"],
                ["Zip Code", requests?.customerDetails?.address?.zipCode || "-"],
            ];

            pdf.autoTable({
                startY: positionY,
                head: [["Field", "Value"]],
                body: customerDetails,
                theme: "striped",
                styles: { fontSize: 9, cellPadding: 2 },
                columnStyles: {
                    0: { cellWidth: 80 },
                    1: { cellWidth: contentWidth - 80 },
                },
            });

            return pdf.lastAutoTable.finalY + 10;
        };


        function formatDates(dateString) {
            const date = new Date(dateString);
            if (isNaN(date)) return 'N/A'; 
            return date.toLocaleDateString('en-US');
        }

        const renderGpsDetails = (pdf, gpsData, positionY) => {
            const gpsDetails = [
                ["GPS Status", `${gpsData?.gpsStatus || "Requested"}`],
                ["Date", `Request Date: ${formatDates(requests?.createdAt)},Install Date:  ${formatDates(gpsData.DateOfInstall)},Uninstall Date: ${formatDates(gpsData.DateOfUninstall)}` || "-"],
                ["Registration Number", gpsData?.vehicleDetails?.regnNumber || "N/A"],
                ["Device Unique ID", gpsData?.deviceDetails?.uniqueId || "N/A"],
                ["IMEI Number", gpsData?.deviceDetails?.imeiNo || "N/A"],
                ["SIM Number", gpsData?.deviceDetails?.simNo || "N/A"],
                ["Device Information", `Relay: ${gpsData?.deviceDetails?.relay ? "Enabled" : "Disabled"}, Network: ${Object.keys(gpsData?.deviceDetails?.network).filter(key => gpsData.deviceDetails.network[key]).join(", ") || "None"}`],
                ["Driver Name", `${gpsData?.driverDetails?.fullName || "N/A"} s/o ${gpsData?.driverDetails?.fatherName || "N/A"}` || "N/A"],
                ["Driver Contact", `${gpsData?.driverDetails?.phone || "N/A"}`],
                ["Driver Information", `Aadhaar: ${gpsData?.driverDetails?.aadhar || "N/A"}, PAN: ${gpsData?.driverDetails?.pan || "N/A"}, Badge: ${gpsData?.driverDetails?.badgeNumber || "N/A"}, Shift: ${gpsData?.driverDetails?.shift || "N/A"}`],
                ["Vehicle Owner", gpsData?.vehicleDetails?.regnOwnerName || "N/A"],
                ["Vehicle Details", `Plate: ${gpsData?.vehicleDetails?.plate || "N/A"}, Chassis: ${gpsData?.vehicleDetails?.chassisNumber || "N/A"}`],
            ];

            pdf.autoTable({
                startY: positionY,
                head: [["Field", "Details"]],
                body: gpsDetails,
                theme: "grid",
                styles: { fontSize: 9, cellPadding: 2 },
                columnStyles: {
                    0: { cellWidth: 90 },
                    1: { cellWidth: contentWidth - 90 },
                },
            });

            return pdf.lastAutoTable.finalY + 10;
        };

        const renderInstallerDetails = (pdf, positionY) => {
            const installerDetails = [
                ["Installer Name", requests?.installerDetails?.name || "-"],
                ["Installer Phone", requests?.installerDetails?.phone || "-"],
            ];

            pdf.autoTable({
                startY: positionY,
                head: [["Field", "Value"]],
                body: installerDetails,
                theme: "striped",
                styles: { fontSize: 9, cellPadding: 2 },
                columnStyles: {
                    0: { cellWidth: 80 },
                    1: { cellWidth: contentWidth - 80 },
                },
            });

            return pdf.lastAutoTable.finalY + 10;
        };

        let positionY = renderHeader(pdf, margin);

        // Render request details on the first page
        positionY = renderCustomerDetails(pdf, positionY);

        // Iterate through GPS details, each GPS on a separate page
        requests.gps.forEach((gpsData, index) => {
            if (index > 0) {
                pdf.addPage(); // Add new page for each GPS
                positionY = renderHeader(pdf, margin);
            }

            positionY = renderGpsDetails(pdf, gpsData, positionY);
        });

        // Render installer details on the same page as the last GPS details
        positionY = renderInstallerDetails(pdf, positionY);

        // Save PDF
        pdf.save("Request.pdf");
    };

    const handleDelete = async (index) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`request/cancel-request/${requests?._id}`, { index })
                    if (data.status) {
                        toast.success("Request Deleted Successfully");
                        navigate('/company/dashboard/request')
                    }
                    else {
                        toast.error("Something Went Wrong");
                    }
                } catch (error) {
                }
            }
        });

    };

    return (
        <div style={{ background: 'white', padding: 20, borderRadius: '5px' }}>
            <Toaster position="top-right" reverseOrder={false} />

            <ListItem>
                <Button style={{
                    backgroundColor: primaryColor,
                    color: '#ffff',
                    cursor: 'pointer',
                    boxShadow: 'none',
                    marginRight: 10

                }}
                    variant="contained"
                    onClick={handleDownloadPdf}
                >
                    Download PDF
                </Button>

                <Button style={{
                    backgroundColor: primaryColor,
                    color: '#ffff',
                    cursor: 'pointer',
                    boxShadow: 'none'
                }}
                    variant="contained"
                    onClick={handleEdit}
                >
                    {loader ? 'Updating...' : 'Update'}
                </Button>
            </ListItem>
            {/* Customer Details */}
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                <List sx={{ border: '1px solid gainsboro' }}>
                    <React.Fragment >
                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Request ID  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                {formData?.customerDetails?.uniqueRequestId || requests?.uniqueRequestId}
                            </div>
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Company Name  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                {formData.customerDetails.companyName}
                            </div>
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  State  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                <div style={{ width: '60%', }}>
                                    {formData?.customerDetails?.address?.state}
                                </div>
                            </div>
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  City  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                <div style={{ width: '60%', }}>
                                    {formData?.customerDetails?.address?.city}
                                </div>
                            </div>
                        </ListItem>
                        <Divider />


                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Street  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                {formData?.customerDetails?.address?.street}
                            </div>
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Landmark  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                {formData?.customerDetails?.address?.landmark}
                            </div>
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Zip Code  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                {formData?.customerDetails?.address?.zipCode}
                            </div>
                        </ListItem>
                        <Divider />



                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Contact No  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                {formData.customerDetails.contactNo}
                            </div>
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Residential Contact No  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                {formData.customerDetails.resContactNo}
                            </div>
                        </ListItem>
                    </React.Fragment>
                </List>
            </Typography>

            <Typography variant='h6' style={{ margin: '10px 0px' }}>Gps Details</Typography>
            {requests?.gps?.map((item, index) => {
                return (<div style={{ marginTop: 10, gap: '10px', width: '100%', display: 'flex' }}>
                    <Accordion style={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>Gps {index + 1}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='h6' style={{ margin: '10px 0px' }}>Device Details</Typography>

                            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                <List sx={{ border: '1px solid gainsboro' }}>
                                    <React.Fragment >
                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Gps Status  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                {formData?.gps[index]?.gpsStatus || 'Requested'}
                                            </div>
                                        </ListItem>
                                        <Divider />


                                        {
                                            formData?.isVendorRequest === false && !deviceUniqueIdList.find(s => s?.gpsDetail?.deviceUniqueId == formData?.gps[index]?.deviceDetails?.uniqueId) && formData?.gps[index]?.gpsStatus != "Installed"
                                            &&
                                            <>
                                                <ListItem>
                                                    <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Requested Device Unique ID  </div>

                                                    <div style={{ width: '60%', marginLeft: '10px' }}>
                                                        {formData?.gps[index]?.deviceDetails?.uniqueId || ''}
                                                        <div style={{ color: 'red' }}>This Gps currently not exists in company stock, Choose new device to Install !</div>
                                                    </div>
                                                </ListItem>
                                                <Divider />
                                            </>
                                        }

                                        {
                                            formData?.isVendorRequest === true || formData?.gps[index]?.gpsStatus == "Installed" ?
                                                <>
                                                    <ListItem>
                                                        <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Device Unique ID  </div>
                                                        <div style={{ width: '60%', marginLeft: '10px' }}>
                                                            {formData?.gps[index]?.deviceDetails?.uniqueId || ''}
                                                        </div>
                                                    </ListItem>
                                                    <Divider />
                                                </>
                                                :
                                                <></>
                                        }


                                        {
                                            formData?.isVendorRequest === false && formData?.gps[index]?.gpsStatus != "Installed" &&
                                            <>
                                                <ListItem>
                                                    <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Device Unique ID  </div>
                                                    <div style={{ width: '60%', marginLeft: '10px' }}>
                                                        <Autocomplete
                                                            id="device-unique-id-select"
                                                            options={deviceUniqueIdList}
                                                            value={deviceUniqueIdList.find(s => s?.gpsDetail?.deviceUniqueId == formData?.gps[index]?.deviceDetails?.uniqueId || '') || null}
                                                            onChange={(event, newValue) => {
                                                                handleDeviceInputChange(index, 'uniqueId', newValue?.gpsDetail?.deviceUniqueId)
                                                                handleDeviceInputChange(index, 'imeiNo', newValue?.gpsDetail?.imei)
                                                                handleDeviceInputChange(index, 'simNo', newValue?.gpsDetail?.simNumber)
                                                            }}
                                                            getOptionLabel={(option) => option?.gpsDetail?.deviceUniqueId || ''}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Choose New Device Unique Id"
                                                                    fullWidth
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </ListItem>
                                                <Divider />
                                            </>
                                        }

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>IMEI NO  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text'
                                                    value={formData.gps[index]?.deviceDetails?.imeiNo || item?.deviceDetails?.imeiNo}
                                                    disabled
                                                    placeholder='imeiNo'
                                                    style={{
                                                        width: "100%",
                                                        color: "#666666"
                                                    }}

                                                // onChange={(e) => handleDeviceInputChange(index, 'imeiNo', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  SIM No  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text'
                                                    disabled
                                                    value={formData.gps[index]?.deviceDetails?.simNo || item?.deviceDetails?.simNo} placeholder='simNo' style={{
                                                        width: "100%",
                                                        color: "#666666"
                                                    }}
                                                // onChange={(e) => handleDeviceInputChange(index, 'simNo', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        {/* <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Network </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <FormGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    {['Airtel', 'Vi', 'BSNL', 'Jio', 'None'].map((provider) => (
                                                        <FormControlLabel
                                                            key={provider}
                                                            control={
                                                                <Switch
                                                                    error={!!errors.network}
                                                                    onFocus={() => handleError('', 'network')}
                                                                    checked={
                                                                        formData.gps[index]?.deviceDetails?.network[provider.toLowerCase()] === true
                                                                    }
                                                                    onChange={() => handleDeviceSwitchChange(index, provider)} // Pass index and provider
                                                                />
                                                            }
                                                            label={provider}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </div>
                                        </ListItem>
                                        <Divider /> */}

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Network </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                {[Object.keys(item?.deviceDetails?.network).filter(key => item?.deviceDetails?.network[key]).join(", ") || "None"]}
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Relay </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                {item?.deviceDetails?.relay ? "Enabled" : "Disabled"}
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Panic Button </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                {item?.deviceDetails?.panicButton ? "Enabled" : "Disabled"}
                                            </div>
                                        </ListItem>
                                        <Divider />


                                        {/* <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Relay </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <FormControlLabel
                                                    key={'relay'}
                                                    control={
                                                        <Switch
                                                            error={!!errors.relay}
                                                            onFocus={() => handleError('', 'relay')}
                                                            checked={formData.gps[index]?.deviceDetails?.relay === true || item?.deviceDetails?.relay === true}
                                                            onChange={() => setFormData((prevState) => {
                                                                const updatedGps = prevState.gps.map((form, idx) => {

                                                                    if (index === idx) {
                                                                        let dataForUpdate = (formData.gps[index]?.deviceDetails?.relay === true || item?.deviceDetails?.relay === true) ? false : true
                                                                        return { ...form, deviceDetails: { ...form.deviceDetails, relay: dataForUpdate } };
                                                                    }
                                                                    return form;
                                                                });
                                                                return { ...prevState, gps: updatedGps };
                                                            })} // Pass index and provider
                                                        />
                                                    }
                                                //    label={'relay'}
                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Panic Button</div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <FormControlLabel

                                                    key={'panicButton'}
                                                    control={
                                                        <Switch
                                                            error={!!errors.panicButton}
                                                            onFocus={() => handleError('', 'panicButton')}
                                                            checked={formData.gps[index]?.deviceDetails?.panicButton === true || item?.deviceDetails?.panicButton === true}
                                                            onChange={() => setFormData((prevState) => {
                                                                const updatedGps = prevState.gps.map((form, idx) => {

                                                                    if (index === idx) {
                                                                        let dataForUpdate = (formData.gps[index]?.deviceDetails?.panicButton === true || item?.deviceDetails?.panicButton === true) ? false : true
                                                                        return { ...form, deviceDetails: { ...form.deviceDetails, panicButton: dataForUpdate } };
                                                                    }
                                                                    return form;
                                                                });
                                                                return { ...prevState, gps: updatedGps };
                                                            })} // Pass index and provider
                                                        />
                                                    }
                                                //    label={'relay'}
                                                />
                                            </div>
                                        </ListItem>
                                        <Divider /> */}
                                    </React.Fragment>
                                </List>
                            </Typography>

                            <Typography variant='h6' style={{ margin: '10px 0px' }}>Driver Details</Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                <List sx={{ border: '1px solid gainsboro' }}>
                                    <React.Fragment >
                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Full Name   </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.driverDetails?.fullName || ''} placeholder='fullName'
                                                    name="fullName"
                                                    style={{
                                                        width: "100%",
                                                        color: "#666666"
                                                    }}
                                                    onChange={(e) => handleDriverInputChange(index, 'fullName', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Father Name </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.driverDetails?.fatherName || item?.driverDetails?.fatherName} placeholder='fatherName'
                                                    name="fatherName"
                                                    style={{
                                                        width: "100%",
                                                        color: "#666666"
                                                    }}
                                                    onChange={(e) => handleDriverInputChange(index, 'fatherName', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Contact No </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input value={formData.gps[index]?.driverDetails?.contactNo || item?.driverDetails?.contactNo} placeholder='contactNo'
                                                    name="contactNo"
                                                    style={{
                                                        width: "100%",
                                                        color: "#666666"
                                                    }}

                                                    onChange={(e) => handleDriverInputChange(index, 'contactNo', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Residential Contact No  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>

                                                <input value={formData.gps[index]?.driverDetails?.resContactNo || item?.driverDetails?.resContactNo} placeholder='resContactNo'
                                                    name="resContactNo"
                                                    style={{
                                                        width: "100%",
                                                        color: "#666666"
                                                    }}

                                                    onChange={(e) => handleDriverInputChange(index, 'resContactNo', e.target.value)}

                                                /> </div>
                                        </ListItem>
                                        <Divider />

                                        {/* <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> address </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}> {item?.driverDetails?.address?.street + ' ' + item?.driverDetails?.address?.landmark + ' ' + item?.driverDetails?.address?.state + ' ' + item?.driverDetails?.address?.city + ' ' + item?.driverDetails?.address?.zipCode || '-'}</div>
                                        </ListItem>
                                        <Divider /> */}

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Street  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.driverDetails?.address?.street || item?.driverDetails?.address?.street} placeholder='street'
                                                    style={{
                                                        width: "100%",
                                                        color: "#666666"
                                                    }}
                                                    onChange={(e) => handleDriverInputChange(index, 'street', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Landmark  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.driverDetails?.address?.landmark || item?.driverDetails?.address?.landmark} placeholder='landmark'
                                                    style={{
                                                        width: "100%",
                                                        color: "#666666"
                                                    }}
                                                    onChange={(e) => handleDriverInputChange(index, 'landmark', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Zip Code  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.driverDetails?.address?.zipCode || item?.driverDetails?.address?.zipCode} placeholder='zipCode'
                                                    style={{
                                                        width: "100%",
                                                        color: "#666666"
                                                    }}

                                                    onChange={(e) => handleDriverInputChange(index, 'zipCode', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  State  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <Autocomplete
                                                    id="state-select"
                                                    options={stateList}
                                                    value={stateList.find(s => s.isoCode === (formData?.gps?.[index]?.driverDetails?.address?.state)) || null}
                                                    onChange={(event, newValue) => handleDriverStateChange(index, event, newValue)}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            error={!!errors.driverState}
                                                            helperText={errors.driverState}
                                                            onFocus={() => handleError('', 'state')}
                                                            {...params}
                                                            label="Select State"
                                                            fullWidth
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  City  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <Autocomplete
                                                    id="city-select"
                                                    options={driverCityList}
                                                    value={driverCityList.find(c => c.name === (formData?.gps?.[index]?.driverDetails?.address?.city)) || null}
                                                    onChange={(event, newValue) => handleDriverCityChange(index, event, newValue)}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={!!errors.driverCity}
                                                            helperText={errors.driverCity}
                                                            onFocus={() => handleError('', 'driverCity')}
                                                            label="Select City"
                                                            fullWidth
                                                            variant="standard"
                                                        />
                                                    )}
                                                />

                                            </div>
                                        </ListItem>


                                    </React.Fragment>
                                </List>
                            </Typography>


                            <Typography variant='h6' style={{ margin: '10px 0px' }}>Vehicle Details</Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                <List sx={{ border: '1px solid gainsboro' }}>
                                    <React.Fragment >
                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Owner Name </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.vehicleDetails?.regnOwnerName || item?.vehicleDetails?.regnOwnerName} placeholder='regnOwnerName'
                                                    name="regnOwnerName"
                                                    style={{
                                                        width: '100%',
                                                        color: "#666666"
                                                    }}

                                                    onChange={(e) => handleVehicleInputChange(index, 'regnOwnerName', e.target.value)}
                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Registration Number   </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.vehicleDetails?.regnNumber || item?.vehicleDetails?.regnNumber} placeholder='regnNumber'
                                                    name="regnNumber"
                                                    style={{
                                                        width: '100%',
                                                        color: "#666666"
                                                    }}

                                                    onChange={(e) => handleVehicleInputChange(index, 'regnNumber', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Plate </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.vehicleDetails?.plate || item?.vehicleDetails?.plate} placeholder='plate'
                                                    name="plate"
                                                    style={{
                                                        width: '100%',
                                                        color: "#666666"
                                                    }}

                                                    onChange={(e) => handleVehicleInputChange(index, 'plate', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Type Of Vehicle</div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.vehicleDetails?.typeOfVehicle || item?.vehicleDetails?.typeOfVehicle} placeholder='typeOfVehicle'
                                                    name="typeOfVehicle"
                                                    style={{
                                                        width: '100%',
                                                        color: "#666666"
                                                    }}

                                                    onChange={(e) => handleVehicleInputChange(index, 'typeOfVehicle', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> Vehicle Class </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.vehicleDetails?.vehicleClass || item?.vehicleDetails?.vehicleClass} placeholder='vehicleClass'
                                                    name="vehicleClass"
                                                    style={{
                                                        width: '100%',
                                                        color: "#666666"
                                                    }}

                                                    onChange={(e) => handleVehicleInputChange(index, 'vehicleClass', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> Chassis Number </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input
                                                    type='text'
                                                    label="Chassis Number"
                                                    name="chassisNumber"
                                                    style={{
                                                        width: '100%',
                                                        color: "#666666"
                                                    }}
                                                    value={formData.gps[index]?.vehicleDetails?.chassisNumber}
                                                    onChange={(e) => handleVehicleInputChange(index, 'chassisNumber', e.target.value)}
                                                    fullWidth
                                                />
                                            </div>
                                        </ListItem>
                                    </React.Fragment>
                                </List>
                            </Typography>


                        </AccordionDetails>
                    </Accordion>
                    {formData?.gps[index]?.gpsStatus == ''
                        ?

                        <IconButton
                            onClick={() => handleDelete(index)}
                            size="small"
                            sx={{ ml: 2, width: 40, height: 40 }}
                        >
                            <Delete fontSize="small" sx={{ color: 'red' }} />
                        </IconButton>
                        :
                        <></>
                    }
                    {/* <ToggleButtonGroup
                        color="primary"
                        value={toggleGpsStatus?.[index]}
                        exclusive
                        // onChange={(e) => { handleChange(e.target.value, index) }}
                        aria-label="Platform"
                        style={{ marginLeft: 'auto', height: '50px' }}
                    >
                        <ToggleButton value="Installed" style={{ fontSize: '.7rem', background: item.gpsStatus === 'Installed' ? primaryColor : '' }}>Installed</ToggleButton>
                        <ToggleButton value="Not Installed" style={{ fontSize: '.7rem',background: item.gpsStatus === 'Not Installed' ? primaryColor : '' }}>Not Installed</ToggleButton>
                    </ToggleButtonGroup> */}

                    <Modal
                        open={open}
                        onClose={handleClose}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: '10px'
                            }}
                        >
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Reason"
                                multiline
                                maxRows={4}
                                value={reason}
                                // onChange={(e) => setReason(e.target.value)}
                                fullWidth
                            />
                            {/* <Button variant="contained"
                                fullWidth
                                style={{ marginTop: '10px', borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                                onClick={() => handleChange("Not Installeded", sno)}>Submit</Button> */}
                        </Box>
                    </Modal>
                </div>
                )
            })}

            <Typography variant='h6' style={{ margin: '10px 0px' }}>Installer Details</Typography>
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                <List sx={{ border: '1px solid gainsboro' }}>
                    <React.Fragment >
                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Name </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                {formData.gps[index]?.installerDetails?.name || requests?.installerDetails?.name}
                            </div>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Contact No   </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                {formData.gps[index]?.installerDetails?.phone || requests?.installerDetails?.phone}
                            </div>
                        </ListItem>
                    </React.Fragment>
                </List>
            </Typography>


            {/* <Grid item xs={12} sm={6}>
                                {index > 0 && <Button onClick={() => removeGpsForm(index)}
                                    variant="outlined"
                                    style={{
                                        borderColor: primaryColor,
                                        color: primaryColor,
                                        border: `2px solid ${primaryColor}`,
                                        textTransform: 'capitalize',
                                        padding: '1% 3.5%',
                                        textAlign: 'center',
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                        marginRight: "10px"
                                    }}
                                >
                                    Remove
                                </Button>}
                                <Button onClick={addForm} variant="contained" style={buttonStyles}>
                                    Add
                                </Button>
                            </Grid> */}

        </div>
    );
}
