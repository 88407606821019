import { Box, Button, IconButton, Typography, useMediaQuery } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { formatPrice, primaryColor, primaryColorHover } from "../constant";
import { serverURL } from "../api";
function BottomCart({ product, totalPrice, qty, setQty, handleCheckout, handleAddToCart }) {
    const matches = useMediaQuery('(max-width:800px)')

    const styles = {
        buyButton: {
            width: matches ? '100%' : '30%',
            background: primaryColor,
            color: 'white',
            borderRadius: 0,
            fontWeight: 400,
            textTransform: 'capitalize',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: primaryColorHover,
                boxShadow: 'none'
            },
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
                backgroundColor: primaryColor,
            }
        },
        cartButton: {
            width: matches ? '50%' : '20%',
            borderColor: primaryColor,
            color: primaryColor,
            textTransform: 'capitalize',
            fontWeight: 400,
            boxShadow: 'none',
            borderRadius: matches ? 0 : 2,
            '&:hover': {
                borderColor: primaryColor,
                boxShadow: 'none',
            },
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
                borderColor: primaryColor,
            }
        },
    }

    return (
        <div style={{ width: '100%', height: matches ? '' : '10vh', color: 'black', background: 'white', display: 'flex', alignItems: 'center', boxShadow: '0 6px 20px 0 gray' }}>
            {matches ? <></>
                : <div style={{ width: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5%' }}>
                    <img src={`${serverURL}/uploads/products-images/${product?.images[0]}`} height="50%" width="50%" alt="BottomCart product"></img>
                </div>
            }

            {matches ? <></>
                : <div style={{ width: '40%' }}>

                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%', fontSize: '14px' }}>{product?.brand} {product?.name}</div>
                    <div>{formatPrice(totalPrice)}</div>
                </div>}

            {matches ? <></>
                :
                <div style={{ width: '45%', display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    {
                        qty === 0 ?
                            <Button variant="contained" sx={styles.buyButton} onClick={handleAddToCart}>Add to Cart</Button>
                            :
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        border: '1px solid #ccc',
                                        borderRadius: '8px',
                                        padding: '8px',
                                        width: 'fit-content',
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <IconButton onClick={() => setQty(qty + 1)} color="primary">
                                        <AddIcon />
                                    </IconButton>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                        {qty}
                                    </Typography>
                                    <IconButton onClick={() => {
                                        if (qty > 0) setQty(qty - 1);
                                    }}
                                        color="secondary">
                                        <RemoveIcon />
                                    </IconButton>
                                </Box>

                                <Button variant="outlined" sx={styles.cartButton} onClick={handleCheckout}>Checkout</Button>
                            </>
                    }
                </div>
            }

            {matches ?
                <div style={{ width: '100%' }}>
                    {
                        qty === 0 ?
                            <Button variant="contained" sx={{ ...styles.buyButton, height: '10vh' }} onClick={handleAddToCart}>Add to Cart</Button>
                            :
                            <div style={{ width: '100%', display: 'flex' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 2,
                                        height: '10vh',
                                        flexGrow: 1,
                                        border: '1px solid #ccc',
                                        padding: '8px',
                                        width: 'fit-content',
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <IconButton onClick={() => setQty(qty + 1)} color="primary">
                                        <AddIcon />
                                    </IconButton>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                        {qty}
                                    </Typography>
                                    <IconButton onClick={() => {
                                        if (qty > 0) setQty(qty - 1);
                                    }}
                                        color="secondary">
                                        <RemoveIcon />
                                    </IconButton>
                                </Box>

                                <Button variant="outlined" sx={{ ...styles.cartButton, width: '50%' }} onClick={handleCheckout}>Checkout</Button>
                            </div>
                    }
                </div>
                : <></>}
        </div>
    )
}

export default BottomCart