import { Box, Button, Divider, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { primaryColor } from "../constant";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '0px',
        marginLeft: '15px',
        '&.installed': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.in-service': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.non-active': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.uninstalled': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.stock': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

export default function VendorVehiclesDetails() {
    const location = useLocation();
    var navigate = useNavigate()
    const detailsData = location?.state?.row;
    const classes = useStyles();
    const renderDetailField = (label, value) => (
        <div style={{ display: 'flex', marginTop: 5 }}>
            <div style={{ width: '35%' }}>
                <span style={{ color: "#808080", fontSize: '14px' }}>
                    {label}
                </span>
            </div>
            <div style={{ width: '65%' }}>
                {
                    label === 'Status' ?
                        <Button className={`${classes.stageButton} ${value?.toLowerCase()?.replace(' ', '')}`}>
                            {value}
                        </Button>
                        :
                        <span style={{ fontSize: '14px' }}>
                            {value || "N/A"}
                        </span>
                }
            </div>
        </div>
    );

    return (
        <div>
            <Box
                style={{
                    background: 'white',
                    borderRadius: 5,
                    border: '1px solid gainsboro',
                    padding: 20,
                }}
            >
                <div>
                    {renderDetailField("Status", detailsData?.status)}
                    {detailsData?.reason && renderDetailField("Reason", detailsData?.reason)}
                </div>



                <div style={{ width: '100%', paddingBottom: '3%' }}>
                    {detailsData?.detail?.map((item, index) => {
                        const address = item?.driverDetails?.address;
                        const formattedAddress = address
                            ? `${address.street || ''}, ${address.landmark || ''}, ${address.city || ''}, ${address.state || ''}, ${address.zipCode || ''}`
                            : "N/A";

                        return (
                            <div key={index}>
                                <Divider style={{ margin: '10px 0' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="h6" fontWeight="500" gutterBottom>
                                        Driver Details
                                    </Typography>
                                    <span style={{ textAlign: 'center', borderRadius: '50%', background: '#e1ffd1', margin: '0 10px', width: 30, height: 30, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>{index + 1}</span>
                                </div>
                                {renderDetailField("Full Name", item?.driverDetails?.fullName)}
                                {renderDetailField("Father Name", item?.driverDetails?.fatherName)}
                                {renderDetailField("Contact No", item?.driverDetails?.contactNo)}
                                {renderDetailField("Res. Contact No", item?.driverDetails?.resContactNo)}
                                {renderDetailField("Aadhar No", item?.driverDetails?.aadhar)}
                                {renderDetailField("PanCard No", item?.driverDetails?.pan)}
                                {renderDetailField("Badge No", item?.driverDetails?.badgeNo)}
                                {renderDetailField("Shift", item?.driverDetails?.shift)}
                                {renderDetailField("Address", formattedAddress)}

                                <Divider style={{ margin: '20px 0' }} />

                                <Typography variant="h6" fontWeight="500" gutterBottom>
                                    Vehicle Details
                                </Typography>
                                {renderDetailField("Brands", item?.vehicleDetails?.brand?.name)}
                                {renderDetailField("Model", item?.vehicleDetails?.model?.name)}
                                {renderDetailField("Owner Name", item?.vehicleDetails?.regnOwnerName)}
                                {renderDetailField("Owner Contact No", item?.vehicleDetails?.regnOwnerContactNo)}
                                {renderDetailField("Vehicle No", item?.vehicleDetails?.regnNumber)}
                                {renderDetailField("Chassis Number", item?.vehicleDetails?.chassisNumber)}
                                {renderDetailField("Type Of Vehicle", item?.vehicleDetails?.typeOfVehicle)}
                                {renderDetailField("Number Of Seats", item?.vehicleDetails?.noOfSeats)}
                                {renderDetailField("Plate", item?.vehicleDetails?.plate)}
                            </div>
                        );
                    })}
                </div>
            </Box>
        </div>
    );
}
