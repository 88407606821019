import React from "react";
import { jsPDF } from "jspdf";
import logo from "../assests/3gb_logo.png"; // Import your company logo
import lineImage from "../assests/3gb.png"; // Import the design separator image
import { Box, Button, Modal, Typography } from "@mui/material";
import { formatDate, primaryColor } from "../constant";
import CompanySeal from "../assests/company_seal.png";

const PDFPreview = ({ formData, open, setOpen }) => {
  const generatePDF = () => {
    const doc = new jsPDF();

    // Add Border
    doc.setDrawColor(0);
    doc.setLineWidth(1);
    doc.rect(5, 5, 200, 287); // x, y, width, height

    // Add Logo (Left Side)
    const logoWidth = 40;
    const logoHeight = 25;
    doc.addImage(logo, "PNG", 10, 10, logoWidth, logoHeight);

    // Add Company Header Info (Right Side)
    doc.setFont("helvetica", "bold");
    doc.setFontSize(9);

    const textStartX = 55; // Align to the right of the logo
    const textStartY = 12;

    doc.setFont("helvetica", "bold");
    doc.text("Speed Limiting Device (Approved & Certified) by ARAI", textStartX, textStartY);

    doc.addImage(lineImage, "PNG", 55, textStartY + 0, 125, 7);
    // Company Details (Below the Line)
    doc.setFont("helvetica", "normal");
    doc.text('(Customer Copy)', textStartX + 123, 9);
    doc.text("(AN ISO 9001:2015 CERTIFIED COMPANY) COMPLIANCE TO AIS018, AIS037, STANDARD", textStartX, textStartY + 10);
    doc.text("25, Kardhani Enclave, Gokulpura, Near Pratap Circle,", textStartX, textStartY + 14);
    doc.text("Kalwar Road, Jhotwara, Jaipur-302012", textStartX, textStartY + 18);
    doc.text("3gbtechnology.sales@gmail.com | www.3gb.co.in | 0141-4901577", textStartX, textStartY + 22);


    // Add Title "CALIBRATION CERTIFICATE (1)" Centered
    doc.setFont("helvetica");
    doc.setFontSize(10);

    const pageWidth = doc.internal.pageSize.getWidth(); // Get page width
    let text = "CALIBRATION CERTIFICATE (1)";
    let textWidth = doc.getTextWidth(text);
    const centerX = (pageWidth - textWidth) / 2;
    doc.text(text, centerX, 40);


    // Section-1: Vehicle Owner Details
    doc.setFont("helvetica", "noraml");
    doc.setFontSize(12);
    doc.text("To,", 10, 60);
    doc.setFontSize(9);
    doc.text('Regional Transport Officer', 20, 65);
    let x = 20;
    let y = 70;
    textWidth = doc.getTextWidth(text);
    const padding = 2;

    // Draw top and bottom borders (1px line)
    doc.setFont("helvetica", "bold");
    doc.setLineWidth(0.3); // Set line width to 0.1 (adjust as needed)
    doc.line(x, y - padding, x + textWidth, y - padding); // Top border
    doc.text(`${formData?.rtoCode}`, x, 72);
    doc.line(x, y + padding + 2, x + textWidth, y + padding + 2); // Bottom border

    text = `${formData?.testRepNumber}`;
    textWidth = doc.getTextWidth(text);
    doc.text(text, pageWidth - textWidth - 10, 65)

    doc.setFont("helvetica", "normal");
    doc.text('This is to certify that following vehicle has been fitted with an Approved Speed Governer, the datails given below.', 10, 80);

    doc.setFont("helvetica", "bold");

    text = 'Section-1-VEHICLE OWNER DETAILS';
    x = 10;
    y = 85;
    textWidth = doc.getTextWidth(text);

    doc.text(text, x, y);
    doc.setLineWidth(0.1); // Thin underline
    doc.line(x, y + 1, x + textWidth, y + 1); // Underline just below the text


    let heading = 'Owner name & address: ';
    let value = `${formData?.ownerName} (${formData?.address})`;
    x = 10;
    y = 90;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)



    doc.setFont("helvetica", "bold");

    text = 'Section-2-VEHICLE DETAILS';
    x = 10;
    y = 95;
    textWidth = doc.getTextWidth(text);

    doc.text(text, x, y);
    doc.setLineWidth(0.1); // Thin underline
    doc.line(x, y + 1, x + textWidth, y + 1); // Underline just below the text


    heading = 'Vehicle Reg No: ';
    value = `${formData?.vehicleRegNumber}`;
    x = 10;
    y = 100;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    heading = 'Engine No: ';
    value = `${formData?.engineNumber}`;
    x = 80;
    y = 100;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)



    heading = 'Type Of Vehicle: ';
    value = `${formData?.vehicleType}`;
    x = 10;
    y = 105;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    heading = 'Vehicle (Make): ';
    value = `${formData?.vehicleBrand}`;
    x = 10;
    y = 110;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)



    heading = 'Chassis No: ';
    value = `${formData?.chassisNumber}`;
    x = 80;
    y = 110;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)



    heading = 'Registration Date: ';
    value = `${formatDate(formData?.registrationDate)}`;
    x = 150;
    y = 110;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    heading = 'Vehicle (Model): ';
    value = `${formData?.vehicleModel}`;
    x = 10;
    y = 115;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    heading = 'Manufacturing Year: ';
    value = `${formData?.manufacturingYear}`;
    x = 80;
    y = 115;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)

    doc.setFont("helvetica", "bold");

    text = 'Section-3-SPEED GOVERNOR DETAILS';
    x = 10;
    y = 120;
    textWidth = doc.getTextWidth(text);

    doc.text(text, x, y);
    doc.setLineWidth(0.1); // Thin underline
    doc.line(x, y + 1, x + textWidth, y + 1); // Underline just below the text

    heading = 'TAC No: ';
    value = `${formData?.tacNumber}`;
    x = 10;
    y = 125;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)

    heading = 'Test Rep No: ';
    value = `${formData?.testRepNumber}`;
    x = 80;
    y = 125;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)

    heading = 'SLD Model: ';
    value = `${formData?.sldModel}`;
    x = 150;
    y = 125;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)

    heading = 'SLD Type: ';
    value = `${formData?.sldType}`;
    x = 10;
    y = 130;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    heading = 'SLD Serial No: ';
    value = `${formData?.sldSerialNo}`;
    x = 80;
    y = 130;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    heading = 'Set Speed: ';
    value = `${formData?.setSpeed}`;
    x = 150;
    y = 130;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    heading = 'Invoice No: ';
    value = `${formData?.invoiceNo}`;
    x = 10;
    y = 135;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)

    heading = 'Invoice Date: ';
    value = `${formatDate(formData?.invoiceDate)}`;
    x = 80;
    y = 135;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    heading = 'Installtion Date: ';
    value = `${formatDate(formData?.installationDate)}`;
    x = 150;
    y = 135;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)

    heading = 'Calibration Date: ';
    value = `${formatDate(formData?.calibrationDate)}`;
    x = 10;
    y = 140;


    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)

    heading = 'Next Calibration Date: ';
    value = `${formatDate(formData?.nextCalibrationDate)}`;
    x = 80;
    y = 140;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    heading = 'Seal no: ';
    value = `${formData?.sealNo}`;
    x = 150;
    y = 140;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)

    text = 'Section-4-AUTHORIZED DEALER DECLARATION';
    x = 10;
    y = 145;
    textWidth = doc.getTextWidth(text);

    doc.text(text, x, y);
    doc.setLineWidth(0.1); // Thin underline
    doc.line(x, y + 1, x + textWidth, y + 1); // Underline just below the text


    doc.text('Note: Section 1 or 2 to be completed by the authorized Dealer Who set or checked the Speed Governor. I declare that I have read and understood the information provided overleaf and the information details in Section 1, and 2 above is true and correct.', 10, 150, { maxWidth: 180 });


    heading = 'Company Name: ';
    value = `${formData?.companyName}`;
    x = 10;
    y = 160;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)



    heading = 'Area: ';
    value = `${formData?.area}`;
    x = 120;
    y = 160;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    heading = 'Address: ';
    value = `${formData?.companyAddress}`;
    x = 10;
    y = 165;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    heading = 'Trade License No: ';
    value = `${formData?.tradeLicenseNo}`;
    x = 120;
    y = 165;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    heading = 'Trade License Expiry Date: ';
    value = `${formatDate(formData?.tradeLicenseExpiryDate)}`;
    x = 10;
    y = 170;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    heading = 'RTO Name: ';
    value = `${formData?.rtoName}`;
    x = 120;
    y = 170;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)


    text = 'Section-5-VEHICLE & SLD IMAGE';
    x = 10;
    y = 175;
    textWidth = doc.getTextWidth(text);

    doc.text(text, x, y);
    doc.setLineWidth(0.1); // Thin underline
    doc.line(x, y + 1, x + textWidth, y + 1); // Underline just below the text



    const companySealWidth = 30;
    const companySealHeight = 20;
    doc.addImage(CompanySeal, "PNG", 170, 180, companySealWidth, companySealHeight);


    const texts = [
      'Customer Signature',
      'Dealer Seal & sign.',
      'Department sign.',
      'Company Seal & sign.',
    ];
    y = 200; // Adjust the Y position as needed
    const textWidths = texts.map((text) => doc.getTextWidth(text));
    const totalTextWidth = textWidths.reduce((sum, width) => sum + width, 0);
    const totalSpacing = pageWidth - 20 - totalTextWidth; // 10px padding on each side
    const spaceBetween = totalSpacing / (texts.length - 1);
    let currentX = 10; // Start with 10px padding

    texts.forEach((text, index) => {
      doc.text(text, currentX, y);
      currentX += textWidths[index] + spaceBetween;
    });

    text = 'Section-6-PRODUCT SPECIFICATION REPORT';
    x = 10;
    y = 205;
    textWidth = doc.getTextWidth(text);

    doc.text(text, x, y);
    doc.setLineWidth(0.1); // Thin underline
    doc.line(x, y + 1, x + textWidth, y + 1); // Underline just below the text

    doc.text(`This is acknowledge and confirm that we got our vehicle(Registration No. ${formData?.vehicleRegNumber} ) bore with Speed Limitation Device manufactured by M/s 3GB TECHNOLOGY PVT LTD and Speed Limitation Device Serial Number is ${formData?.sldSerialNo}. We have checked the performance of the vehicle after fitted of Speed Limitation Device and also confirmed that above Speed of the vehicle is set and the unit is sealed at above notary points and functioning as per norms laid down in AIS:018. We are satisfied with the performance of the unit in all perspectives.`, 10, 210, { maxWidth: 180 });
    doc.text('We don\'t have rights to raise any dispute or any legal claims against M/s 3GB TECHNOLOGY PVT LTD in the event that the above mentioned seals are found broken/torn/tampered and more specifically with the respect to any variance in the speed limit set at the time of delivery, after expiry of warranty period of 12 months from the date of installation.', 10, 230, { maxWidth: 180 });


    heading = 'Name: ';
    value = `${formData?.name}`;
    x = 10;
    y = 250;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)

    heading = 'Place: ';
    value = `${formData?.place}`;
    x = 80;
    y = 250;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)

    heading = 'ID No: ';
    value = `${formData?.idNo}`;
    x = 150;
    y = 250;

    // Bold heading
    doc.setFont('helvetica', 'bold');
    doc.text(heading, x, y);

    // Normal value
    doc.setFont('helvetica', 'normal');
    doc.text(value, x + doc.getTextWidth(heading) + 2, y)

    // Save PDF
    doc.save("SpeedGoverner.pdf");
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '10px',
    outline: 'none',
    maxHeight: '80vh',
    overflowY: 'auto',
    borderRadius: '10px',
  };

  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" sx={{
          fontWeight: 600,
          fontSize: 22,
          padding: '16px 0',
          color: '#333',
          borderBottom: '1px solid #ccc'
        }}>
          Downaload Speed Governer Pdf
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Button style={{ textTransform: 'capitalize', color: "#fff", background: primaryColor, borderRadius: 0 , marginTop: '10px', marginBottom: '10px'}} onClick={generatePDF} fullWidth>Download</Button>
        </Typography>
      </Box>
    </Modal>)
};

export default PDFPreview;
