import { Facebook, Instagram, YouTube } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { get_data } from '../api';

const SocialLinks = () => {
  const [facebook, setFacebook] = React.useState('');
  const [instagram, setInstagram] = React.useState('');
  const [youtube, setYoutube] = React.useState('');

  const getSocialLinks = async () => {
    const global = await get_data('global/get-global-prices');
    if (global?.status == true) {
      setFacebook(global?.data?.facebook);
      setInstagram(global?.data?.instagram);
      setYoutube(global?.data?.youtube);
    }
  }

  useEffect(() => {
    getSocialLinks();
  }, []);

  return (
    <div className="social-links">
      <a href={facebook} target="_blank" rel="noopener noreferrer" className="social-link">
        <Facebook />
      </a>
      <a href={youtube} target="_blank" rel="noopener noreferrer" className="social-link">
        <YouTube />
      </a>
      <a href={instagram} target="_blank" rel="noopener noreferrer" className="social-link">
        <Instagram />
      </a>
    </div>
  );
};

export default SocialLinks;