// import React, { useEffect, useState } from 'react';
// import { Modal, Box, Typography, Button, TextField, Chip, Select, MenuItem, InputLabel, FormControl, Grid, CircularProgress, useMediaQuery, Avatar } from '@mui/material';
// import { formatDate, formatPrice, primaryColor } from '../../constant';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { serverURL, get_data, post_data } from '../../api';
// import Swal from 'sweetalert2';
// import InvoiceModal from './InvoiceModal';
// import { styled } from '@mui/material/styles';
// import RefundServicePayment from './RefundServicePayment';

// const BookingDetailsModal = ({ open, setOpen, currentBooking, type }) => {
//     const { user_data } = useSelector(state => state.user);
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const [loading, setLoading] = useState(false);
//     const [emailError, setEmailError] = useState('');
//     const [invoiceModal, setInvoiceModal] = useState(false);
//     const [currentInvoice, setCurrentInvoive] = useState({});

//     const handleClose = () => {
//         setOpen(false);
//         setEmailError(false);
//     };

//     // const { primaryColor } = globalConstant();
//     const isDesktop = useMediaQuery('(min-width: 768px)');

//     const styles = {
//         modalBox: {
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             overflowY: 'auto',
//             maxHeight: isDesktop ? '95%' : '80%',
//             width: 600,
//             bgcolor: 'background.paper',
//             borderRadius: '10px',
//             boxShadow: 24,
//             textAlign: 'center',
//             padding: !isDesktop ? 2 : ''
//         },
//         heading: {
//             fontWeight: 600,
//             fontSize: 27,
//             marginBottom: '10px',
//         },
//         buttons: {
//             display: 'flex',
//             justifyContent: 'center',
//             gap: '10px',
//             marginTop: '20px',
//         },
//         amenity: {
//             fontSize: '12px',
//             color: '#666',
//             backgroundColor: '#fff',
//             border: '1px solid black',
//             borderRadius: '15px',
//             padding: '2px 7px',
//             marginRight: '5px',
//             marginBottom: '5px',
//             color: 'black'
//         },
//         amenities: {
//             display: 'flex',
//             flexWrap: 'wrap',
//             marginRight: 'auto',
//             justifyContent: 'right'
//         },
//         button: {
//             padding: '10px 20px',
//             fontSize: '16px',
//             color: '#fff',
//             backgroundColor: primaryColor,
//             borderRadius: '8px',
//             cursor: 'pointer',
//             border: '2px solid ' + primaryColor,
//             marginTop: '3%',
//             width: '30%',
//             boxShadow: 'none'
//         }
//     };


//     const statusStyles = {
//         scheduled: {
//             backgroundColor: '#ffe9a8',
//             color: '#bf8f00',
//         },
//         completed: {
//             backgroundColor: '#C8E6C9',
//             color: '#388E3C',
//         },
//         cancelled: {
//             backgroundColor: '#FFCDD2',
//             color: '#D32F2F',
//         }
//     };

//     const heading = {
//         fontWeight: 600,
//         fontSize: 22,
//         marginBottom: '10px',
//         textAlign: 'left',
//         p: 3,
//         borderBottom: '1px solid gainsboro'
//     }

//     const DataRow = ({ label, value }) => (
//         <Box sx={{
//             display: 'flex',
//             padding: '2% 0',
//         }}>
//             <InvoiceModal currentInvoice={currentInvoice} currentIndex={1} open={invoiceModal} setOpen={setInvoiceModal} />
//             <Typography variant="body1" style={{ flex: 2, textAlign: 'left', fontSize: 15 }}>{label}:</Typography>
//             <Typography style={{ flex: 2, textAlign: 'right', fontSize: 15 }}>
//                 {
//                     label === 'Receipt' ?
//                         <>
//                             <p style={{ margin: 0, color: primaryColor, fontWeight: 600, cursor: 'pointer' }}
//                                 onClick={() => {
//                                     setInvoiceModal(true)
//                                     setCurrentInvoive(currentBooking)
//                                 }}
//                             >Download
//                             </p>
//                         </>
//                         :
//                         <>
//                             <Typography style={{ marginLeft: 'auto', fontSize: 15, textAlign: 'right' }} variant="body1">{value}</Typography>
//                         </>
//                 }
//             </Typography>
//         </Box>
//     )
//     console.log("bbbbbbbb", currentBooking)

//     return (
//         <Modal
//             open={open}
//             onClose={handleClose}
//         >
//             <Box sx={styles.modalBox}>
//                 <Typography variant="h6" sx={heading}>
//                     Booking Detai
//                 </Typography>
//                 <div style={{ marginTop: 2, padding: '2% 3% 6% 3%' }}>
//                     <Grid container spacing={5}>
//                         <Grid item xs={12} style={{ width: '100%' }}>
//                             <DataRow label="Receipt" value={currentBooking?.orderUniqueId} />
//                             <DataRow label="Booking ID" value={currentBooking?.orderUniqueId} />
//                             <DataRow label="Amount" value={formatPrice(currentBooking?.amount)} />
//                             <DataRow label="City" value={currentBooking?.shippingAddress?.city} />
//                             <DataRow label="State" value={currentBooking?.shippingAddress?.state} />
//                             <DataRow label="ZipCode" value={currentBooking?.shippingAddress?.zipCode} />
//                             <DataRow label="Transaction Number" value={currentBooking?.transactionNumber} />
//                             <DataRow label="Invoice Number" value={currentBooking?.invoiceNumber} />
//                             <DataRow label="Order Status" value={currentBooking?.orderStatus} />
//                             {
//                                 currentBooking?.productId?.map((product, i) =>
//                                 (<div
//                                     key={i}
//                                     style={{
//                                         width: '90px',
//                                         borderRadius: '8px',
//                                         cursor: 'pointer',
//                                         padding: '10px 5px',
//                                         display: 'flex',
//                                         flexDirection: 'row',
//                                         flexWrap: 'wrap',
//                                         alignItems: 'center',
//                                         boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//                                         backgroundColor: '#fff',
//                                         marginBottom: '10px',
//                                     }}
//                                 >
//                                     <Avatar
//                                         src={`${serverURL}/uploads/products-images/${product?.images?.[0]}`}
//                                         variant="rounded"
//                                         style={{
//                                             width: '100%', // Occupy the full width
//                                             height: '70px', // Slightly smaller height
//                                             borderRadius: '8px',
//                                             border: '1px solid #ddd', // Lighter border
//                                             objectFit: 'cover',
//                                         }}
//                                     />
//                                     <div
//                                         style={{
//                                             fontSize: '0.9rem', // Reduced font size
//                                             color: '#333', // Darker color for better contrast
//                                             fontWeight: 500, // Made font weight bolder
//                                             marginTop: '8px', // Reduced margin
//                                             textOverflow: 'ellipsis',
//                                             whiteSpace: 'nowrap',
//                                             overflow: 'hidden',
//                                             textAlign: 'center', // Center-aligned text
//                                             maxWidth: '100%',
//                                         }}
//                                     >
//                                         {product?.name}
//                                     </div>
//                                     <div
//                                         style={{
//                                             fontSize: '1rem', // Slightly reduced size
//                                             fontWeight: 600, // Increased font weight for price
//                                             // color: '#e74c3c', // Accent color for price
//                                             marginTop: '5px', // Slight margin above price
//                                             textAlign: 'start',
//                                             width: '100%'
//                                         }}
//                                     >
//                                         {formatPrice(product?.price)} <span style={{fontSize:'12px'}}>{' x ' + currentBooking?.productInfo?.[product?._id]?.qty}</span>
//                                     </div>
//                                 </div>
//                                 )
//                                 )}
//                             <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                                 <Typography sty variant='body1'>accessories:</Typography>
//                                 {
//                                     currentBooking?.accessoryId.length > 0 ? <>{currentBooking?.accessoryId?.map((acces, i) =>
//                                     (<div
//                                         key={i}
//                                         style={{
//                                             width: '90px',
//                                             borderRadius: '8px',
//                                             cursor: 'pointer',
//                                             padding: '10px 5px',
//                                             display: 'flex',
//                                             flexDirection: 'row',
//                                             flexWrap: 'wrap',
//                                             alignItems: 'center',
//                                             boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//                                             backgroundColor: '#fff',
//                                             marginBottom: '10px',
//                                         }}
//                                     >
//                                         <Avatar
//                                             src={`${serverURL}/uploads/accessories-images/${acces?.images?.[0]}`}
//                                             variant="rounded"
//                                             style={{
//                                                 width: '100%', // Occupy the full width
//                                                 height: '70px', // Slightly smaller height
//                                                 borderRadius: '8px',
//                                                 border: '1px solid #ddd', // Lighter border
//                                                 objectFit: 'cover',
//                                             }}
//                                         />
//                                         <div
//                                             style={{
//                                                 fontSize: '0.9rem', // Reduced font size
//                                                 color: '#333', // Darker color for better contrast
//                                                 fontWeight: 500, // Made font weight bolder
//                                                 marginTop: '8px', // Reduced margin
//                                                 textOverflow: 'ellipsis',
//                                                 whiteSpace: 'nowrap',
//                                                 overflow: 'hidden',
//                                                 textAlign: 'center', // Center-aligned text
//                                                 maxWidth: '100%',
//                                             }}
//                                         >
//                                             {acces?.name}
//                                         </div>
//                                         <div
//                                             style={{
//                                                 fontSize: '1rem', // Slightly reduced size
//                                                 fontWeight: 600, // Increased font weight for price
//                                                 // color: '#e74c3c', // Accent color for price
//                                                 marginTop: '5px', // Slight margin above price
//                                                 textAlign: 'start',
//                                                 width: '100%'
//                                             }}
//                                         >
//                                             {formatPrice(acces?.price)}
//                                         </div>
//                                     </div>
//                                     )
//                                     )}</> : <></>}
//                             </div>
//                             <DataRow label="Created Date" value={formatDate(currentBooking?.createdAt)} />
//                         </Grid>
//                     </Grid>
//                 </div>
//             </Box>
//         </Modal >
//     );
// };

// export default BookingDetailsModal;



import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Table, TableHead, TableBody, TextField, Avatar, ListItem, ListItemAvatar, IconButton, List, Divider, Button } from '@mui/material';
import { formatDate, formatPrice, primaryColor } from '../../constant';
import toast from 'react-hot-toast';
import { makeStyles } from '@mui/styles';
import { serverURL, get_data, post_data } from '../../api';
import InvoiceModal from './InvoiceModal';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.processing': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.shipped': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.delivered': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.cancelled': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.true': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
    },
});

const BookingDetailsModal = ({ open, setOpen, currentBooking, type }) => {
    const classes = useStyles();
    const [simMobileNo, setSimMobileNo] = useState(currentBooking?.productId);
    const [invoiceModal, setInvoiceModal] = useState(false);
    const [currentInvoice, setCurrentInvoive] = useState({})

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        setSimMobileNo(currentBooking?.productId);
    }, [currentBooking?.productId]);

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80vw',
            height: '80vh',
            border: 'none',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
            overflow: 'auto'
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,
        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right'
        },
        icon: {
            width: 22,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    const shippingAddress = currentBooking?.shippingAddress
        ? `${currentBooking.shippingAddress.street}, ${currentBooking.shippingAddress.city}, ${currentBooking.shippingAddress.zipCode}, ${currentBooking.shippingAddress.state}`
        : '-';

    const DataRow = ({ label, value }) => (
        <div style={styles.row}>
            <span style={styles.dataLabel}>{label}</span>
            {
                label === 'Receipt' ?
                    <>
                        <span style={{ marginLeft: 'auto', color: primaryColor, fontWeight: 600, cursor: 'pointer' }}
                            onClick={() => {
                                setInvoiceModal(true)
                                setCurrentInvoive(currentBooking)
                            }}
                        >Download
                        </span>
                    </>
                    :
                    label === 'Status' ?
                        <Button
                            style={{ fontSize: 11, fontWeight: 500, borderRadius: 30, marginLeft: 'auto' }}
                            className={`${classes.stageButton} ${value?.toLowerCase()?.replace(' ', '')}`}
                        >
                            {value}
                        </Button>
                        :
                        <span style={styles.dataValue}>{value}</span>

            }
        </div>
    );

    return (
        <>
            <InvoiceModal currentInvoice={currentInvoice} open={invoiceModal} setOpen={setInvoiceModal} />
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={styles.modalBox}>
                    <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                        Order&apos;s Details
                    </Typography>
                    <Box style={{ display: 'flex' }}>
                        <Box sx={{ padding: '2%', width: '50%' }}>
                            <DataRow label="Receipt" value={currentBooking?.orderUniqueId} />
                            <DataRow label="User Name" value={currentBooking?.userId?.name || '-'} />
                            <DataRow label="Phone" value={currentBooking?.userId?.phone || '-'} />
                            <DataRow label="Product Name" value={currentBooking?.productId?.map((item) => <div>{item?.name}</div>) || '-'} />
                            <DataRow label="Amount Without Gst" value={formatPrice(currentBooking?.amountBeforGst) || '-'} />
                            <DataRow label="Paid Amount" value={formatPrice(currentBooking?.amount) || '-'} />
                            <DataRow label="Address" value={shippingAddress || '-'} />
                            <DataRow label="Status" value={currentBooking?.orderStatus || '-'} />
                            {/* <DataRow label="Status" value={currentBooking?.status || '-'} /> */}
                            <DataRow label="Date" value={formatDate(currentBooking?.createdAt) || '-'} />
                            <DataRow label="Transaction Number" value={currentBooking?.transactionNumber || '-'} />
                            <DataRow label="InVoice Number" value={currentBooking?.invoiceNumber || '-'} />
                        </Box>

                        <Box style={{ width: '50%', overflow: 'auto' }}>
                            <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                                <List>
                                    {currentBooking?.productId?.map((item, index) => (
                                        <div key={index} style={{ marginBottom: '30px', border: '1px solid #ddd', padding: '10px', boxSizing: 'border-box' }}>
                                            {/* Product Card */}
                                            <div style={{ borderRadius: '8px', display: 'flex', gap: '20px' }}>
                                                <ListItemAvatar style={{ flexShrink: 0 }}>
                                                    <img src={`${serverURL}/uploads/products-images/${item?.images?.[0]}`} style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' }} />
                                                </ListItemAvatar>

                                                {/* Product Information */}
                                                <Box style={{ flexGrow: 1 }}>
                                                    <Typography variant='h6'>
                                                        <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
                                                            <span>{formatPrice(item?.price)}</span>
                                                            <span style={{ fontSize: '14px', color: '#888' }}> x {currentBooking?.productInfo?.[item?._id]?.qty}</span>
                                                        </div>
                                                    </Typography>


                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                        {currentBooking?.productInfo?.[item?._id]?.relay != 'no relay' && <span
                                                            style={{
                                                                fontSize: '14px',
                                                                color: '#808080',
                                                                border: '1px solid #808080',
                                                                padding: '5px 10px',
                                                                textAlign: 'center',
                                                                borderRadius: '5px'
                                                            }}
                                                        >
                                                            {currentBooking?.productInfo?.[item?._id]?.relay}
                                                        </span>}
                                                        {currentBooking?.productInfo?.[item?._id]?.insurance != 'without insurance' && <span
                                                            style={{
                                                                fontSize: '14px',
                                                                color: '#808080',
                                                                border: '1px solid #808080',
                                                                padding: '5px 10px',
                                                                textAlign: 'center',
                                                                borderRadius: '5px'
                                                            }}
                                                        >
                                                            {currentBooking?.productInfo?.[item?._id]?.insurance}
                                                        </span>}

                                                        {currentBooking?.productInfo?.[item?._id]?.server != 'without server' && <span
                                                            style={{
                                                                fontSize: '14px',
                                                                color: '#808080',
                                                                border: '1px solid #808080',
                                                                padding: '5px 10px',
                                                                textAlign: 'center',
                                                                borderRadius: '5px'
                                                            }}
                                                        >
                                                            {currentBooking?.productInfo?.[item?._id]?.server}
                                                        </span>
                                                        }
                                                        {currentBooking?.productInfo?.[item?._id]?.simCard != 'no sim' && <span
                                                            style={{
                                                                fontSize: '14px',
                                                                color: '#808080',
                                                                border: '1px solid #808080',
                                                                padding: '5px 10px',
                                                                textAlign: 'center',
                                                                borderRadius: '5px'
                                                            }}
                                                        >
                                                            {currentBooking?.productInfo?.[item?._id]?.simCard}
                                                        </span>}
                                                    </div>

                                                    <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
                                                        {currentBooking?.productInfo?.[item?._id]?.imei && <span
                                                            style={{
                                                                fontSize: '14px',
                                                                color: '#808080',
                                                                border: '1px solid #808080',
                                                                padding: '5px 10px',
                                                                textAlign: 'center',
                                                                borderRadius: '5px'
                                                            }}
                                                        >
                                                            IMEI NO : {currentBooking?.productInfo?.[item?._id]?.imei}
                                                        </span>}

                                                        {currentBooking?.productInfo?.[item?._id]?.simNumber && <span
                                                            style={{
                                                                fontSize: '14px',
                                                                color: '#808080',
                                                                border: '1px solid #808080',
                                                                padding: '5px 10px',
                                                                textAlign: 'center',
                                                                borderRadius: '5px'
                                                            }}
                                                        >
                                                            SIM NO :  {currentBooking?.productInfo?.[item?._id]?.simNumber}
                                                        </span>}
                                                    </div>
                                                </Box>
                                            </div>

                                            {currentBooking?.productInfo?.[item?._id]?.accessories?.length > 0 && (
                                                <div style={{ marginTop: '15px', padding: '10px 0' }}>
                                                    <Typography variant="body1" style={{ fontSize: '16px', fontWeight: '500', marginBottom: '10px' }}>
                                                        Accessories:
                                                    </Typography>
                                                    <div style={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
                                                        {currentBooking?.productInfo?.[item?._id]?.accessories.map((accessory, i) => (
                                                            <div
                                                                key={i}
                                                                style={{
                                                                    position: 'relative',
                                                                    width: '130px',
                                                                    cursor: 'pointer',
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                {/* Accessory Image */}
                                                                <img
                                                                    src={`${serverURL}/uploads/accessories-images/${accessory?.images?.[0]}`}
                                                                    variant="rounded"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '120px',
                                                                        borderRadius: '8px',
                                                                        objectFit: 'cover',
                                                                    }}
                                                                />

                                                                {/* Transparent Overlay for Accessory Name and Price */}
                                                                <div
                                                                    style={{
                                                                        position: 'absolute',
                                                                        bottom: '5px',
                                                                        left: '0',
                                                                        right: '0',
                                                                        padding: '5px 10px',
                                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Slightly darker transparent background for better contrast
                                                                        borderRadius: '4px',
                                                                        color: '#fff',
                                                                        textAlign: 'center',
                                                                    }}
                                                                >
                                                                    {/* Accessory Name */}
                                                                    <div
                                                                        style={{
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                            maxWidth: '100%',
                                                                            fontSize: '14px'
                                                                        }}
                                                                    >
                                                                        {accessory?.title}
                                                                    </div>

                                                                    {/* Accessory Price */}
                                                                    <div
                                                                        style={{
                                                                            fontSize: '14px',
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        {formatPrice(accessory?.price)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </List>
                            </div>

                        </Box >
                    </Box >
                </Box>
            </Modal >
        </>

    );
};

export default BookingDetailsModal;
