import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Divider, List, ListItem, ListItemText, ListItemAvatar, Avatar, Backdrop, useMediaQuery, Modal, TextField, Alert, Chip, IconButton, CircularProgress, Checkbox, FormGroup, FormControlLabel, Radio, RadioGroup, FormLabel, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { FaShieldAlt, FaTruck, FaPercentage, FaPlus, FaTimes } from 'react-icons/fa';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { primaryColor, formatPrice } from '../../constant';
import { get_data, post_data } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import StarIcon from '@mui/icons-material/Star';
import Empty from '../../components/Empty';
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import { useLocation, useNavigate } from 'react-router-dom';
import { login } from '../../redux/slices/user-slice';
import BillingDetail from '../../components/BillingDetail';
import { serverURL } from '../../api';
import TrustBadges from '../../components/TrustBadges';
import CartServicesSkeleton from "../../components/CartServicesSkeleton";
import Logo from '../../assests/oredogpslogo.jpeg';

const Checkout = () => {
    const { error, isLoading, Razorpay } = useRazorpay();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [alertMessage, setAlertMessage] = useState('');
    const [total, setTotal] = useState(105.00);
    const [refresh, setRefresh] = useState(false);
    const [serviceDate, setServiceDate] = useState('');
    const [serviceTime, setServiceTime] = useState('');
    const { user_data, isLoggedIn } = useSelector(state => state.user);
    const [allSubServices, setAllSubServices] = useState([]);
    const [loading, setLoading] = useState();
    const [loadingIds, setLoadingIds] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(max-width: 960px)');
    const [city, setCity] = useState('');
    const [open, setOpen] = useState(false);
    const [billingDetail, setBillingDetail] = useState({});
    const [billingError, setBillingError] = useState({});
    const [subTotal, setSubTotal] = useState(0);
    const [gst, setGst] = useState(0);
    const [allCartItems, setAllCartItems] = useState([]);
    const [accessoryIds, setAccessoryIds] = useState([])
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const [productInfo, setProductInfo] = useState({});

    const shippingCharges = location?.state?.shippingCharges || 0;

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const fetchAllProducts = async () => {
        const productId = JSON.parse(localStorage.getItem("productId"));

        const data = await post_data("product/get-product-for-cart", { productId });
        const globalPrices = await get_data("global/get-global-prices");
        if (data?.status) {
            setLoading(false);
            setAllCartItems(data?.data);

            let temp = 0
            let accessoryAmount = 0
            let totalAmount = 0

            // let tempAccesory = JSON.parse(localStorage.getItem("accessoryId")) || [];
            // setAccessoryIds(tempAccesory);

            // data?.data?.forEach(element => {
            //     temp += parseInt(element?.price);

            //     if (element?.accessories?.length > 0) {
            //         element?.accessories?.map((item, i) => {
            //             if (tempAccesory?.[element?._id]?.includes(item?._id)) {
            //                 accessoryAmount += parseInt(item?.price);
            //             }
            //         })
            //     }
            // });

            let productInfo = JSON.parse(localStorage.getItem("productInfo")) || {};

            setProductInfo(productInfo);

            Object.keys(productInfo).map((key) => {
                temp += parseInt(productInfo[key]?.totalPrice) * productInfo[key]?.qty;
            })


            // totalAmount = temp + accessoryAmount
            totalAmount = temp;
            let tempGst = (18 / 100) * totalAmount
            let tempTotal = totalAmount + tempGst
            setSubTotal(totalAmount)
            setGst(tempGst)
            setTotal(tempTotal + parseInt(shippingCharges))
        }
    }


    // const fetchAccessoryIdsFromLocalStorage = ()=>{
    //     let temp = JSON.parse(localStorage.getItem("accessoryId"))
    //     setAccessoryIds(temp)
    // }

    useEffect(() => {
        fetchAllProducts();
    }, [allSubServices]);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            padding: isMobile || isTablet ? '20px' : '5% 10%',
            backgroundColor: '#fff',
            position: 'relative',
        },
        subServicesContainer: {
            padding: isMobile || isTablet ? '20px' : '0 7% 3%',
            overflow: 'hidden',
        },
        subheading: {
            fontSize: '16px',
            marginBottom: isMobile || isTablet ? '20px' : '3%',
        },
        cartSection: {
            padding: '20px',
            backgroundColor: 'white',
            border: '1px solid #E0E0E0',
            borderRadius: '10px',
            boxSizing: "border-box",
        },
        summarySection: {
            flex: 1,
            padding: '20px',
            height: 300,
            border: '1px solid #E0E0E0',
            borderRadius: '10px',
            zIndex: 2,
            marginLeft: isMobile ? '0' : '20px',
            marginTop: isMobile ? '20px' : '0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
        },
        cartItem: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            marginBottom: '20px',
            alignItems: 'start',
        },
        itemDetails: {
            display: 'flex',
            flexDirection: 'column',
        },
        itemActions: {
            display: 'flex',
            alignItems: 'center',
        },
        itemImage: {
            width: '80px',
            height: '80px',
            marginRight: '20px',
            borderRadius: 5
        },
        discountPrice: {
            fontWeight: '600',
            fontSize: '18px',
        },
        price: {
            fontWeight: '400',
            textDecoration: 'line-through',
            marginLeft: '10px',
            color: '#9E9E9E',
            fontSize: '18px',
        },
        discountCode: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
        },
        placeOrderButton: {
            padding: '13px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: primaryColor,
            border: 'none',
            borderRadius: 0,
            cursor: 'pointer',
            width: '100%',
            marginTop: '20px',
            boxShadow: 'none',
        },
        priceRow: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1% 0',
        },
        trustPoints: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
            background: '#f5f5f5',
            padding: '2% 5%',
        },
        trustPoint: {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            flex: 1,
        },
        icon: {
            fontSize: '30px',
            marginBottom: '10px',
            color: primaryColor,
        },
        amenity: {
            fontSize: '12px',
            color: '#666',
            backgroundColor: '#fff',
            border: '1px solid green',
            borderRadius: '15px',
            padding: '2px 7px',
            marginRight: '5px',
            marginBottom: '5px',
            color: 'green'
        },
        amenities: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '5px'
        },
        date: {
            fontSize: '14px',
            color: '#333',
            marginBottom: '5px',
        },
        rating: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
        },
        removeButton: {
            color: 'red',
        },
    };

    const handleDelete = async (id) => {
        const productId = JSON.parse(localStorage.getItem("productId"));

        const productInfo = JSON.parse(localStorage.getItem("productInfo"));

        Object.keys(productInfo).forEach((key) => {
            if (key === id) {
                delete productInfo[key];
                localStorage.setItem("productInfo", JSON.stringify(productInfo));
            }
        })

        const index = productId.indexOf(id);

        productId.splice(index, 1);

        localStorage.setItem("productId", JSON.stringify(productId));

        fetchAllProducts();
    };


    const handleBillingError = (label, value) => {
        setBillingError((prev) => ({ ...prev, [label]: value }))
    }

    const validate = () => {
        let error = false;
        if (!billingDetail?.fullName) {
            handleBillingError("fullName", "Name is Required");
            error = true;
        }
        if (!billingDetail?.phoneNumber) {
            handleBillingError("phoneNumber", "Phone Number is Required");
            error = true;
        }
        if (!billingDetail?.email) {
            handleBillingError("email", "Email is Required");
            error = true;
        }
        if (!billingDetail?.shippingAddress?.street) {
            handleBillingError("street", "Street is Required");
            error = true;
        }
        if (!billingDetail?.shippingAddress?.city) {
            handleBillingError("city", "City is Required");
            error = true;
        }
        if (!billingDetail?.shippingAddress?.state) {
            handleBillingError("state", "State is Required");
            error = true;
        }
        if (!billingDetail?.shippingAddress?.zipCode) {
            handleBillingError("zipCode", "ZipCode is Required");
            error = true;
        }

        if (error) {
            return error;
        }

        return false
    }

    const handleBookNow = async () => {
        if (!validate()) {
            handleRzrpPayment()
        }
    }


    const handleRzrpPayment = async (params) => {
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    }

    const options = {
        key: "rzp_test_GQ6XaPC6gMPNwH",
        amount: Math.ceil(total * 100),
        currency: "INR",
        name: "Oredo GPS Pvt. ltd.",
        description: "Services with a Lifestyle",
        image: Logo,

        handler: async function (response) {
            handleOpen();
            const accessoryId = [];
            allCartItems?.map((item) => {
                item?.accessories?.map((accessory, i) => {
                    if (accessoryIds?.[item?._id]?.includes(accessory?._id)) {
                        accessoryId.push(accessory?._id)
                    }
                })
            })

            let payload = { billingDetail, amountBeforGst: subTotal, amount: total, transactionNumber: response?.razorpay_payment_id, productId: allCartItems?.map((item) => item?._id || ''), accessoryId, productInfo: productInfo };

            const result = await post_data('order/create-order', payload);
            localStorage.setItem("authToken", result?.data?.token);
            dispatch(login(result?.data?.user));
            navigate('/userdashboard')
            if (result?.status === true) {
                handleClose();
                window.scrollTo(0, 0)
            }
            else {
                handleClose();
                window.scrollTo(0, 0)
            }
        },
        prefill: {
            name: `${user_data?.full_name}`,
            email: user_data?.email,
            contact: user_data?.phone,
        },
        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: primaryColor,
        },
    };

    useEffect(() => {
        setBillingDetail((prev) => ({
            ...prev,
            productId: allCartItems?.map((item) => item?._id || ''),
        }));
    }, [allCartItems]);
    return (
        <>
            <Header />

            <Box sx={styles.container}>
                <Box style={{ flex: 3 }}>
                    <Box style={styles.cartSection}>
                        <BillingDetail user_data={user_data} billingError={billingError} billingDetail={billingDetail} setBillingError={setBillingError} setBillingDetail={setBillingDetail} setCity={setCity} />
                    </Box>

                    <Box style={styles.cartSection} sx={{ mt: 2 }}>
                        <Typography variant="h6" gutterBottom>Cart Item</Typography>
                        {
                            loading ?
                                <>
                                    <CartServicesSkeleton />
                                </>
                                :
                                <>
                                    {
                                        allCartItems?.length === 0 ?
                                            <>
                                                <Empty />
                                            </>
                                            :
                                            <>
                                                <List>
                                                    {allCartItems?.map((item, index) => (
                                                        <>
                                                            <ListItem key={index} sx={styles.cartItem}>
                                                                <ListItemAvatar>
                                                                    <img src={`${serverURL}/uploads/products-images/${item?.images[0]}`} style={styles.itemImage} />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={item?.name}
                                                                    secondary={
                                                                        <>
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                {/* Duration: {convertMinutes(item?.selected_sub_service_id?.duration)} */}

                                                                                <IconButton
                                                                                    onClick={() => handleDelete(item?._id)}
                                                                                    style={styles.removeButton}
                                                                                    disabled={loadingIds.includes(item?._id)}
                                                                                >
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        sx={{
                                                                                            boxShadow: 'none',
                                                                                            padding: '2px 10px',
                                                                                            borderRadius: 10,
                                                                                            background: 'white',
                                                                                            border: '1px solid red',
                                                                                            color: 'red',
                                                                                            '&:hover': {
                                                                                                backgroundColor: 'white',
                                                                                                boxShadow: 'none',
                                                                                                cursor: 'pointer'
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {loadingIds?.includes(item?._id) ? 'Removing...' : 'Remove'}
                                                                                    </Button>

                                                                                </IconButton>

                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                />
                                                                <Box sx={styles.itemActions}>
                                                                    <Typography variant="h6" sx={styles.discountPrice} onChange={(e) => setBillingDetail({ ...billingDetail, productId: item._id })} >{formatPrice(item?.price)}<span style={{ fontSize: '14px' }}> x {productInfo?.[item?._id]?.qty}</span> </Typography>
                                                                    {/* <Typography variant="h6" sx={styles.price}>{formatPrice(item?.selected_sub_service_id?.price)}</Typography> */}
                                                                </Box>
                                                            </ListItem>

                                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                                {productInfo?.[item?._id]?.relay != 'no relay' && <span
                                                                    style={{
                                                                        fontSize: '14px',
                                                                        color: '#808080',
                                                                        border: '1px solid #808080',
                                                                        padding: '5px 10px',
                                                                        textAlign: 'center',
                                                                        borderRadius: '5px'
                                                                    }}
                                                                >
                                                                    {productInfo?.[item?._id]?.relay}
                                                                </span>}
                                                                {productInfo?.[item?._id]?.insurance != 'without insurance' && <span
                                                                    style={{
                                                                        fontSize: '14px',
                                                                        color: '#808080',
                                                                        border: '1px solid #808080',
                                                                        padding: '5px 10px',
                                                                        textAlign: 'center',
                                                                        borderRadius: '5px'
                                                                    }}
                                                                >
                                                                    {productInfo?.[item?._id]?.insurance}
                                                                </span>}

                                                                {productInfo?.[item?._id]?.server != 'without server' && <span
                                                                    style={{
                                                                        fontSize: '14px',
                                                                        color: '#808080',
                                                                        border: '1px solid #808080',
                                                                        padding: '5px 10px',
                                                                        textAlign: 'center',
                                                                        borderRadius: '5px'
                                                                    }}
                                                                >
                                                                    {productInfo?.[item?._id]?.server}
                                                                </span>
                                                                }
                                                                {productInfo?.[item?._id]?.simCard != 'no sim' && <span
                                                                    style={{
                                                                        fontSize: '14px',
                                                                        color: '#808080',
                                                                        border: '1px solid #808080',
                                                                        padding: '5px 10px',
                                                                        textAlign: 'center',
                                                                        borderRadius: '5px'
                                                                    }}
                                                                >
                                                                    {productInfo?.[item?._id]?.simCard}
                                                                </span>}
                                                            </div>

                                                            {productInfo?.[item?._id]?.accessories?.length > 0 && (
                                                                <div style={{ marginTop: '15px', padding: '10px 0'}}>
                                                                    <Typography variant="body1" style={{ fontSize: '16px', fontWeight: '500', marginBottom: '10px', textAlign:'center' }}>
                                                                        Accessories
                                                                    </Typography>
                                                                    <div style={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
                                                                        {productInfo?.[item?._id]?.accessories.map((accessory, i) => (
                                                                            <div
                                                                                key={i}
                                                                                style={{
                                                                                    position: 'relative',
                                                                                    width: '150px',
                                                                                    cursor: 'pointer',
                                                                                    textAlign: 'center',
                                                                                }}
                                                                            >
                                                                                {/* Accessory Image */}
                                                                                <img
                                                                                    src={`${serverURL}/uploads/accessories-images/${accessory?.images?.[0]}`}
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        height: '120px',
                                                                                        borderRadius: '8px',
                                                                                        objectFit: 'cover',
                                                                                    }}
                                                                                />

                                                                                {/* Transparent Overlay for Accessory Name and Price */}
                                                                                <div
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        bottom: '5px',
                                                                                        left: '0',
                                                                                        right: '0',
                                                                                        padding: '5px 10px',
                                                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Slightly darker transparent background for better contrast
                                                                                        borderRadius: '4px',
                                                                                        color: '#fff',
                                                                                        textAlign: 'center',
                                                                                    }}
                                                                                >
                                                                                    {/* Accessory Name */}
                                                                                    <div
                                                                                        style={{
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            whiteSpace: 'nowrap',
                                                                                            maxWidth: '100%',
                                                                                            fontSize: '14px'
                                                                                        }}
                                                                                    >
                                                                                        {accessory?.title}
                                                                                    </div>

                                                                                    {/* Accessory Price */}
                                                                                    <div
                                                                                        style={{
                                                                                            fontSize: '14px',
                                                                                            textAlign: 'center',
                                                                                        }}
                                                                                    >
                                                                                        {formatPrice(accessory?.price)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {/* {index !== allSubServices?.length - 1 && <Divider />} */}
                                                            {allCartItems?.length === 1 || index === allCartItems?.length - 1 ? <></> : <Divider sx={{ mt: 1, mb: 1 }} />}
                                                        </>
                                                    ))}

                                                </List>
                                            </>
                                    }
                                </>
                        }
                    </Box>
                </Box>

                <Box style={{ height: "100%", position: "sticky", top: "10%", flex: 2 }}>
                    <Box sx={styles.summarySection}>
                        <Typography variant="h6" gutterBottom>Pricing Details</Typography>
                        <Box sx={styles.priceRow}>
                            <Typography variant="body1">Total:</Typography>
                            <Typography variant="body1">{formatPrice(subTotal)}</Typography>
                        </Box>
                        {/* <Box sx={styles.priceRow}>
                            <Typography variant="body1">GST {user_data?.gst ? '(18%)' : '(5%)'}:</Typography>
                            <Typography variant="body1">+{formatPrice(gst)}</Typography>
                        </Box> */}
                        <Box sx={styles.priceRow}>
                            <Typography variant="body1">GST (18%):</Typography>
                            <Typography variant="body1">+{formatPrice(gst)}</Typography>
                        </Box>

                        <Box sx={styles.priceRow}>
                            <Typography variant="body1">Shipping Charges:</Typography>
                            <Typography variant="body1">{formatPrice(shippingCharges)}</Typography>
                        </Box>
                        <Box sx={styles.priceRow}>
                            <Typography variant="body1" style={{ fontWeight: 500 }}>Payable:</Typography>
                            <Typography variant="body1" style={{ fontWeight: 500 }}>{formatPrice(total)}</Typography>
                        </Box>
                        {alertMessage && (
                            <Alert severity={alertMessage.includes('successfully') ? 'success' : 'error'}>{alertMessage}</Alert>
                        )}
                        <Button style={styles.placeOrderButton} onClick={handleBookNow}>Book Now</Button>
                    </Box>

                    <Box sx={styles.summarySection} style={{ height: 'auto', marginTop: '5%' }}>
                        <TrustBadges />
                    </Box>
                </Box>


            </Box>
            <Footer />

        </>
    );
};

export default Checkout;