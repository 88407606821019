import { Box, Button, Modal, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { formatPrice, primaryColor, formatDate } from '../../constant';


const InvoiceModal = ({ open, setOpen, currentInvoice }) => {
    const contentRef = useRef(null);
    const { user_data } = useSelector(state => state.user);

    const isDesktop = useMediaQuery('(min-width: 768px)');

    const styles = {
        invoice: {
            padding: "4%",
            height: 470,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #000",
            paddingBottom: "10px",
            marginBottom: "5%",
        },
        brand: {
            textAlign: "left",
        },
        info: {
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        infoSection: {
            textAlign: "left",
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
        },
        th: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        td: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        footer: {
            marginTop: "20px",
            borderTop: "2px solid #000",
            marginTop: "5%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        terms: {
            textAlign: "left",
        },
        payment: {
            textAlign: "left",
        },
        summary: {
            marginTop: "20px",
            textAlign: "right",
        },
        text: {
            fontSize: 14,
        },
    };
    const handleDownloadPdf = async () => {
        if (contentRef.current) {
            const canvas = await html2canvas(contentRef.current);
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            let position = 0;

            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save("invoice.pdf");
        }
    };

    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isDesktop ? 550 : '85%',
        height: isDesktop ? '85%' : '80%',
        borderRadius: 2,
        overflowY: 'auto',
        bgcolor: "white",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        border: "none",
        boxShadow: 24,
        p: 2,
    };

    const button = {
        padding: '5px',
        fontSize: 13,
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: isDesktop ? '20%' : '40%',
        marginTop: '20px',
        boxShadow: 'none',
        marginLeft: 'auto'
    }

    const getAmountBeforeGst = (total_amount, gst) => {
        return Math.ceil(total_amount / (1 + gst / 100));
    }

    const details = [
        {
            invoice_to: currentInvoice?.userId?.name,
            // gst: currentInvoice?.gst,
            item_description: currentInvoice?.invoiceNumber,
            item_price: getAmountBeforeGst(currentInvoice?.total_amount, currentInvoice?.gst),
            paid: currentInvoice?.paid_amount,
            due: currentInvoice?.remaining_amount,
            total: currentInvoice?.total_amount,
            tax: parseInt(currentInvoice?.total_amount) - parseInt(getAmountBeforeGst(currentInvoice?.total_amount, currentInvoice?.gst)),
            invoice_number: currentInvoice?.invoiceNumber,
            invoice_date: currentInvoice?.createdAt,
        },
    ];

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={styleModal}>
                    <div ref={contentRef} id="invoice" style={styles.invoice}>
                        <div style={styles.header}>
                            <div style={styles.brand}>
                                <h2 style={{ fontWeight: 600, margin: 0, }}>Oredo GPS</h2>
                                <p style={{ fontSize: 11, opacity: "70%" }}>
                                    Services with a Lifestyle
                                </p>
                            </div>
                            <h2 style={{ fontWeight: 600, margin: 0 }}>INVOICE</h2>
                        </div>
                        <div style={{ ...styles.info, ...styles.text }}>
                            <div style={styles.infoSection}>
                                <p>
                                    <strong>Invoice to:</strong>
                                </p>
                                <p>{details[0]?.invoice_to}</p>
                                {/* <p>{details[0]?.invoice_to_address}</p> */}
                            </div>
                            <div style={{ ...styles.infoSection, textAlign: 'right' }}>
                                <p>
                                    <strong>Invoice no.: </strong> {details[0]?.invoice_number}
                                </p>
                                <p>
                                    <strong>Date: </strong> {formatDate(details[0]?.invoice_date)}
                                </p>
                            </div>
                        </div>
                        <table style={{ ...styles.table, ...styles.text, marginTop: "3%" }}>
                            <thead style={{ textAlign: "left" }}>
                                <tr>
                                    <th>SL.</th>
                                    <th>Item Description</th>
                                    <th>Price</th>
                                    {/* <th>GST</th>
                                    <th>Total</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {currentInvoice?.productId?.map((item, i) =>
                                    <tr>
                                        <td>{i + 1}.</td>
                                        <td>{item?.name}</td>
                                        <td>{formatPrice(item?.price)}</td>
                                        {/* <td>{18}%</td> */}
                                        {/* <td>{formatPrice(details[0]?.total)}</td> */}
                                    </tr>)}
                            </tbody>
                        </table>
                        <div style={{ ...styles.footer, ...styles.text }}>
                            <div style={styles.terms}>
                                <p>Thanks for doing business with us.</p>
                                {/* <p>Terms & Conditions</p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                                    dignissim pretium consectetur.
                                </p> */}
                            </div>
                            <div style={{ ...styles.summary, ...styles.text }}>
                                <p>Sub Total: {formatPrice(currentInvoice?.amountBeforGst)}</p>
                                <p>Tax: {formatPrice(currentInvoice?.amount - currentInvoice?.amountBeforGst)}</p>
                                <p style={{ margin: 0, padding: 0 }}>
                                    <strong>Total: {formatPrice(currentInvoice?.amount)}</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <Button variant="contained" onClick={() => { handleDownloadPdf() }} style={button}>Download</Button>
                </Box>
            </Modal>
        </>
    );
};

export default InvoiceModal;
