// export default function EmptyPage() {
//     return (
//         <>
//             <div style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 alignItems: 'center',
//                 width: '100%',
//                 margin: 'auto',
//             }}>
//                 <center>
//                     {/* <img
//                         style={{ width: 350, height: 350 }}
//                         src="/images/empty.svg"
//                     /> */}
//                     <h2 style={{
//                         fontWeight: 500,
//                         fontSize: 25,
//                     }}>
//                         Nothing to show...
//                     </h2>
//                 </center>
//             </div>
//         </>
//     )
// }

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    emptyMessageCell: {
        textAlign: 'center',
        height: '200px',
        verticalAlign: 'middle',
    },
    noRecordText: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#888',
        textAlign: 'center'
    },
    tableContainer: {
        background: 'white',
        borderRadius: 5,
        border: '1px solid gainsboro',
    },
});

const EmptyPage = ({ tableHeader, dashboard }) => {
    const classes = useStyles();
    const data = []; // No data, will trigger the empty message

    return (
        <>
            {
                dashboard === true ?
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            margin: 'auto',
                        }}>
                            <center>
                                 <img
                                    style={{ width: 350, height: 350 }}
                                    src="/images/empty.svg"
                                />
                                <h2 style={{
                                    fontWeight: 500,
                                    fontSize: 25,
                                }}>
                                    Nothing to show...
                                </h2>
                            </center>
                        </div>
                    </>
                    :
                    <TableContainer className={classes.tableContainer}>
                        <Table className={classes.root}>
                            <TableHead>
                                <TableRow>
                                    {tableHeader?.map((item, idx) => (
                                        <TableCell
                                            key={idx}
                                            style={{
                                                borderRight: '1px solid gainsboro',
                                                padding: '16px 10px',
                                                textAlign: 'center',
                                                width: idx === 0 ? '5%' : ''
                                            }}
                                        >
                                            {item}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {data?.length === 0 ? (
                                    <TableRow>
                                        <TableCell className={classes.emptyMessageCell} colSpan={tableHeader?.length}>
                                            <Typography className={classes.noRecordText}>No Record Found</Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    data.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center' }}>
                                                {row}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </>
    );
};

export default EmptyPage;
