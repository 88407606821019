import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Table, TableHead, TableBody, TextField, Avatar, ListItem, ListItemAvatar, IconButton, List, Divider, Button, Select, MenuItem, InputLabel, FormLabel, FormControl } from '@mui/material';
import toast from 'react-hot-toast';
import { makeStyles } from '@mui/styles';
import { formatPrice, primaryColor } from '../../constant';
import { post_data, serverURL } from '../../api';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.processing': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.shipped': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.delivered': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.cancelled': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.true': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
    },
});

const ShippedModal = ({ open, setOpen, data, getAllOrders }) => {
    const classes = useStyles();
    const [imei, setImei] = useState({});
    const [simNumber, setSimNumber] = useState({});
    const [status, setStatus] = useState('processing');
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (data) {
            setImei((prevImei) => {
                const updatedImei = { ...prevImei };
                data?.productId?.forEach((item) => {
                    updatedImei[item?._id] = data?.productInfo[item?._id]?.imei;
                });
                return updatedImei;
            });

            setSimNumber((prevSimNumber) => {
                const updatedSimNumber = { ...prevSimNumber };
                data?.productId?.forEach((item) => {
                    updatedSimNumber[item?._id] = data?.productInfo[item?._id]?.simNumber;
                });
                return updatedSimNumber;
            });

            setStatus(data?.orderStatus || 'processing');
        }
    }, [data]);

    console.log("oreder stattus", status)



    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 550,
            height: '80vh',
            border: 'none',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
            overflow: 'auto'
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,
        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right'
        },
        icon: {
            width: 22,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    const DataRow = ({ label, value }) => (
        <div style={styles.row}>
            <span style={styles.dataLabel}>{label}</span>
            {
                label === 'Status' ?
                    <Button
                        style={{ fontSize: 11, fontWeight: 500, borderRadius: 30, marginLeft: 'auto' }}
                        className={`${classes.stageButton} ${value?.toLowerCase()?.replace(' ', '')}`}
                    >
                        {value}
                    </Button>
                    :
                    <span style={styles.dataValue}>{value}</span>

            }
        </div>
    );
    console.log(" data", data);

    const handleSubmit = async () => {
        try {

            Object.keys(imei).forEach((item) => {
                if (data?.productInfo && data.productInfo[item]) {
                    data.productInfo[item].imei = imei[item];
                    data.productInfo[item].simNumber = simNumber[item];
                }
            });

            data.orderStatus = status;

            const result = await post_data(`order/update-order/${data?._id}`, data);

            if (result.status == true) {
                handleClose();
                getAllOrders();
                toast.success("Order Updated Successfully");
            } else {
                toast.error(result?.response?.data?.message || "Something went wrong");
            }

            console.log("updated data", data);
        } catch (e) {
            console.log("Error while shipping order", e);
        }
    }


    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                    Shipped Order
                </Typography>
                <Box>
                    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                        {data?.productId?.map((item, index) => (
                            <div key={index} style={{ marginBottom: '10px', border: '1px solid #ddd', padding: '10px', boxSizing: 'border-box' }}>
                                <DataRow label="Product" value={item?.name} />

                                {/* <div style={styles.row}>
            <span style={styles.dataLabel}>{label}</span>
            {
                label === 'Status' ?
                    <Button
                        style={{ fontSize: 11, fontWeight: 500, borderRadius: 30, marginLeft: 'auto' }}
                        className={`${classes.stageButton} ${value?.toLowerCase()?.replace(' ', '')}`}
                    >
                        {value}
                    </Button>
                    :
                    <span style={styles.dataValue}>{value}</span>

            }
        </div> */}



                                <div style={styles.row}>
                                    <span style={styles.dataLabel}>IMEI Number</span>
                                    <span style={styles.dataValue}>
                                        <input
                                            type="text"
                                            placeholder="Enter IMEI Number"
                                            onChange={(e) =>
                                                setImei((prevImei) => ({
                                                    ...prevImei,
                                                    [item?._id]: e.target.value
                                                }))
                                            }
                                            value={imei[item?._id] || ''}
                                        />
                                    </span>
                                </div>

                                <div style={styles.row}>
                                    <span style={styles.dataLabel}>SIM Number</span>
                                    <span style={styles.dataValue}>
                                        <input
                                            type="text"
                                            placeholder="Enter SIM Number"
                                            onChange={(e) =>
                                                setSimNumber((prevSimNumber) => ({
                                                    ...prevSimNumber,
                                                    [item?._id]: e.target.value
                                                }))
                                            }
                                            value={simNumber[item?._id] || ''}
                                        />
                                    </span>
                                </div>
                            </div>
                        ))}
                        <div style={styles.row}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    label="Status"
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <MenuItem value="processing">Processing</MenuItem>
                                    <MenuItem value="shipped">Shipped</MenuItem>
                                    <MenuItem value="delivered">Delivered</MenuItem>
                                    <MenuItem value="cancelled">Cancelled</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <Button sx={{ marginTop: '20px', float: 'right', marginRight: '20px', backgroundColor: primaryColor, color: 'white', padding: '8px 20px' }} onClick={handleSubmit}>Submit</Button>
                    </div>

                </Box >
            </Box >

        </Modal >
    );
};

export default ShippedModal;
