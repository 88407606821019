import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, useMediaQuery, Chip } from '@mui/material';
import { get_data } from '../../api';
import { useSelector } from 'react-redux';
import moment from 'moment';
import BookingDetailsModal from './BookingDetailsModal';
import CancelModal from './CancelModal';
import { formatDate, formatPrice, primaryColor } from '../../constant';
import ReviewDrawer from './ReviewDrawer';




const BookingsCard = ({ booking, getAllBookings }) => {

    const isTablet = useMediaQuery('(max-width: 960px)');
    const isMobile = useMediaQuery('(max-width: 600px)');
    // const { primaryColor } = globalConstant();
    const [openBooking, setOpenBooking] = React.useState(false);
    const [openCancel, setOpenCancel] = React.useState(false);
    const { user_data } = useSelector(state => state.user);
    const [selectedBookingAmount, setSelectedBookingAmount] = useState('');
    const [selectedBookingId, setSelectedBookingId] = useState('');
    const [cancelType, setCancelType] = useState('')
    const statusStyles = {
        scheduled: {
            backgroundColor: '#ffe9a8',
            color: '#bf8f00',
        },
        completed: {
            backgroundColor: '#C8E6C9',
            color: '#388E3C',
        },
        cancelled: {
            backgroundColor: '#FFCDD2',
            color: '#D32F2F',
        },

        processed: {
            backgroundColor: '#ffe9a8',
            color: '#bf8f00',
        },
        success: {
            backgroundColor: '#C8E6C9',
            color: '#388E3C',
        },
        failed: {
            backgroundColor: '#FFCDD2',
            color: '#D32F2F',
        }
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isMobile || isTablet ? 'column' : 'row',
            justifyContent: 'space-between',
            padding: '20px',
            borderRadius: '10px',
            border: '1px solid #E0E0E0',
            backgroundColor: 'white',
            marginBottom: '20px',
            width: isMobile || isTablet ? '95%' : '60%',
            margin: isMobile || isTablet ? '' : '2% auto'
        },
        imageContainer: {
            flex: 0.3,
            marginRight: isMobile ? '0' : '20px',
            marginBottom: isMobile ? '20px' : '0',
        },
        image: {
            width: 100,
            height: 100,
            borderRadius: '10px',
        },
        content: {
            flex: 3,
            display: 'flex',
            flexDirection: 'column',
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
        },
        title: {
            fontSize: '18px',
            fontWeight: '500',
            color: primaryColor,
            marginRight: '10px',
        },
        details: {
            fontSize: '14px',
            color: '#333',
            marginBottom: '10px',
        },
        amenities: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '10px',
        },
        amenity: {
            fontSize: '12px',
            color: '#666',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '15px',
            padding: '5px 10px',
            marginRight: '5px',
            marginBottom: '5px',
        },
        priceSection: {
            flex: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        price: {
            fontSize: '24px',
            fontWeight: '600',
            textAlign: 'right',
            color: primaryColor,
        },
        buttonContainer: {
            display: 'flex',
            gap: '10px',
        },
        button: {
            backgroundColor: 'white',
            color: primaryColor,
            border: '2px solid ' + primaryColor,
            borderRadius: '7px',
            textTransform: 'none',
            padding: '5px 15px',
        },
        tax: {
            fontSize: 14,
            color: '#333'
        },
        status: {
            padding: '5px 10px',
            borderRadius: '5px',
            textAlign: 'center',
            marginBottom: '10px',
        },
        date: {
            fontSize: '14px',
            color: '#333',
            marginBottom: '10px',
        }
    };

    const handleClick = () => {
        setOpenBooking(true);
    };


    useEffect(() => {
        getRefundByBooking();
        getReviewByBooking()
    }, []);


    const [refund, setRefund] = useState([]);
    const [review, setReview] = useState([]);

    const getRefundByBooking = async () => {
        // try {
        //     const response = await get_data(`refund/get-refund-by-booking/${booking?._id}`);
        //     if (response.status) {
        //         setRefund(response?.data?.[0]);
        //     }
        // } catch (error) {
        //     console.log("Error while fetching Services", error);
        // }
    };

    const getReviewByBooking = async () => {
        // try {
        //     const response = await get_data(`reviews/get-review-by-booking/${booking?._id}`);
        //     if (response.status) {
        //         setReview(response?.data?.[0]);
        //     }
        // } catch (error) {
        //     console.log("Error while fetching Services", error);
        // }
    };


    // Calculate if the cancel button should be displayed
    const serviceDateTime = moment(`${booking?.service_date} ${booking?.service_time}`, "ddd DD MM YY h A");
    const now = moment();
    const hoursUntilService = serviceDateTime.diff(now, 'hours');
    const showCancelButton = hoursUntilService > 6;

    const serviceDateTime2 = moment(`${booking?.service_date} ${booking?.service_time}`, "ddd DD MM YY h A");
    const now2 = moment();
    const hoursUntilService2 = serviceDateTime.diff(now, 'hours');
    const showCancelButton2 = hoursUntilService < 6;


    return (
        <Box sx={styles.container}>
            <Box sx={styles.content}>
                <Box sx={styles.titleContainer}>
                    <Typography sx={styles.title}>
                        {booking?.sub_service_id?.map((item) => item?.id?.sub_service_name)?.join(' | ')}
                    </Typography>
                    <div style={{ display: 'flex', gap: 10 }}>
                        <Chip
                            label={booking?.orderStatus}
                            sx={{
                                ...statusStyles[booking?.orderStatus],
                                borderRadius: '5px',
                                fontWeight: '500',
                                textTransform: 'capitalize'
                            }}
                        />
                        {/* {
                            refund && (
                                <Chip
                                    label={'Refund' + ' ' + refund?.refund_status}
                                    sx={{
                                        ...statusStyles[refund?.refund_status],
                                        borderRadius: '5px',
                                        fontWeight: '500',
                                        textTransform: 'capitalize'
                                    }}
                                />
                            )
                        } */}
                    </div>
                </Box>
                <Box sx={styles.amenities}>
                    {booking?.service_id?.map((amenity, index) => (
                        <Typography key={index} sx={styles.amenity}>{amenity?.service_name} Services</Typography>
                    ))}
                </Box>
                <Typography sx={styles.date}><font style={{ fontWeight: 500 }}>Booking Date:</font> {formatDate(booking?.createdAt)}</Typography>
                {/* <Typography sx={styles.date}><font style={{ fontWeight: 500 }}>Scheduled Date & Time:</font> {`${booking?.service_date} - ${booking?.service_time}`}</Typography> */}
                {/* <Typography sx={styles.date}><font style={{ fontWeight: 500 }}>Scheduled Date & Time:</font> {`${booking?.service_date?.split(" ")[0]} ${booking?.service_date?.split(" ")[1]}-${booking?.service_date?.split(" ")[2]}-${booking?.service_date?.split(" ")[3]}`} - {booking?.service_time}</Typography> */}
            </Box>
            <Box sx={styles.priceSection}>
                <Typography sx={styles.price}>
                    {formatPrice(parseInt(booking?.amount))}
                    <Typography sx={styles.tax}>includes taxes and charges</Typography>
                </Typography>
                <Box sx={styles.buttonContainer}>
                    {
                        booking?.booking_status === 'completed' && !review && (
                            <ReviewDrawer service={booking?.service_id} vendors={booking?.vendors} bookingId={booking?._id} getAllBookings={getAllBookings} />
                        )
                    }

                    <Button sx={styles.button} onClick={handleClick}>View Details</Button>
                </Box>
            </Box>
            {/* <CancelModal getAllBookings={getAllBookings} cancelType={cancelType} open={openCancel} setOpen={setOpenCancel} selectedBookingAmount={selectedBookingAmount} selectedBookingId={selectedBookingId} /> */}
            <BookingDetailsModal getAllBooking={getAllBookings} type='customer' open={openBooking} refund={refund} setOpen={setOpenBooking} currentBooking={booking} />
        </Box>
    );
};

export default BookingsCard;