import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ProductsGrid from "../../components/ProductsGrid";
import ProductComp from "../../components/ProductComp";
import { useEffect, useState, useRef } from "react";
import { get_data } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import ScreenLoading from "../../components/ScreenLoading"
import BottomCart from "../../components/BottomCart";

export default function ProductPage() {
    const navigate = useNavigate();
    const [down, setDown] = useState(false)
    const [qty, setQty] = useState(0);
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const { id } = useParams();
    const [totalPrice, setTotalPrice] = useState();
    const [relay, setRelay] = useState('no relay');
    const [simCard, setSimCard] = useState('no sim');
    const [server, setServer] = useState('without server');
    const [insurance, setInsurance] = useState('without insurance');
    const [installation, setInstallation] = useState('without installation');
    const [globalPrices, setGlobalPrices] = useState({});
    const [formData, setFormData] = useState({
        accessories: []
    })
    const productInfo = JSON.parse(localStorage.getItem("productInfo")) || {};

    const diveleRef = useRef(null);
    const listenToScroll = () => {
        const scrollableDiv = diveleRef.current;
        if (scrollableDiv) {
            setDown(scrollableDiv.scrollTop > 235);
        }
    };
    
    
    useEffect(() => {
        const scrollableDiv = diveleRef.current;
        if (scrollableDiv) {
            scrollableDiv.addEventListener('scroll', listenToScroll);
        }
    
        return () => {
            if (scrollableDiv) {
                scrollableDiv.removeEventListener('scroll', listenToScroll);
            }
        };
    }, [])

    const handleCheckout = () => {

        const productInfo = JSON.parse(localStorage.getItem("productInfo")) || {};

        localStorage.setItem("productInfo", JSON.stringify({ ...productInfo, [data?._id]: { relay: relay, insurance: insurance, server: server, simCard: simCard, totalPrice: totalPrice, accessory: formData?.accessory, qty: qty, accessories: formData?.accessories } }));

        navigate("/checkout", { state: { shippingCharges: globalPrices?.shipping } });
    }

    const handleAddToCart = () => {
        setQty(1);
        const productIds = JSON.parse(localStorage.getItem("productId")) || [];
        const productInfo = JSON.parse(localStorage.getItem("productInfo")) || {};

        if (!productIds.includes(data?._id)) {
            productIds.push(data?._id);
        }

        localStorage.setItem("productInfo", JSON.stringify({ ...productInfo, [data?._id]: { relay: relay, insurance: insurance, server: server, simCard: simCard, totalPrice: totalPrice, accessory: formData?.accessory, qty: qty } }));

        localStorage.setItem("productId", JSON.stringify(productIds));

    };

    const getProduct = async () => {
        try {
            const data = await get_data(`product/get-product/${id}`);
            if (data.status) {
                setLoading(false);
                setData(data?.data);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getProduct();
    }, [id]);

    useEffect(() => {
        setTotalPrice(data?.price);
    }, [data])


    return (
        <div id="scrolldiv" style={{ maxHeight: '100vh', overflow: 'auto' }} ref={diveleRef}>
            {
                loading ?
                    <>
                        <ScreenLoading />
                    </>
                    :
                    <>
                        <Header />
                        <ProductComp
                            product={data}
                            qty={qty}
                            setQty={setQty}
                            handleCheckout={handleCheckout}
                            productInfo={productInfo}
                            relay={relay}
                            setRelay={setRelay}
                            simCard={simCard}
                            setSimCard={setSimCard}
                            server={server}
                            setServer={setServer}
                            insurance={insurance}
                            setInsurance={setInsurance}
                            totalPrice={totalPrice}
                            setTotalPrice={setTotalPrice}
                            formData={formData}
                            setFormData={setFormData}
                            globalPrices={globalPrices}
                            installation={installation}
                            setInstallation={setInstallation}
                            setGlobalPrices={setGlobalPrices}
                            handleAddToCart={handleAddToCart}
                            down={down}
                            setDown={setDown}
                        />
                        <ProductsGrid />
                        <Footer />
                        {down ? <div style={{ width: '100%', position: 'sticky', bottom: 0 }}>
                            <BottomCart product={data} totalPrice={totalPrice} qty={qty} setQty={setQty} handleCheckout={handleCheckout} handleAddToCart={handleAddToCart} />
                        </div> : <></>
                        }
                    </>
            }
        </div>
    )
}