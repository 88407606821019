import React, { useState, useEffect } from "react";
import {
    TextField,
    Button,
    Typography,
    Container,
    Grid,
    Paper,
    Modal,
    Backdrop,
    Box,
    MenuItem,
    FormControl,
    Autocomplete,
} from "@mui/material";
import axios from "axios";
import { useRazorpay } from "react-razorpay";
import toast from "react-hot-toast";
import Logo from "../../assests/oredogpslogo.jpeg";
import { get_data, post_data } from "../../api";
import { useSelector } from "react-redux";
import { primaryColor } from "../../constant";

const AdminSpeedGovernorModal = ({ open, setOpen, getAllSpeedGoverner, isUpdate, editData }) => {
    const [brandList, setBrandList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [formData, setFormData] = useState({
        clientId: '',
        certificateNumber: Math.floor(1000000000 + Math.random() * 9000000000),
        rtoCode: "",
        ownerName: "",
        address: "",
        vehicleRegNumber: "",
        vehicleType: "",
        engineNumber: "",
        chassisNumber: "",
        registrationDate: "",
        vehicleBrand: "",
        vehicleModel: "",
        manufacturingYear: "",
        tacNumber: "",
        testRepNumber: "",
        sldModel: "",
        sldSerialNo: Math.floor(100000 + Math.random() * 900000),
        setSpeed: "",
        sldType: "",
        invoiceNo: "",
        invoiceDate: "",
        installationDate: "",
        calibrationDate: "",
        nextCalibrationDate: "",
        sealNo: Math.floor(100000 + Math.random() * 900000),
        companyName: "",
        area: "",
        companyAddress: "",
        tradeLicenseNo: "",
        tradeLicenseExpiryDate: "",
        rtoName: "",
        name: "",
        place: "",
        idNo: Math.floor(100000 + Math.random() * 900000),
    });
    const [speedGovernerPrice, setSpeedGovernerPrice] = useState(0);
    const [companyList, setCompanyList] = useState([]);

    const fetchAllCompany = async () => {
        const result = await get_data(`client/get-all-clients-for-options`);
        setCompanyList(result?.data || []);
    };

    useEffect(() => {
        fetchAllCompany();
    }, []);


    useEffect(() => {
        if (isUpdate) {
            setFormData({
                ...editData,
                clientId: editData?.clientId?._id,
            })
        }
    }, [editData])

    const fetchPrice = async () => {
        try {
            let global = await get_data('global/get-global-prices');

            if (global?.status == true) {
                setSpeedGovernerPrice(global?.data?.speedGoverner);
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    useEffect(() => {
        fetchPrice()
    }, []);

    const getAllModels = async (value) => {
        let result = await get_data(`model/get-all-model-by-brand/${value}`)
        if (result?.status == true) {

            setModelList(result?.data || [])
        } else {
            toast.error(result?.message)
        }
    }

    const fetchBrands = async () => {
        const result = await get_data(`brand/get-all-brands-for-options`);
        if (result?.status) {
            setBrandList(result?.data || []);

        }
    }

    useEffect(() => {
        fetchBrands()
    }, [])

    useEffect(() => {
        if (formData.invoiceDate) {
            const date = new Date(formData.invoiceDate);
            date.setDate(date.getDate() + 2);

            const nextYearDate = new Date(date);
            nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);

            const formattedDate = date.toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric"
            });

            const formattedNextYearDate = nextYearDate.toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric"
            });

            setFormData((prev) => ({
                ...prev,
                installationDate: formattedDate,
                calibrationDate: formattedDate,
                nextCalibrationDate: formattedNextYearDate
            }));
        }
    }, [formData.invoiceDate]);


    const generateRandomString = (length) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };


    useEffect(() => {
        if (formData?.rtoCode) {
            const part1 = `${formData.rtoCode}`;
            const part2 = `${generateRandomString(3)}BT-${generateRandomString(3)}`;
            const part3 = `${generateRandomString(3)}${Math.floor(100 + Math.random() * 900)}`;
            const part4 = `${Math.floor(100000 + Math.random() * 900000)}`; // 6-digit unique number

            const generated = `${part1}/${part2}/${part3}/${part4}`;
            setFormData({ ...formData, testRepNumber: generated });
        }
    }, [formData?.rtoCode]);


    const handleClose = () => {
        setOpen(false);
        getAllSpeedGoverner()
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        const result = await post_data('speed-governor/create-speed-governer', formData);
        if (result?.status === true) {
            toast.success("Payment Successful")
            handleClose()
        }
        else {
            toast.error(result?.response?.data?.message || "Something went wrong")
        }
    }



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '90vh',
        bgcolor: 'background.paper',
        border: '2px solid #fff',
        boxShadow: 24,
        borderRadius: '7px',
        overflow: 'auto',
    };

    const handleUpdate = async () => {
        setLoader(true)
        const result = await post_data(`speed-governor/update-speed-governer/${editData?._id}`, formData);
        if (result?.status === true) {
            toast.success("Updated Successfully")
            handleClose();
        }
        else {
            toast.error(result?.response?.data?.message || "Something went wrong")
        }
        setLoader(false)
    }


    return (
        <Modal
            open={open}
            onClose={handleClose}
        >

            <Box sx={style}>
                <Typography id="modal-title" variant="h6" component="h2" style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>
                    {isUpdate ? 'Edit Brand' : 'Speed Governer Form'}
                </Typography>

                <Grid container spacing={2} sx={{ p: 4 }}>
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <Autocomplete
                                fullWidth
                                options={companyList}
                                value={companyList?.find((s) => s?._id ===  formData?.clientId) || null}
                                onChange={(event, newValue) => setFormData({...formData, clientId: newValue?._id})}
                                autoHighlight
                                getOptionLabel={(option) => option.companyName || ''}
                                renderInput={(params) => <TextField {...params} label="Select Company" fullWidth />}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField label="Certificate Number" name="certificateNumber" value={formData?.certificateNumber} fullWidth onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="RTO Code" name="rtoCode" value={formData?.rtoCode} fullWidth onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12}><Typography>SECTION 1</Typography></Grid>
                    <Grid item xs={12}><Typography>Vehicle Owner Details</Typography></Grid>
                    <Grid item xs={6}>
                        <TextField label="Owner Name" name="ownerName" value={formData?.ownerName} fullWidth onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Address" name="address" value={formData?.address} fullWidth onChange={handleChange} />
                    </Grid>

                    <Grid item xs={12}><Typography>SECTION 2</Typography></Grid>
                    <Grid item xs={12}><Typography>Vehicle Details</Typography></Grid>
                    <Grid item xs={6}>
                        <TextField label="Vehicle Registration Number" value={formData?.vehicleRegNumber} name="vehicleRegNumber" fullWidth onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Engine Number" name="engineNumber" value={formData?.engineNumber} fullWidth onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Chassis Number" name="chassisNumber" value={formData?.chassisNumber} fullWidth onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Type of Vehicle" name="vehicleType" value={formData?.vehicleType} fullWidth onChange={handleChange}>
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="date" label="Registration date" name="registrationDate" value={formData?.registrationDate ? formData.registrationDate.split("T")[0] : ""} fullWidth onChange={handleChange} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField label="Manufacturing Year" name="manufacturingYear" value={formData?.manufacturingYear} fullWidth onChange={handleChange} />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                options={brandList}
                                getOptionLabel={(option) => option?.name}
                                sx={{ width: 'auto' }}
                                required
                                value={
                                    brandList?.find((brand) => brand?.name === formData?.vehicleBrand) || null
                                }
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        getAllModels(newValue?._id);
                                        setFormData((prev) => ({
                                            ...prev,
                                            vehicleBrand: newValue?.name,
                                        }))
                                    }
                                }}
                                renderInput={(params) => {
                                    return <TextField {...params} label="Select Brands" />;
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <  Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                options={modelList}
                                getOptionLabel={(option) => option?.name}
                                sx={{ width: 'auto' }}
                                value={modelList?.find((model) => model?.name === formData?.vehicleModel) || null}
                                required
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setFormData((prev) => ({
                                            ...prev,
                                            vehicleModel: newValue?.name,
                                        }))
                                    }
                                }}

                                renderInput={(params) => {
                                    return <TextField {...params} label="Select Models" />
                                }}
                            />
                        </FormControl>
                    </Grid >

                    <Grid item xs={12}><Typography>SECTION 3</Typography></Grid>

                    <Grid item xs={12}><Typography>Speed Governer Details</Typography></Grid>

                    <Grid item xs={6}>
                        <TextField label="TAC Number" name="tacNumber" value={formData?.tacNumber} fullWidth onChange={handleChange} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField label="Test Rep Number" name="testRepNumber" value={formData?.testRepNumber} fullWidth onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="SLD Model" name="sldModel" fullWidth value={formData?.sldModel} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="SLD Serial Number" name="sldSerialNo" value={formData?.sldSerialNo} fullWidth onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Set Speed" name="setSpeed" fullWidth value={formData?.setSpeed} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="SLD Type" name="sldType" fullWidth value={formData?.sldType} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Invoice Number" name="invoiceNo" value={formData.invoiceNo} fullWidth onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="date" label="Invoice Date" name="invoiceDate" value={formData?.invoiceDate ? formData.invoiceDate.split("T")[0] : ""} fullWidth onChange={handleChange} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField label="Installation Date" name="installationDate" fullWidth value={formData?.installationDate} />
                    </Grid>


                    <Grid item xs={4}>
                        <TextField label="Calibration Date" name="calibrationDate" fullWidth value={formData?.calibrationDate} />
                    </Grid>


                    <Grid item xs={4}>
                        <TextField label="Next Calibration Date" name="nextCalibrationDate" fullWidth value={formData?.nextCalibrationDate} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Seal No" name="sealNo" fullWidth value={formData?.sealNo} />
                    </Grid>

                    <Grid item xs={12}><Typography>SECTION 4</Typography></Grid>
                    <Grid item xs={12}><Typography>Authorized Dealer Declaration</Typography></Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">NOTE - Section 1 or 2 to be completed by the authorized dealer Who set or checked the Speed Governor. I declare that I have read and understood the information provided overleaf and information details in section 1 and 2 above is true and correct. </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField label="Company Name" name="companyName" value={formData?.companyName} fullWidth onChange={handleChange} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField label="Area" name="area" fullWidth value={formData?.area} onChange={handleChange} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField label="Company Address" name="companyAddress" value={formData?.companyAddress} fullWidth onChange={handleChange} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField label="Trade License No" name="tradeLicenseNo" value={formData?.tradeLicenseNo} fullWidth onChange={handleChange} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField type="date" label="Trade License Expiry Date" value={formData?.tradeLicenseExpiryDate ? formData.tradeLicenseExpiryDate.split("T")[0] : ""} name="tradeLicenseExpiryDate" fullWidth onChange={handleChange} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField label="RTO Name" name="rtoName" value={formData?.rtoName} fullWidth onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12}><Typography>SECTION 5</Typography></Grid>
                    <Grid item xs={12}><Typography>Product Specification Report</Typography></Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">This is acknowledged and confirmed that we got our vehicle bore with Speed Limitation
                            Device manufactured by M/s 3GB TECHNOLOGY PVT LTD. We have checked the performance of the vehicle after fitted
                            of Speed Limitation Device and also confirmed that the above Speed of the Vehicle is set and the unit is sealed
                            at above notary points and functioning as per norms laid down in AIS:018. We are satisfied with the performance of
                            the unit in all perspective. We don't have right to rasie any dispute or any legal claim against M/s 3GB TECHNOLOGY PVT LTD
                            in the event that the above mentioned seals are found broken/torn/tempered and more specifically with the respect to
                            any variance in the speed limit set at the time of delivery, after expiriy of the warranty period of 12 months from the
                            date of Installation.
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField label="Name" name="name" fullWidth value={formData?.name} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Place" name="place" fullWidth value={formData?.place} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="ID No" name="idNo" fullWidth value={formData?.idNo} />
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Button disabled={loader} variant="contained" style={{ textTransform: 'capitalize', color: "white", background: primaryColor, borderRadius: 6, padding: '1% 3.5%', boxShadow: 'none' }} onClick={isUpdate ? handleUpdate : handleSubmit}>{loader ? "Loading..." : isUpdate ? "Update" : `Submit`}</Button>
                    </Grid>
                </Grid>

            </Box>
        </Modal>
    );
};

export default AdminSpeedGovernorModal;
