import { Box, Card, CardContent, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress, TextField, Button, Grid, Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../api";
import { Toaster, toast } from 'react-hot-toast';
import { primaryColor } from "../../constant";

const DefaultNetwork = () => {

    const [network, setNetwork] = useState('');
    const [relay, setRelay] = useState('');
    const [insuranceOneYear, setInsuranceOneYear] = useState('');
    const [insuranceTwoYear, setInsuranceTwoYear] = useState('');
    const [insuranceThreeYear, setInsuranceThreeYear] = useState('');
    const [simCard, setSimCard] = useState('');
    const [server, setServer] = useState('');
    const [loading, setLoading] = useState(true);
    const [updatedId, setUpdatedId] = useState(null);
    const [globalId, setGlobalId] = useState(null);
    const [airtelPrice, setAirtelPrice] = useState('');
    const [jioPrice, setJioPrice] = useState('');
    const [viPrice, setViPrice] = useState('');
    const [bsnlPrice, setBsnlPrice] = useState('');
    const [installation, setInstallation] = useState('');
    const [shipping, setShipping] = useState('');
    const [speedGoverner, setSpeedGoverner] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [youtube, setYoutube] = useState('');
    const [android, setAndroid] = useState('');
    const [ios, setIos] = useState('');
    const [website, setWebsite] = useState('');
    const [role, setRole] = useState([]);

    const fetchNetwork = async () => {
        const result = await get_data('network/get-network');

        const global = await get_data('global/get-global-prices');

        if (result.status === true) {
            setNetwork(result?.data[0]?.name)
            setUpdatedId(result?.data[0]?._id)
        }

        if (global.status === true) {
            setRelay(global?.data?.relay);
            setSimCard(global?.data?.simCard);
            setServer(global?.data?.server);
            setGlobalId(global?.data?._id);
            setAirtelPrice(global?.data?.airtelPrice);
            setJioPrice(global?.data?.jioPrice);
            setViPrice(global?.data?.viPrice);
            setBsnlPrice(global?.data?.bsnlPrice);
            setShipping(global?.data?.shipping);
            setInstallation(global?.data?.installation);
            setInsuranceOneYear(global?.data?.insuranceOneYear);
            setInsuranceTwoYear(global?.data?.insuranceTwoYear);
            setInsuranceThreeYear(global?.data?.insuranceThreeYear);
            setSpeedGoverner(global?.data?.speedGoverner);
            setFacebook(global?.data?.facebook);
            setInstagram(global?.data?.instagram);
            setYoutube(global?.data?.youtube);
            setAndroid(global?.data?.android);
            setIos(global?.data?.ios);
            setWebsite(global?.data?.website);
            setRole(global?.data?.role);
        }
        setLoading(false)
    }

    console.log("global,role", role)


    const handleChange = async (event) => {
        setLoading(true)

        const result = await post_data(`network/set-network/${updatedId}`, { name: event.target.value })

        if (result.status) {
            setNetwork(result?.data?.name)
            setUpdatedId(result?.data?._id)
            toast.success("Network Updated")
        } else {
            toast.error("Something went wrong")
        }
        setLoading(false)

    };

    useEffect(() => {
        fetchNetwork();
    }, []);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        )
    }

    const handleUpdate = async () => {
        setLoading(true)

        if (!globalId) {
            const result = await post_data(`global/create-global-prices`, { relay, insuranceOneYear, insuranceTwoYear, insuranceThreeYear, simCard, server, speedGoverner, airtelPrice, jioPrice, viPrice, bsnlPrice, installation, shipping, facebook, instagram, youtube, android, ios, website, role })
            if (result.status === true) {
                toast.success("Prices Updated")
            } else {
                toast.error("Something went wrong")
            }
            setLoading(false)
        } else {
            const result = await post_data(`global/set-global-prices/${globalId}`, { relay, insuranceOneYear, insuranceTwoYear, insuranceThreeYear, simCard, server, airtelPrice, jioPrice, viPrice, bsnlPrice, installation, shipping, speedGoverner, facebook, instagram, youtube, android, ios, website, role })

            if (result.status === true) {
                toast.success("Prices Updated")
            } else {
                toast.error("Something went wrong")
            }
            setLoading(false)
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && e.target.value.trim() !== "") {
            setRole([...role, e.target.value.trim()]);
            e.target.value = ""; // Clear input field
            e.preventDefault(); // Prevent form submission if inside a form
        }
    };

    const handleDelete = (chipToDelete) => {
        setRole(role.filter((chip) => chip !== chipToDelete));
    };

    return (
        <Box>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Card>
                <CardContent>
                    <FormControl>
                        <FormLabel>Default Network</FormLabel>
                        <RadioGroup
                            value={network}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="airtel" control={<Radio />} label="Airtel" />
                            <FormControlLabel value="jio" control={<Radio />} label="Jio" />
                            <FormControlLabel value="vi" control={<Radio />} label="Vi" />
                            <FormControlLabel value="bsnl" control={<Radio />} label="Bsnl" />
                        </RadioGroup>
                    </FormControl>
                </CardContent>
            </Card>


            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Airtel Sim Price"
                                value={airtelPrice}
                                onChange={(e) => setAirtelPrice(e.target.value)}
                                fullWidth
                            />
                        </Grid>


                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Vi Sim Price"
                                value={viPrice}
                                onChange={(e) => setViPrice(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Jio Sim Price"
                                value={jioPrice}
                                onChange={(e) => setJioPrice(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Bsnl Sim Price"
                                value={bsnlPrice}
                                onChange={(e) => setBsnlPrice(e.target.value)}
                                fullWidth
                            />
                        </Grid>


                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Relay Price"
                                value={relay}
                                onChange={(e) => setRelay(e.target.value)}
                                fullWidth
                            />
                        </Grid>


                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Insurance One Year Price"
                                value={insuranceOneYear}
                                onChange={(e) => setInsuranceOneYear(e.target.value)}
                                fullWidth
                            />
                        </Grid>


                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Insurance Two Year Price"
                                value={insuranceTwoYear}
                                onChange={(e) => setInsuranceTwoYear(e.target.value)}
                                fullWidth
                            />
                        </Grid>


                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Insurance Three Year Price"
                                value={insuranceThreeYear}
                                onChange={(e) => setInsuranceThreeYear(e.target.value)}
                                fullWidth
                            />
                        </Grid>


                        {/* <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Sim Card Price"
                                value={simCard}
                                onChange={(e) => setSimCard(e.target.value)}
                                fullWidth
                            />
                        </Grid> */}

                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Shipping Charges"
                                value={shipping}
                                onChange={(e) => setShipping(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Server Price"
                                value={server}
                                onChange={(e) => setServer(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Installation Charges"
                                value={installation}
                                onChange={(e) => setInstallation(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Speed Governer Charges"
                                value={speedGoverner}
                                onChange={(e) => setSpeedGoverner(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Facebook Link"
                                value={facebook}
                                onChange={(e) => setFacebook(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Instagram Link"
                                value={instagram}
                                onChange={(e) => setInstagram(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Youtube Link"
                                value={youtube}
                                onChange={(e) => setYoutube(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Android Link"
                                value={android}
                                onChange={(e) => setAndroid(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="IOS Link"
                                value={ios}
                                onChange={(e) => setIos(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Website Link"
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                sx={{ mt: 1 }}
                                label="Add Roles"
                                onKeyDown={handleKeyDown} // Handle Enter key event
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                                {role.map((item, index) => (
                                    <Chip
                                        key={index}
                                        label={item}
                                        onDelete={() => handleDelete(item)}
                                        color="primary"
                                    />
                                ))}
                            </Box>
                        </Grid>
                    </Grid>

                    <div>
                        <Button
                            style={{
                                backgroundColor: primaryColor,
                                color: '#ffff',
                                cursor: 'pointer',
                                boxShadow: 'none',
                                marginRight: 10,
                                marginTop: 10
                            }}
                            onClick={handleUpdate}
                        >
                            Update
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </Box >
    )
}

export default DefaultNetwork;