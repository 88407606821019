import React from "react";
import { Container, Typography, Box, Grid, Card, CardContent, Avatar, Button } from "@mui/material";
import { styled } from "@mui/system";
import { primaryColor } from "../../constant";
import { useNavigate } from "react-router-dom";

const teamMembers = [
  { name: "ABCD Singh", role: "Web Developer", image: "https://via.placeholder.com/100" },
  { name: "John Doe", role: "UI/UX Designer", image: "https://via.placeholder.com/100" },
  { name: "Jane Smith", role: "Project Manager", image: "https://via.placeholder.com/100" }
];

const HeroSection = styled(Box)({
  textAlign: "center",
  padding: "80px 20px",
  background: primaryColor,
  color: "#fff",
});

const AboutUs = () => {

  const navigate = useNavigate();

  return (
    <>
      {/* Hero Section */}
      <HeroSection>
        <Typography variant="h3" gutterBottom>About Us</Typography>
        <Typography variant="h6">Building digital experiences with passion and expertise.</Typography>
      </HeroSection>

      {/* Company Information */}
      <Container sx={{ py: 5 }}>
        <Typography variant="h4" align="center" gutterBottom>Who We Are</Typography>
        <Typography variant="body1" align="center" paragraph>
          We are a team of creative and dedicated professionals, striving to deliver top-notch GPS rental and sales solutions.
        </Typography>
      </Container>

      {/* Team Section */}
      <Container sx={{ py: 5 }}>
        <Typography variant="h4" align="center" gutterBottom>Meet Our Team</Typography>
        <Grid container spacing={3} justifyContent="center">
          {teamMembers.map((member, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card sx={{ textAlign: "center", p: 2, boxShadow: 3 }}>
                <Avatar src={member.image} sx={{ width: 80, height: 80, margin: "auto" }} />
                <CardContent>
                  <Typography variant="h6">{member.name}</Typography>
                  <Typography variant="body2" color="textSecondary">{member.role}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Call to Action */}
      <Box sx={{ textAlign: "center", py: 5, background: "#f5f5f5" }}>
        <Typography variant="h4" gutterBottom>Rent or Buy GPS with Us</Typography>
        <Typography variant="body1" paragraph>
          Whether you need a GPS device for a short-term rental or a permanent purchase, we’ve got you covered. Contact us today!
        </Typography>
        <Button variant="contained" sx={{ color: "#fff", background: primaryColor, mt: 3 }} size="large" onClick={()=>navigate('/contact')}>Contact Us</Button>
      </Box>
    </>
  );
};

export default AboutUs;